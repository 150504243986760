/**
 * @typedef {{
 *   percentOccupied: null | number,
 *   trend: null | "increasing" | "decreasing" | "constant",
 *   areas: Array<ParkingArea>,
 *   totalCapacity: number,
 *   totalOccupation: null | number,
 *   totalFree: null | number,
 * }} Occupation
 */

/**
 * @typedef {{
 *   name: null | string,
 *   motorVehicle?: UtilizationEntry,
 *   disabled?: UtilizationEntry,
 *   charging?: UtilizationEntry,
 *   woman?: UtilizationEntry,
 * }} ParkingArea
 */

/**
 * @typedef {{
 *   capacity: number,
 *   occupied: null | number,
 *   free: null | number,
 * }} UtilizationEntry
 */

/**
 * @param {null | Occupation} occupation
 * @return {null | string}
 */
export function getOccupationIcon(occupation) {
	if (occupation === null || occupation.percentOccupied === null) {
		return null;
	}

	const occRounded = Math.round(occupation.percentOccupied / 10) * 10;
	const trendStr = occRounded === 0 || occRounded === 100 ? 'constant' : occupation.trend || 'constant';
	return `${trendStr}_${occRounded.toString(10)}`;
}

/**
 * @param {null | Occupation} occupation
 * @return {null | string}
 */
export function getOccupationText(occupation) {
	if (occupation === null || occupation.percentOccupied === null) {
		return null;
	}

	const percentStr = Math.round(occupation.percentOccupied).toString(10);
	let res = `${percentStr}% Belegung`;

	const trend = renderTrend(occupation.trend);
	if (trend !== null) {
		res += ` (${trend})`;
	}

	return res;
}


function renderTrend(trend) {
	switch (trend) {
		case 'constant':
			return 'gleichbleibend';
		case 'increasing':
			return 'steigend';
		case 'decreasing':
			return 'sinkend';
		default:
			return null;
	}
}
