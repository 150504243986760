import proj4 from 'proj4';

/**
 * @param {Record<string, string>} params
 * @returns {string}
 */
// TODO dieselbe Funktionalität Map→url-parameters gibt es sicher schon woanders in dem Projekt: darauf umstellen
export function genUrlParams(params) {
	return Object.entries(params)
		.map(([key, val]) =>
			`${encodeURIComponent(key)}=${encodeURIComponent(val)}`
		)
		.join('&');
}


export default function formatLocation({lat, lon} = {}) {
	return lat && lon ? `${lat.toFixed(6)}, ${lon.toFixed(6)}` : '';
}


/**
 * @param {import('../types').Location} location
 * @returns{import('../types').MapsightCoords}
 */
export function locationToCoords(location) {
	return location && [location.lon, location.lat];
}

/**
 * @param {import('../types').MapsightCoords} coords
 * @returns {import('../types').Location}
 */
export function coordsToLocation(coords) {
	return {
		lat: coords[1],
		lon: coords[0],
	};
}

/**
 * @param {import('../types').Location} location
 */
export function locationToOlCoords(location) {
	return proj4('EPSG:3857', [location.lon, location.lat]);
}


/**
 * @param {number} lengthM
 * @returns {string}
 */
export function formatTripLength(lengthM) {
	if (lengthM < 100) {
		return '< 100 m';
	}
	if (lengthM < 1000) {
		return `${Math.round(lengthM / 10) * 10} m`;
	}
	// diese Formel funktioniert wirklich nur für >=1000m
	const lengthHm = Math.round(lengthM / 100);
	const lengthM2 = lengthHm % 10;
	return `${Math.floor(lengthHm / 10)}${lengthM2 > 0 ? `,${lengthM2}` : ''} km`;
}


/**
 * @param {number} durationSecs
 * @returns {string}
 */
export function formatTripDuration(durationSecs) {
	const HOUR = 3600;
	const MINUTE = 60;

	if (MINUTE >= durationSecs) {
		return '< 1 Minute';
	}
	let ret = '';
	if (durationSecs >= HOUR) {
		ret += `${Math.floor(durationSecs / HOUR)} Stunden`;
		durationSecs %= HOUR;
	}
	if (durationSecs >= MINUTE) {
		if (ret.length) {
			ret += ' und ';
		}
		ret += `${Math.floor(durationSecs / MINUTE)} Minuten`;
	}
	return ret;
}
