import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {FEATURE_SOURCES} from '@mapsight/ui/src/js/config/constants/controllers';

import {useMagic, useNavPos, useOnLinkToDetailsSource} from '../../hooks';
import {featureSourceInfoSelector, findFeatureSourceIdForFeatureId, pageHomeSelector} from '../../store/selectors';
import {findAreaAndTopicFromSourceId, getBaseUrl} from '../../routing/helpers';

import Link from '../link';

// wir können annehmen, dass alle auf der Karte sichtbaren area-spezifischen Features aus einer für die aktuelle area verfügbaren Quelle stammt
//		wir müssen also nur die Seite dazu finden.
//		keine Summary für die Spezialquellen
const FeatureDetailsSummary = memo(function FeatureDetailsSummary() {
	const baseUrl = getBaseUrl();
	const navPos = useNavPos();
	const featureSources = useSelector(state => state[FEATURE_SOURCES]);
	const featureSourceInfo = useSelector(featureSourceInfoSelector);
	const clearFeatureSelection = useOnLinkToDetailsSource();
	const startPage = useSelector(pageHomeSelector);

	const magic = useMagic();
	// eslint-disable-next-line prefer-const
	let {topic, area} = findAreaAndTopicFromSourceId(magic, featureSourceInfo.source) || {};

	if (!topic) {
		return null;
	}

	let areaChanged = false;
	if(topic && !topic.noArea && area && area.uri !== navPos.area.uri) {
			if(featureSources[`${navPos.area.uri}-${topic.uri}`] && findFeatureSourceIdForFeatureId({source: featureSources[`${navPos.area.uri}-${topic.uri}`]}, featureSourceInfo.featureId)) {
				area = navPos.area;
				areaChanged = true;
			}
	}

	// TODO translation

	let content = null;
	if (featureSourceInfo.foreign || startPage) {
		content = (
			<Link
				className="vmznds-list__more-link"
				to={`${baseUrl}${topic.noArea ? '' : area.uri + '/'}${topic.pageUri ? topic.pageUri : topic.uri}`}
				onClick={clearFeatureSelection}
			>
				Alle
				{!areaChanged && featureSourceInfo.totalCount ? ' ' + featureSourceInfo.totalCount + ' ' : ' '}
				{areaChanged  ? ' ' + featureSources[`${navPos.area.uri}-${topic.uri}`].data.features.length + ' ' : ' '}
				{featureSourceInfo.name ? ` in ${featureSourceInfo.name} ` : ' '}
				anzeigen{'\u00A0'}…
			</Link>
		);
	} else {
		content = (
			<a className="vmznds-list__more-link" onClick={clearFeatureSelection}>
				Zur Liste …
			</a>
		);
	}

	return (content ? (
				<div className="ms3-feature-details-content__summary">
					{content}
				</div>
			) :
			null
	);
});

export default FeatureDetailsSummary;
