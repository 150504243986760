import flatpickr from 'flatpickr';
import {German as flatpickrGermanLocale} from 'flatpickr/dist/l10n/de';
import React, {Fragment, useCallback, useEffect, useRef} from 'react';

import {
	dateToYmd,
	getFirstDayOfLastYear,
	getFirstDayOfMonth,
	getFirstDayOfYear,
	getLastDayOfLastYear,
	getLastDayOfMonth,
	getLastDayOfYear,
} from '../../helpers';

const DATEPICKER_OPTIONS = {
	locale: flatpickrGermanLocale,
	dateFormat: 'Y-m-d',
	altInput: true,
	altFormat: 'd.m.Y',
};

export default function TimeRangeSelection({startDateYmd, endDateYmd, setStartDateYmd, setEndDateYmd}) {
	const fromElementRef = useRef();
	const toElementRef = useRef();
	const fromFlatpickrInstanceRef = useRef(null);
	const toFlatpickrInstanceRef = useRef(null);
	const setDateRange = useCallback((startYmd, endYmd) => {
		setStartDateYmd(startYmd);
		setEndDateYmd(endYmd);

		if (fromFlatpickrInstanceRef.current) {
			fromFlatpickrInstanceRef.current.set('maxDate', endYmd);
			fromFlatpickrInstanceRef.current.setDate(startYmd, true, 'Y-m-d');
		}
		if (fromFlatpickrInstanceRef.current) {
			toFlatpickrInstanceRef.current.set('minDate', startYmd);
			toFlatpickrInstanceRef.current.setDate(endYmd, true, 'Y-m-d');
		}
	}, [setStartDateYmd, setEndDateYmd]);
	const handleStartDateChange = useCallback((e) => setStartDateYmd(e.target.value), [setStartDateYmd]);
	const handleEndDateChange = useCallback((e) => setEndDateYmd(e.target.value), [setEndDateYmd]);

	const handlePresetClick = useCallback((e) => {
		switch (e.target.getAttribute('data-rz-preset')) {
			case 'currentYear':
				setDateRange(getFirstDayOfYear(), getLastDayOfYear());
				break;

			case 'lastYear':
				setDateRange(getFirstDayOfLastYear(), getLastDayOfLastYear());
				break;

			case 'currentMonth':
				setDateRange(getFirstDayOfMonth(), getLastDayOfMonth());
				break;

			case 'lastMonth': {
				let month = (new Date()).getMonth() - 1; // 0 = January, -1 = December of last year
				let year = (new Date()).getFullYear();
				if (month === -1) {
					month = 11;
					year -= 1;
				}

				setDateRange(getFirstDayOfMonth(month + 1, year), getLastDayOfMonth(month + 1, year));
			}
				break;
			default:
		}
	}, [setDateRange]);

	useEffect(() => {
		fromFlatpickrInstanceRef.current = flatpickr(fromElementRef.current, {
			...DATEPICKER_OPTIONS,
			onChange: selectedDates => {
				if (selectedDates.length) {
					setStartDateYmd(dateToYmd(selectedDates[0]));
				}
			},
		});

		toFlatpickrInstanceRef.current = flatpickr(toElementRef.current, {
			...DATEPICKER_OPTIONS,
			onChange: selectedDates => {
				if (selectedDates.length) {
					setEndDateYmd(dateToYmd(selectedDates[0]));
				}
			},
		});
	}, [startDateYmd, endDateYmd, setStartDateYmd, setEndDateYmd]);

	useEffect(() => {
		if (startDateYmd) {
			toFlatpickrInstanceRef.current.set('minDate', startDateYmd);
			toFlatpickrInstanceRef.current.redraw();
		}
		if (endDateYmd) {
			fromFlatpickrInstanceRef.current.set('maxDate', endDateYmd);
			fromFlatpickrInstanceRef.current.redraw();
		}
	}, [fromFlatpickrInstanceRef, toFlatpickrInstanceRef, startDateYmd, endDateYmd, setStartDateYmd, setEndDateYmd]);

	return (
		<Fragment>
			<div>
				<label htmlFor="vmznds-rz-wizard-input-from">
					Vom
					<input className="vmznds-rz-wizard-input" type="date" id="vmznds-rz-wizard-input-from"
						value={startDateYmd} onChange={handleStartDateChange} ref={fromElementRef} />
				</label>
				<label htmlFor="vmznds-rz-wizard-input-to">
					bis einschließlich
					<input className="vmznds-rz-wizard-input" type="date" id="vmznds-rz-wizard-input-to"
						value={endDateYmd} onChange={handleEndDateChange} ref={toElementRef} />
				</label>
			</div>
			<div>
				Vorschläge:<br />
				<button className="vmznds-rz-wizard-button vmznds-rz-wizard-button--small" type="button"
					data-rz-preset="lastYear" onClick={handlePresetClick}>Letztes Jahr
				</button>
				<button className="vmznds-rz-wizard-button vmznds-rz-wizard-button--small" type="button"
					data-rz-preset="lastMonth" onClick={handlePresetClick}>Letzter Monat
				</button>
				<button className="vmznds-rz-wizard-button vmznds-rz-wizard-button--small" type="button"
					data-rz-preset="currentYear" onClick={handlePresetClick}>Laufendes Jahr
				</button>
				<button className="vmznds-rz-wizard-button vmznds-rz-wizard-button--small" type="button"
					data-rz-preset="currentMonth" onClick={handlePresetClick}>Laufender Monat
				</button>
			</div>
		</Fragment>
	);
}
