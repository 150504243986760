import React, {Fragment, memo} from 'react';

import ZoomButtons from '@mapsight/ui/src/js/components/map-overlay/zoom-buttons';
import UserGeoLocationButton from '@mapsight/ui/src/js/components/map-overlay/user-geo-location-button';

import {memoForever} from '../helpers';


/** @type {import("@mapsight/ui/src/js/components/contexts").MapOverlayBottomRight} */
const MapOverlayBottomRight = memo(function MapOverlayBottomRight({}) {
		return (
			<Fragment>
				<ZoomButtons />
				<UserGeoLocationButton />
			</Fragment>
		);
	},
	memoForever
);

export default MapOverlayBottomRight;
