// Anforderungen and den Rewrite
// - entkoppeln: keine indirekten Aufrufe eines observe-Handlers durch das dispatch eines anderen observe-Handlers desselben Controllers
// - performance: batchActions nutzen, die an einer Stelle in einem dispatch alle Datenänderungen aufgrund er User-Interaktion bündeln
// - @mapsight-style: Nutzung des Controllers und des Reducers auch für die Verbindungsanfragen

// TODO navigation rewrite TODOs
// - check ob alles auf setBy: 'userLocation' klar kommt
// - Routenanfragen anhand von modality, requestIds und und datetime nur die relevanten holen, observe auf tripParameterSelector
// - animateMap wenn Route vorliegt
// - Test von animateMap wenn Start und Ziel identisch (benötigt gute Internetverbindung / zu Hause testen)
// - aufräumen / abhängigkeiten vom alten Verzeichnis entfernen
// - console.logs entfernen soweit in Zukunft überflüssig
// - eslint
// - altes Verzeichnis löschen

/* eslint-disable no-use-before-define */
import {batchActions} from 'redux-batched-actions';
import debounce from 'lodash/debounce';

import getPath from '@neonaut/lib-js/es/object/getPath';
import {observeState} from '@neonaut/lib-redux/observe-state';

import {BaseController} from '@mapsight/core/lib/base/controller';
import {animate as animateAction, setInteractionStatus} from '@mapsight/core/lib/map/actions';
import {setData} from '@mapsight/core/lib/feature-sources/actions';
import getFeatureProperty from '@mapsight/ui/src/js/helpers/get-feature-property';
import {FEATURE_SOURCES, MAP} from '@mapsight/ui/src/js/config/constants/controllers';

import {coordsToLocation, locationToCoords, locationToOlCoords} from './api/helpers';
import {enabledSelector, inputSelectors, tripParameterSelector, tripsSelector} from './selectors';
import {DRAW_INTERACTION, ROUTE_FETCH_MATRIX, NAVIGATION, ROUTE_LAYERS, ROUTE_STOPS_LAYER} from './constants';

import {
	getTripBike,
	getTripCar,
	getTripPublic,
	setInputLocation,
	SELECT_SUGGESTION,
	SET_DATETIME,
	SET_INPUT,
	SET_SUGGESTIONS,
	TRIP_RESULT
} from './actions';


/**import('./types).Store*/
export const initialState = {
	enabled: false, // TODO document: active: we are on the navigation page
	modality: 'car',
	origin: {
		input: {},
		suggestions: {},
	},
	dest: {
		input: {},
		suggestions: {},
	},
	trips: {
		car: {},
		bike: {},
		public: {},
	}
};

// TODO die DrawInteraction genauer angucken, weil eventuell haben gezeichnete POI eine von mir aktuell gar nicht beobachtete id
// ja so ist es: draw fügt weitere Punkte hinzu, die neue Namen (eine Zahl) haben → daran kann man erkennen ob es draw war oder modify
// TODO testen, ob modify Daten resetet. Im Zweifel aber einfach die selbst-gesetzte Geometry zusätzlich in prorperties ablegen. Ist sie gleich, war es ein Update vom Textfeld, ist sie unterschiedliche ein modify
export class NavigationController extends BaseController {
	constructor() {
		super();

		/**boolean*/
		this.isEnabled = false;
		/**
		 * @type {{
		 * 	origin: import('./types').Location | null,
		 * 	dest: import('./types').Location | null
		 * }}
		 */
		this.currentLocations = {origin: null, dest: null};
		this.debouncedHandleModifiedFeatures = debounce(this.handleModifiedFeatures.bind(this), 400);
	}

	/**
	 * @param {import('./types').Store} state
	 * @param {any} action
	 * @param {object} globalState
	 * @returns {
	 *   {
	 *       [p: number]: {
	 *           input: *
	 *           suggestions: *&{showSuggestions: *}
	 *       }
	 *   } | {trips} | {
	 *        [p: number]: *&{
	 *            suggestions: *&{entries: *}
	 *        }
	 *   } | * | {datetime} | {}
	 * }
	 */
	reduce(state = {}, action, globalState) {
		const requestDatetime = state.datetime;
		const requestIds = {
			origin: getPath(state, ['origin', 'input', 'requestId'], 0),
			dest: getPath(state, ['dest', 'input', 'requestId'], 0),
		};
		const suggestionsRequestIds = {
			origin: getPath(state, ['origin', 'suggestions', 'requestId']),
			dest: getPath(state, ['dest', 'suggestions', 'requestId']),
		};

		// eslint-disable-next-line default-case
		switch (action.type) {
			case TRIP_RESULT:
				if (
					action.data.datetime && action.data.datetime === requestDatetime
					&& action.data.requestIds.origin === requestIds.origin
					&& action.data.requestIds.dest === requestIds.dest
				) {
					return {
						...state,
						trips: {
							...state.trips,
							[action.modality]: action.data,
						}
					};
				}
				break;

			case SET_INPUT:
				console.log('navigation controller reduce SET_INPUT', {data: action.data, requestIds, inputTarget: action.inputTarget});
				// test of requestId ist nötig, weil das auch von setInputLocation genutzt wird
				if (action.data.requestId >= requestIds[action.inputTarget]) {
					return {
						...state,
						[action.inputTarget]: {
							input: {
								...state[action.inputTarget]?.input,
								...action.data,
							},
							suggestions: {
								...state[action.inputTarget]?.suggestions,
								showSuggestions: action.showSuggestions,
							}
						}
					};
				}
				break;

			case SET_SUGGESTIONS:
				if (action.requestId === suggestionsRequestIds[action.inputTarget]) {
					return {
						...state,
						[action.inputTarget]: {
							...state[action.inputTarget],
							suggestions: {
								...state[action.inputTarget].suggestions,
								entries: action.entries,
							}
						}
					};
				}
				break;

			case SELECT_SUGGESTION:
				if (action.requestId >= requestIds[action.inputTarget]) {
					const suggestion = state[action.inputTarget].suggestions.entries[action.id];
					if (!suggestion) {
						console.warn('NavigationController SELECT_SUGGESTION – no suggestion');
						return state;
					}
					return {
						...state,
						[action.inputTarget]: {
							input: {
								...state[action.inputTarget].input,
								requestId: action.requestId,
								name: suggestion.name,
								location: suggestion.location,
							},
							suggestions: {
								...state[action.inputTarget].suggestions,
								showSuggestions: false, // TODO ... weitere UI-Eigenschaften falls nötig
							}
						}
					};
				}
				break;

			case SET_DATETIME:
				return {
					...state,
					datetime: action.date
				};
		}

		return super.reduce(state, action, globalState);
	}

	init() {
		super.init();
		const store = this.getStore();

		//  enabled action mit updateInteractionStatus
		observeState(store, enabledSelector, enabled => {
			const {origin, dest} = this.currentLocations;
			this.isEnabled = enabled;
			updateInteractionStatus(enabled, origin, dest);
		});

		// Route(n) berechnen
		observeState(store, tripParameterSelector,
			/**
			 * @param {object} props
			 * @param {boolean} props.enabled
			 * @param {import('./types').Modality} props.modality
			 * @param {Array<object>} props.tripParameters
			 */
			({enabled, modality, ...tripParameters}) => {
				if (
					!tripParameters.origin.requestId || !tripParameters.dest.requestId
					|| !tripParameters.origin.location || !tripParameters.dest.location
				) {
					store.dispatch(setData(FEATURE_SOURCES, ROUTE_LAYERS[modality], {
						type: 'FeatureCollection',
						features: [],
					}));
					return;
				}
				const trips = tripsSelector(store.getState()); // we do not want to observe this, we just want to compare with it
				const {origin, dest, datetime} = tripParameters;
				/**import('./types').TripRequestIds*/const requestIds = {
					origin: tripParameters.origin.requestId,
					dest: tripParameters.dest.requestId
				};
				const actions = [];

				if (ROUTE_FETCH_MATRIX[modality].car && needsRoute(requestIds, datetime, trips, 'car')) {
					actions.push(
						getTripCar(origin.location, dest.location, datetime, requestIds)
					);
				}
				if (ROUTE_FETCH_MATRIX[modality].bike && needsRoute(requestIds, datetime, trips, 'bike')) {
					actions.push(
						getTripBike(origin.location, dest.location, datetime, requestIds)
					);
				}
				if (ROUTE_FETCH_MATRIX[modality].public && needsRoute(requestIds, datetime, trips, 'public')) {
					actions.push(
						getTripPublic(origin.location, dest.location, datetime, requestIds)
					);
				}

				console.log('NavigationController observe tripParameterSelector', {
					modality,
					fetchMatrix: ROUTE_FETCH_MATRIX[modality],
					car: (ROUTE_FETCH_MATRIX[modality].car && needsRoute(requestIds, datetime, trips, 'car')),
					bike: (ROUTE_FETCH_MATRIX[modality].bike && needsRoute(requestIds, datetime, trips, 'bike')),
					public: (ROUTE_FETCH_MATRIX[modality].public && needsRoute(requestIds, datetime, trips, 'public')),
					actionsCount: actions.length,
					actions,
				});

				if (actions.length) {
					store.dispatch(batchActions(actions));
				}
			}
		);

		// Textcontrol, Link, UserLoaction im Navigation-Store → map, nach Textfeldern getrennt
		['origin', 'dest'].forEach(
			inputTarget => observeState(
				store,
				inputSelectors[inputTarget],
				/**LocationInput*/input => {
					if (input.setBy !== 'map') {
						this.currentLocations[inputTarget] = input.location;
						const {origin, dest} = this.currentLocations;
						const featuresData = featureSourceData(origin && locationToCoords(origin), dest && locationToCoords(dest), 'text');
						const actions = [
							(setData(FEATURE_SOURCES, ROUTE_STOPS_LAYER, featuresData)), // kein async ... warum das der alte Code hatte, erschließt sich mir (@herbert) nicht
							updateInteractionStatus(this.isEnabled, origin, dest)
						];
						const animate = (animateMap(origin, dest)); // async im alten Code, aber eigentlich auch unnötig, der Map-Controller fährt die Aninmation ja dann sowieso außerhalb des Reducers
						if(animate) {
							actions.push(animate);
						}
						store.dispatch(batchActions(actions));
					}
				}
			)
		);

		// map → Navigation-Store, also ins Textcontrol, beide zusammen, da es auch das Zeichnen von Punkten umfasst, zusätzliche Features anlegt
		observeState(
			store,
			createUnfilteredFeaturesSelector(FEATURE_SOURCES, ROUTE_STOPS_LAYER),
			features => {
				if (features?.length > 2) { // FIXME aktuell werden die beiden leeren Features beim enable nicht richtig angelegt
					// draw
					this.handleDrawnFeatures(features);
				} else {
					this.debouncedHandleModifiedFeatures(features);
				}
			}
		);
	}

	//  was macht mapsight beim ändern? gehen die properties verloren?
	//  der code funktioniert sowohl wenn die Properties beibehalten werden als auch wenn sie gelöscht werden
	handleModifiedFeatures(features) {
		if (!features || !features.length) {
			return;
		}
		const newOriginCoords = features[0]?.geometry?.coordinates;
		const newDestinationCoords = features[1]?.geometry?.coordinates;

		const actions = [];

		if (newOriginCoords && JSON.stringify(newOriginCoords) !== JSON.stringify(getFeatureProperty(features[0], 'setCoords'))) {
			this.currentLocations.origin = coordsToLocation(newOriginCoords);
			actions.push(
				setInputLocation(NAVIGATION, 'origin', this.currentLocations.origin)
			);
		}
		if (newDestinationCoords && JSON.stringify(newDestinationCoords) !== JSON.stringify(getFeatureProperty(features[1], 'setCoords'))) {
			this.currentLocations.dest = coordsToLocation(newDestinationCoords);
			actions.push(
				setInputLocation(NAVIGATION, 'dest', this.currentLocations.dest)
			);
		}

		if (actions.length) {
			this.getStore().dispatch(batchActions([
				...actions,
				// hier ist das setData erforderlich, um die Property 'setCoords' zu aktualisieren
				setData(FEATURE_SOURCES, ROUTE_STOPS_LAYER, featureSourceData(
					locationToCoords(this.currentLocations.origin),
					locationToCoords(this.currentLocations.dest)
				)),
				updateInteractionStatus(this.isEnabled,
					this.currentLocations.origin,
					this.currentLocations.dest
				),
			]));
		}
	}

	handleDrawnFeatures(features) {
		// if (features && features.length > 2) ... schon vor dem Aufruf
		// die unteren beiden Features sind immer die vom Controller gesetzten
		// effektiv ist length nie > 3 weil der Code hier max. zwei zulässt und dann wird ja immer nur ein Punkt dazu gezeichnet
		// daher sollte newestFeatures nie mehr als 1 haben
		const newestFeatures = features.slice(2).slice(-2);
		let nextOriginCoords;
		let nextDestCoords;
		if (!this.currentLocations.origin) {
			nextOriginCoords = newestFeatures[0].geometry.coordinates;

			if (newestFeatures.length > 1) {
				if (!this.currentLocations.dest) {
					nextDestCoords = newestFeatures[1].geometry.coordinates;
				}
			}
		} else if (!this.currentLocations.dest) {
			nextDestCoords = newestFeatures[0].geometry.coordinates;
		}


		const {origin: oldOrigin, dest: oldDest} = this.currentLocations;

		// leider unterstützt batchActions keine async action-Funktionen. Daher braucht setInputLocation ein eigenes dispatch
		// Wir können die aber zuerst dispatchen, weil sie wegen dem async trotzdem erst nach den batched actions abgearbeitet werden
		if (nextOriginCoords) {
			this.currentLocations.origin = coordsToLocation(nextOriginCoords);
			this.dispatch(
				setInputLocation(NAVIGATION, 'origin', this.currentLocations.origin)
			);
		}
		if (nextDestCoords) {
			this.currentLocations.dest = coordsToLocation(nextDestCoords);
			this.dispatch(
				setInputLocation(NAVIGATION, 'dest', this.currentLocations.dest)
			);
		}

		console.warn('navgiation controller handleDrawnFeatures', {
			features,
			haveNewOrigin: !!nextOriginCoords,
			nextDestCoords,
			nextOriginCoords,
			newestFeatures,
			oldOrigin,
			oldDest,
			actions
		});

		const actions = [
			// hier ist das setData erforderlich, um die überzähligen Punkte zu entfernen
			setData(FEATURE_SOURCES, ROUTE_STOPS_LAYER, featureSourceData(
				nextOriginCoords || oldOrigin && locationToCoords(oldOrigin),
				nextDestCoords || oldDest && locationToCoords(oldDest),
				'map'
			)),
			updateInteractionStatus(this.isEnabled,
				this.currentLocations.origin,
				this.currentLocations.dest
			),
		];

		this.getStore().dispatch(batchActions(actions));
	}
}

// FIXME tag this function with export in @mapsight/core/lib/feature-sources/selectors.js
function createUnfilteredFeaturesSelector(featureSourcesControllerName, featureSourceId) {
	return state => state[featureSourcesControllerName] &&
		state[featureSourcesControllerName][featureSourceId] &&
		state[featureSourcesControllerName][featureSourceId].data &&
		state[featureSourcesControllerName][featureSourceId].data.features || null;
}


/**
 * @param features features in ROUTE_STOPS_LAYER's data
 * @returns redux action object
 */
const updateInteractionStatus = (isEnabled, origin, dest) => setInteractionStatus(
	MAP,
	DRAW_INTERACTION,
	isEnabled && (!origin || !dest)
);

/**
 * @param {import("../store/types").MapsightCoords} originCoords
 * @param {import("../store/types").MapsightCoords} destCoords
 * @returns {{features: [{geometry: {coordinates, type: string}, id: string, type: string, properties: {mapsightIconId: string, name: string, tooltip: string}},{geometry: {coordinates, type: string}, id: string, type: string, properties: {mapsightIconId: string, name: string, tooltip: string}}], type: string}}
 */
const featureSourceData = (originCoords, destCoords) => ({
	type: 'FeatureCollection',
	features: [
		{
			id: 'vmznds-pre-routing-trip-start',
			type: 'Feature',
			properties: {
				name: 'Start der Route',
				mapsightIconId: 'flag-start',
				tooltip: 'Zum Ändern des Startpunkts ziehen',
				setCoords: originCoords,
			},
			geometry: originCoords && {
				type: 'Point',
				coordinates: originCoords,
			},
		},
		{
			id: 'vmznds-pre-routing-trip-finish',
			type: 'Feature',
			properties: {
				name: 'Ziel der Route',
				mapsightIconId: 'flag-finish',
				tooltip: 'Zum Ändern des Zielpunkts ziehen',
				setCoords: destCoords,
			},
			geometry: destCoords && {
				type: 'Point',
				coordinates: destCoords,
			},
		},
	],
});

/**
 *
 * @param {import('./types').Location} [origin]
 * @param {import('./types').Location} [dest]
 * @param [bbox]
 * @returns {*}
 */
// this can't be used as an action, as on some path's it doesn't return an action object and batchActions isn't prepared for that to be the case
const animateMap = (origin, dest, bbox) => {
	if (
		!origin && !dest
	) {
		return undefined;
	}

	const options = {
		duration: 1000,
		padding: [60, 20, 60, 20],
		maxZoom: 17,
	};

	if (bbox) {
		options.bounds = bbox;
	} else if (origin && dest) {
		const [x1, y1] = locationToOlCoords(origin);
		const [x2, y2] = locationToOlCoords(dest);

		options.bounds = [Math.min(x1, x2), Math.min(y1, y2), Math.max(x1, x2), Math.max(y1, y2)];
	} else if (origin || dest) {
		options.center = locationToOlCoords(origin || dest);
	} else {
		return undefined;
	}

	return (animateAction('map', options));
};

function needsRoute(requestIds, datetime, trips, modality) {
	const {
		/**include('./types').TripRequestIds*/requestIds: tripRequestIds,
		/**string*/datetime: tripDatetime
	} = trips[modality];
	// console.log('navigation controller: needsRoute '+modality, (
	// 	datetime !== tripDatetime
	// 	|| !tripRequestIds?.origin || !tripRequestIds?.dest
	// 	|| requestIds.origin !== tripRequestIds.origin
	// 	|| requestIds.dest !== tripRequestIds.dest
	// ), {requestIds, tripRequestIds, datetime, tripDatetime, trips});
	return (
		datetime !== tripDatetime
		|| !tripRequestIds?.origin || !tripRequestIds?.dest
		|| requestIds.origin !== tripRequestIds.origin
		|| requestIds.dest !== tripRequestIds.dest
	);
}
