import React, {memo} from 'react';

import {memoForever} from '../../../mapsight-vmznds/helpers';

import DatePicker from './date-picker';
import LocationInput from './location-input';
import NavigationResult from './navigation-result';
import Modality from './modality';


export default memo(
	function NavigationBox() {
		return (
			<div className="ms3-pre-routing-box">
				<Modality />

				<DatePicker />

				<LocationInput
					inputTarget="origin"
					label="Von:"
					placeholder="Start eingeben ..."
				/>

				{/* TODO: replace hardcoded styles, das ist ein Pfeil nach unten */}
				<div style={{textAlign: 'center', height: 0, overflow: 'visible', lineHeight: '21px'}}>
					{/* TODO: extract svg asset */}
					<svg width="7px" height="11px" viewBox="0 0 7 11">
						<polygon stroke="none" fill="#0066B4"
								 points="3.6244507 10.8773804 0.418396 7.6713257 3.0365601 7.6713257 3.0365601 0.1694946 4.2191772 0.1694946 4.2191772 7.6713257 6.8305054 7.6781616" />
					</svg>
				</div>

				<LocationInput
					inputTarget="dest"
					label="Nach:"
					placeholder="Ziel eingeben ..."
				/>

				{/* Do something with this button? just here for decoration for now …  NO, it's better usability to not have a button
				<button style={{margin: '10px auto'}} className="vmznds-button" type="button">Berechnen</button>*/}

				<NavigationResult />
			</div>
		);
	},
	memoForever
);
