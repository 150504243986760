import {useMemo} from 'react';
import getFeatureProperty from '@mapsight/ui/src/js/helpers/get-feature-property';

import {useCacheBustingUrl} from '../../hooks';

// TODO use refreshInterval from geojson if there's one (not needed now, but maybe when more cameras will be available
export default function useCameraUrl(feature) {
	const imgRefBase = useMemo(
		() => {
			let value = null;
			try { // new URL may cause exception
				const url = new URL(getFeatureProperty(feature, 'webcamImageUrl'), window.location.origin);
				if (url && typeof window !== 'undefined') {
					value = window.location.origin + url.pathname + url.search;
				} else {
					value = url.href;
				}
			} catch {
				// NOP
			}
			return value;
		},
		[feature]
	);

	return useCacheBustingUrl(
		// reload every 60sec
		60 * 1000,
		typeof imgRefBase === 'string' ? imgRefBase : null
	);
}
