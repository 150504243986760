import {ACTION_REMOVE_FROM, ACTION_SET, baseReducer} from '@mapsight/core/lib/base/reducer';

import {FeatureSelectionsController} from '@mapsight/core/lib/feature-selections/controller';
import {FEATURE_SELECTION_PRESELECT, FEATURE_SELECTION_SELECT} from '@mapsight/ui/src/js/config/feature/selections';
import {FEATURE_LIST, FEATURE_SOURCES} from '@mapsight/ui/src/js/config/constants/controllers';

import {history} from '../renderer/browser-router';
import {findAreaAndTopicFromSourceId, getBaseUrl, navPosition, QS_REDUCED} from '../routing/helpers';

import {ACTION_SET_FROM_ROUTE, ACTION_SET_VIA_HISTORY} from './actions';
import {findFeatureSourceIdForFeatureId, pageHomeSelector} from './selectors';
import {SPECIAL_PAGE_NAVIGATION} from './controller';

let _magic = null;

const featureSourceInfoForId = (state, featureId) => {
	const featureSources = state[FEATURE_SOURCES];
	const listSourceId = state[FEATURE_LIST].featureSource;

	// prefer list feature source
	const source = featureSources[listSourceId] && findFeatureSourceIdForFeatureId({[listSourceId]: featureSources[listSourceId]}, featureId) ?
		listSourceId :
		findFeatureSourceIdForFeatureId(featureSources, featureId);

	return {
		source,
		listSource: listSourceId,
		featureId,
		foreign: listSourceId !== source,
	};
};

// we can't call history.push imedeatly as is will start a new redux cycle via route-to-store
// so we use setTimeout to put it on hold till the reducer are finished
// (as js has a single threaded execution model, a timeout of 1ms will wait till the current execution path is finished)
let _timeoutHandler = null;

function queueHistory(url, state, debug) {
	// console.log('NavposSelectionController queueHistory',{url, state, debug});
	// if(debug?.type === 'MAPSIGHT_SET') {
	// 	console.trace();
	// }
	clearTimeout(_timeoutHandler);
	_timeoutHandler = setTimeout(
		() => {
			// console.trace('NavposSelectionController setting history',{url, state, debug});
			history.push(url, state);
		},
		1
	);
}

function reduceDeselectAllFeatureSelect(state, action, globalState) {
	const navPos = navPosition(_magic, history.location.pathname + history.location.search, history.location.state);

	if (
		((navPos.topic?.deepLinks && navPos.deepLink) || navPos.select)
		&& navPos.topic?.type !== SPECIAL_PAGE_NAVIGATION // setzt neuerdings deepLinks auf true, aber leitet keine selection davon ab
	) {
		// console.log('NavposSelectionController deselect pushed url ', {
		// 		url: `${getBaseUrl()}${navPos.topic.noArea ? '' : navPos.area.uri + '/'}` +
		// 			`${navPos.topic.pageUri ? navPos.topic.pageUri : navPos.topic.uri}/` +
		// 			`${navPos.reduced ? '?' + QS_REDUCED : ''}`,
		// 		navPos,
		// 		location: history.location
		// });

		// deepLink entfernen, route-to-store ruft dann auch deselectAll auf
		queueHistory(
			`${getBaseUrl()}${navPos.topic.noArea ? '' : navPos.area.uri + '/'}` +
			`${navPos.topic.pageUri ? navPos.topic.pageUri : navPos.topic.uri}/` +
			`${navPos.reduced ? '?' + QS_REDUCED : ''}`,
			{},
			// {...action, globalState, deselectAll, selectExcl, isSelectionSelect, lineno: 91}
		);
	} else {
		state = baseReducer(state, action);
	}
	return state;
}

function reduceSelectExclFeatureSelect(state, action, globalState) {
	const navPos = navPosition(_magic, history.location.pathname + history.location.search, history.location.state);

	const featureSources = globalState[FEATURE_SOURCES];
	const featureSourceInfo = featureSourceInfoForId(globalState, action.value.length ? action.value[0] : action.value);
	const startPage = pageHomeSelector(globalState);

	// eslint-disable-next-line prefer-const
	let {topic, area} = findAreaAndTopicFromSourceId(_magic, featureSourceInfo.source) || {};

	if (topic && !topic.noArea && area &&
		area.uri !== navPos.area.uri &&
		featureSources[`${navPos.area.uri}-${topic.uri}`] &&
		findFeatureSourceIdForFeatureId({source: featureSources[`${navPos.area.uri}-${topic.uri}`]}, featureSourceInfo.featureId)
	) {
		// featureSourceInfoSelector und/oder findAreaAndTopicFromSourceId haben das Element in der falschen Area gefunden,
		// wir bleiben bei der aktuellen wenn das feature auch dort vorhanden ist
		area = navPos.area;
	}

	if (
		!topic || startPage ||
		(!topic.deepLinks && !topic.deepContent) ||
		featureSourceInfo.foreign || !featureSourceInfo.featureId ||
		topic.uri !== navPos?.topic?.uri ||
		navPos.topic?.type === SPECIAL_PAGE_NAVIGATION // setzt neuerdings deepLinks auf true, aber leitet keine selection davon ab
	) {
		// wenn keine deepLinks erlaubt sind, das select in den url-state packen, damit es Teil der Browser-History wird
		// console.log('NavposSelectionController pushed url ', {
		// 	url:
		// 		(startPage ?
		// 				`${getBaseUrl()}${navPos?.areaFound ? area.uri : ''}` :
		// 				`${getBaseUrl()}${topic.noArea ? '' : area.uri + '/'}` +
		// 				`${navPos.topic ? (navPos.topic.pageUri ? navPos.topic.pageUri : topic.uri) + '/' : ''}`
		// 		) +
		// 		`${navPos.reduced ? '?' + QS_REDUCED : ''}`,
		// 	navPos,
		// 	location: history.location
		// });

		// Dieser Zweig ist eigentlich nur möglich wenn entweder auf der Startseite oder auf einer Seite mit Topic
		queueHistory(
			(startPage ?
					`${getBaseUrl()}${navPos?.areaFound ? area.uri : ''}` :
					`${getBaseUrl()}${topic.noArea ? '' : area.uri + '/'}` +
					`${navPos.topic ? (navPos.topic.pageUri ? navPos.topic.pageUri : topic.uri) + '/' : ''}`
			) +
			`${navPos.reduced ? '?' + QS_REDUCED : ''}`,
			{select: action.value},
			// {...action, globalState, deselectAll, selectExcl, isSelectionSelect, lineno: 136}
		);
	} else {
		// console.log('NavposSelectionController pushed url ', {
		// 	url: `${getBaseUrl()}${topic.noArea ? '' : area.uri + '/'}` +
		// 		`${topic.pageUri ? topic.pageUri : topic.uri}/` +
		// 		`${featureSourceInfo.featureId}/` +
		// 		`${navPos.reduced ? '?' + QS_REDUCED : ''}`,
		// 	navPos,
		// 	location: history.location
		// });

		queueHistory(
			`${getBaseUrl()}${topic.noArea ? '' : area.uri + '/'}` +
			`${topic.pageUri ? topic.pageUri : topic.uri}/` +
			`${featureSourceInfo.featureId}/` +
			`${navPos.reduced ? '?' + QS_REDUCED : ''}`,
			{},
			// {...action, globalState, deselectAll, selectExcl, isSelectionSelect, lineno: 148}
		);
	}
	return state;
}

function reduceLikePreselectionController(state, action, globalState) {
	state = baseReducer(state, {...action, type: ACTION_SET});

	if (action.path.length === 2 && action.path[0] === FEATURE_SELECTION_SELECT &&
		(
			(Array.isArray(action.value) && action.value.length > 0) ||
			(action.value && !Array.isArray(action.value))
		)
	) {
		state = baseReducer(state, {...action, type: ACTION_SET, path: [FEATURE_SELECTION_PRESELECT, action.path[1]]});
	}
	return state;
}

export class NavposSelectionController extends FeatureSelectionsController {
	constructor(controllerName, magic) {
		super(controllerName);
		NavposSelectionController.setMagic(magic);
	}

	static setMagic(magic) {
		_magic = magic;
	}

	reduce(state = {}, action, globalState) {
		const isSelectionSelect = (action.path?.length === 2 && action.path[0] === FEATURE_SELECTION_SELECT);
		const deselectAll = (isSelectionSelect &&
			(
				(action.type === ACTION_SET_VIA_HISTORY && Array.isArray(action.value) && action.value.length === 0) ||
				(action.type === ACTION_SET && Array.isArray(action.value) && action.value.length === 0) ||
				(action.type === ACTION_REMOVE_FROM && ((baseReducer(state, action))[FEATURE_SELECTION_SELECT][action.path[1]].length) === 0)
			)
		);
		const selectExcl = (
			(action.type === ACTION_SET || action.type === ACTION_SET_VIA_HISTORY) &&
			isSelectionSelect &&
			(
				(Array.isArray(action.value) && action.value.length > 0) ||
				(action.value && !Array.isArray(action.value))
			)
		);

		// console.log('NavposSelectionController', {
		// 	action,
		// 	isSelectionSelect,
		// 	deselectAll,
		// 	selectExcl,
		// 	baseReducer: baseReducer(state, action),
		// });

		// console.log('NavposSelectionController', {...action, globalState, deselectAll, selectExcl, isSelectionSelect});
		if (history && deselectAll) {
			return reduceDeselectAllFeatureSelect(state, action, globalState);
		} else if (history && selectExcl) {
			return reduceSelectExclFeatureSelect(state, action, globalState);
		} else if (action.type === ACTION_SET_FROM_ROUTE || (!history && (selectExcl || deselectAll))) {
			return reduceLikePreselectionController(state, action, globalState);
		} else if (action.type === ACTION_SET_VIA_HISTORY) {
			// ACTION_SET_VIA_HISTORY for use case we're not interested here
			return baseReducer(state, {...action, type: ACTION_SET});
		}

		return baseReducer(state, action);
	}
}
