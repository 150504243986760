import React, {Fragment, memo} from 'react';

import {NavAppLink, readNunavDataLink} from '../../../modules/navigation/components/qr-link';

import FeatureDetailsContentAnomaly from './feature-details-content-anomaly';
import FeatureDetailsContentPundr from './feature-details-content-pundr';
import FeatureDetailsContentSchul from './feature-details-content-schul';
import FeatureDetailsContentWheelCounter from './feature-details-content-wheel-counter';


/**
 * @type {import("@mapsight/ui/src/js/components/contexts.js").FeatureDetailsContent}
 */
const FeatureDetailsContent = memo(
	function FeatureDetailsContent(props) {
		const {feature} = props;
		let {children} = props;
		let nunavLink = null;

		if (
			typeof feature === 'object'
			&& feature !== null
			&& typeof feature.properties === 'object'
			&& feature.properties !== null
		) {
			if(typeof feature.properties.type === 'string') {
				const featType = feature.properties.type;

				switch (featType) {
					case 'anomaly':
						children = (
							<FeatureDetailsContentAnomaly {...props} />
						);
						break;

					case 'schul':
						children = (
							<FeatureDetailsContentSchul {...props} />
						);
						break;

					case 'pundr':
						children = (
							<FeatureDetailsContentPundr {...props} />
						);
						break;

					case 'wheel-counter':
						children = (
							<FeatureDetailsContentWheelCounter {...props} />
						);
						break;

					default:
				}
			}

			nunavLink = readNunavDataLink(feature.properties.description);
		}

		return (
			<Fragment>
				{children}
				{feature && feature.properties && nunavLink &&
					<div className="vmznds__infobox vmznds__infobox--footer">
						<NavAppLink name={feature.properties.name} nunavLink={nunavLink} />
					</div>
				}
			</Fragment>
		);
	}
);

export default FeatureDetailsContent;
