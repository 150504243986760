import React, {memo} from 'react';
import {useDispatch} from 'react-redux';
import {batchActions} from 'redux-batched-actions';

import easing from 'ol/easing';
import {animate as animateMap} from '@mapsight/core/lib/map/actions';
import {deselectAll} from '@mapsight/core/lib/feature-selections/actions';

import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';
import {FEATURE_SELECTION_PRESELECT, FEATURE_SELECTION_SELECT} from '@mapsight/ui/src/js/config/feature/selections';
import {FEATURE_SELECTIONS, MAP} from '@mapsight/ui/src/js/config/constants/controllers';

import {getBaseUrl} from '../routing/helpers';
import {useMagic, useNavPos} from '../hooks';
import {deselectAllViaHistory, setNavigationOpen} from '../store/actions';

import Link from './link';


export default memo(
	/**
	 * @param {{
	 * }} props
	 * @returns {null | React.ReactElement}
	 */
	function AreaSwitch({}) {
		const magic = useMagic();
		const navPos = useNavPos();
		const baseUrl = getBaseUrl();
		const dispatch = useDispatch();

		if (!magic.areas) {
			return null;
		}

		const notNoArea = !(navPos.topic && navPos.topic.noArea);
		const areaButtons = magic.areas.map((area) => {
			const selected = navPos.area && navPos.area.uri === area.uri;
			const uri = `${baseUrl}${notNoArea ? area.uri +'/' : ''}${navPos.topic && navPos.topic.pageUri ? navPos.topic.pageUri : ''}` +
				`${navPos.deepLink ? '/' + navPos.deepLink : ''}`;

			// Hier kein useCallback! Ist eine Fehler und bringt nichts.
			// ein useCallback kann eigentlich nur verwendet werden, weil magic und insbesonders magic.areas aktuell effektiv konstant sind, wenn sich die Anzahl der areas im laufenden Betrieb ändert, hat man plötzlich unterschiedlich viele use-Aufrufe und react dreht durch
			// man könnte es optimieren indem man die Erzeugung der callbacks in eine memoizee-d Funktion auslagert, die ohne das Eintragen in der React-Komponente auskommt und dadurch keine Problem mit der potentiell wechselnden Anzahl der areas hat.
			// Der Aufwand lohnt aber nicht. Immer wenn die Komponente wegen neuer Parameter rerendert, braucht es wegen auch neue Callbacks (2 der 4 werden wegen selected ausgetauscht, bei topic-Wechsel alle).
			function onClick() {
				const actions = [
					setNavigationOpen(false),
					deselectAll(FEATURE_SELECTIONS, FEATURE_SELECTION_PRESELECT),
					deselectAllViaHistory(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT),
				];
				if (selected) {
					actions.unshift(
						animateMap(MAP, {
							nearest: true,
							duration: 1000,
							easing: easing.easeOut,
							bounds: navPos.area.bounds,
						}));
				}
				dispatch(batchActions(actions));
			}

			return (
				<li
					key={`${area.uri}`}
					onClick={onClick}
					className="vmznds-area-switcher__entry"
				>
					<Link
						className={modClasses('vmznds-area-switcher__link', {selected})}
						aria-current={selected}
						aria-describedby={selected ? 'a11y-desc-current' : undefined}
						to={{
							pathname: uri,
							state: {area: area.uri}
						}}
					>
						{area.name}
					</Link>
				</li>
			);
		});

		return (
			<nav className="vmznds-area-switcher" aria-labelledby="vmzareaswitcher">
				<span id="vmzareaswitcher" className="visuallyhidden">Karte und Datenquellen auf das jeweilige Gebiet einstellen</span>
				<ul className="vmznds-area-switcher__list">
					{areaButtons}
				</ul>
			</nav>
		);
	}
);
