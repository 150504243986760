import fetch from 'cross-fetch';

// TODO es gibt sicher irgendwo schon so ein anderes genUrlParams → darauf umstellen
import {genUrlParams} from '../helpers';

/**
 * @param {import("../../types").Location} location
 * @param {{abortSignal?: null | AbortSignal}} options
 * @returns {Promise<{displayName: string, tags: Array<import("../../types").LocationTag>} | null>}
 */
export async function fetchReverseGeocode({lon, lat}, options = {}) {
	const urlParams = {
		lang: 'de',
		lon: lon,
		lat: lat,
	};

	const url = `/photon/reverse?${genUrlParams(urlParams)}`;

	const res = await fetch(url, {signal: options.abortSignal});
	const body = await res.json();
	// filtered results are Locations outside the supported area of the website → just pretend we don't even know the name
	const feature = (!body.filtered && body.features || [])[0];

	if (!feature) {
		return null;
	}

	const {
		properties: {
			street: street,
			housenumber: houseNumber,
			city: city,
			postcode: postCode,
			name: name,
			area_tags: tags,
		} = {},
	} = feature;

	return {
		displayName: buildDisplayNameForSuggestion({name, street, houseNumber, postCode, city}),
		tags
	};
}


/**
 * @param {string} query
 * @param {{abortSignal?: null | AbortSignal}} options
 * @returns {Promise<Record<string, import("../../types").Suggestion>>}
 */
export async function fetchSuggestions(query, options = {}) {
	const url = `/photon-api/${encodeURIComponent(query)}`;

	const res = await fetch(url, {signal: options.abortSignal});
	const body = await res.json();

	/** @type {import('./types').GeoCodingFeature[]} features */
	const features = body.features || [];

	/** @type {Record<string, import("../../types").Suggestion>} */
	const suggestions = {};
	features.forEach(feature => {
		const {
			id: id,
			properties: {
				osm_id: osmId,
				street: street,
				housenumber: houseNumber,
				city: city,
				postcode: postCode,
				name: name,
				area_tags: tags,
				nunavEventId: nunavEventId,
				nunavEventRole: nunavEventRole,
				nunavDeepLink: nunavDeepLink,
			} = {},
			geometry: {
				coordinates: [lon, lat],
			} = {},
		} = feature;


		const nameLC = name && name.toLowerCase();
		const queryLC = query.toLowerCase();
		if(	!nunavEventId
			|| (name
				&& nameLC.includes(queryLC)
				&& (!nameLC.includes('acc columbia jet') || queryLC.includes('acc') || queryLC.includes('col') || queryLC.includes('jet'))
				&& (!nameLC.includes('radio hannover') || queryLC.includes('rad'))
				&& (!nameLC.includes('graphmasters') || queryLC.includes('gra'))
				&& (!nameLC.includes('nunav') || queryLC.includes('nun'))
				&& (!nameLC.includes('testzentrum') || queryLC.includes('tes') || queryLC.includes('cor'))
			)
	    ) {
			const idStr = `${id || osmId}`;
			suggestions[idStr] = {
				id: idStr,
				name: buildDisplayNameForSuggestion({name, street, houseNumber, postCode, city}),
				location: {
					lat: parseFloat(lat),
					lon: parseFloat(lon),
					tags,
					nunavEventId,
					nunavEventRole,
					nunavDeepLink,
				},
			};
		}
	});

	return suggestions;
}



/**
 * @param {import('./types').GeoCodingProperties} props
 * @returns {string}
 */
function buildDisplayNameForSuggestion({name, street, houseNumber, postCode, city}) {
	// TODO: Ca we be a bit smarten than this?
	//      - Handle places/locations other than house numbers better.
	//      - Add markup support (line breaks, size/bold)?
	return (
		`${name ? `${name}, ` : ''} ${street || ''} ${houseNumber || ''} ${postCode || ''} ${city || ''}`
			.trim()
			.replace(/\s+/, ' ')
			.replace(/,$/, '')
	);
}
