import PhotoSwipe from 'photoswipe/dist/photoswipe';
import PhotoSwipeUi from 'photoswipe/dist/photoswipe-ui-default';

const pswpElem = document.querySelector('.pswp');
if (pswpElem) {
	const elems = [...document.querySelectorAll('a.vmznds-fancybox')];
	const items = elems.map((elem) => ({
		src: elem.href,
		w: elem.dataset.width,
		h: elem.dataset.height,
		thumbnailElement: elem.querySelector('.image-embed-item'),
	}));
	elems.forEach((elem, index) => {
		elem.addEventListener(
			'click',
			(event) => {
				if (
					event.button === 0 &&
					!event.altKey &&
					!event.ctrlKey &&
					!event.metaKey &&
					!event.shiftKey
				) {
					event.preventDefault();
				}

				new PhotoSwipe(
					pswpElem,
					PhotoSwipeUi,
					items,
					{
						index,
						getThumbBoundsFn() {
							const {thumbnailElement} = items[index];
							const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
							const rect = thumbnailElement.getBoundingClientRect();
							return {
								x: rect.left,
								y: rect.top + pageYScroll,
								w: rect.width,
							};
						},
					},
				).init();
			}
		);
	});
}
