import fetchPonyfill from 'fetch-ponyfill';

const {fetch} = fetchPonyfill();

const noop = () => undefined;

export default function (request, onSuccess = noop, onError = noop) {
	fetch(request)
		.then(response => {
			if (response.status === 0 || response.ok) {
				response.json()
					.then(text => onSuccess(text))
					.catch(err => onError(err));
			} else {
				onError(request.status);
			}
		})
		.catch(err => onError(err));

	return {
		abort: function () {
			onSuccess = noop;
			onError = noop;
		},
	};
}
