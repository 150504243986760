import React, {forwardRef, memo} from 'react';

import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';

export const PanelBoxBox = memo(forwardRef(
	/**
	 * @param {{
	 *   k?: string,
	 *   height: "limit" | "expand" | "keep",
	 *   withBorder?: boolean,
	 *   tabIndex?: number
	 *   children: React.ReactNode
	 * }} props
	 * @param {React.ForwardedRef<HTMLDivElement>} ref
	 * @returns {null | React.ReactElement}
	 */

	function PanelBoxInner({
		k = '',
		height,
		withBorder = true,
		tabIndex,
		children,
	}, ref) {
		return (
			<div
				ref={ref}
				tabIndex={tabIndex}
				className={modClasses('vmznds-panel-box__box', {
					[`key-${k}`]: true,
					[`height-${height}`]: true,
					'with-border': withBorder,
				})}
			>
				{children}
			</div>
		);
	}
));
