import {createSelector, createStructuredSelector} from 'reselect';

import getPath from '@neonaut/lib-js/es/object/getPath';

import {getFilteredFeatures} from '@mapsight/core/lib/feature-selections/selectors';
import {findFeatureInFeatureSourcesById} from '@mapsight/core/lib/feature-sources/selectors';

import {isViewFullscreen, userPreferenceListVisibleSelector, viewSelector} from '@mapsight/ui/src/js/store/selectors';
import {VIEW_MOBILE} from '@mapsight/ui/src/js/config/constants/app';
import {FEATURE_SELECTION_SELECT} from '@mapsight/ui/src/js/config/feature/selections';
import {FEATURE_LIST, FEATURE_SELECTIONS, FEATURE_SOURCES} from '@mapsight/ui/src/js/config/constants/controllers';

import {
	CONTENT_PAGES,
	FEATURE_LIST_TWO,
	SPECIAL_PAGE_CAMERAS,
	SPECIAL_PAGE_CONTENT,
	SPECIAL_PAGE_HOME,
	SPECIAL_PAGE_NAVIGATION,
	SPECIAL_PAGE_SITEMAP,
	SPECIAL_PAGE_URGENT_BLUE,
	SPECIAL_PAGE_URGENT_GREEN,
	SPECIAL_PAGE_URGENT_RED,
	SPECIAL_PAGE_URGENT_SCHOOL,
	VMZPAGES
} from './controller';


export const isViewMobileSelector = createSelector(viewSelector, _ => _ === VIEW_MOBILE);
export const isDesktopSelector = createSelector(viewSelector, isViewFullscreen);
export const isFullscreenSelector = createSelector(
	[isDesktopSelector, userPreferenceListVisibleSelector],
	(desktop, uiPref) => desktop && !uiPref
);

export const listVisibleSelector = createSelector(
	state => state[VMZPAGES][FEATURE_LIST],
	/**
	 * @param {object|null} _
	 * @returns {boolean}
	 */
	_ => !_ || _.visible !== false // count undefined et.al. as true
);

export const listSortControlSelector = createSelector(
	state => state[VMZPAGES][FEATURE_LIST],
	/**
	 * @param {object|null} _
	 * @returns {boolean}
	 */
	_ => _ && _.sortControl
);

export const listFilterControlSelector = createSelector(
	state => state[VMZPAGES][FEATURE_LIST],
	/**
	 * @param {object|null} _
	 * @returns {boolean}
	 */
	_ => _ && _.filterControl
);

export const listTagSwitcherControlSelector = createSelector(
	state => state[VMZPAGES].tagSwitcher,
	/**
	 * @param {object|null} _
	 * @returns {boolean}
	 */
	_ => _ && _.show
);

// FIXMW das visible aus der Liste wird beim Laden der source auf true gesetzt -> das muss nach [VMZPAGES]
// dann auch gleich den Titel mit verschieben
export const listTwoVisibleSelector = createSelector(
	state => state[VMZPAGES][FEATURE_LIST_TWO],
	/**
	 * @param {object|null} _
	 * @returns {boolean}
	 */
	_ => !_ || _.visible !== false // count undefined et.al. as true
);

export const listTwoTitleSelector = createSelector(
	state => state[VMZPAGES][FEATURE_LIST_TWO],
	/**
	 * @param {object|null} listTwo
	 * @returns {string | null}
	 */
	(listTwo) => (listTwo && listTwo.title) || null
);

export const navOpenSelector = createSelector(
	state => state[VMZPAGES].navigation,
	/**
	 * @param {object} _
	 * @returns {boolean}
	 */
	_ => !!_.open
);

/**
 * @param {any} state
 * @returns {null | string}
 */
export function listTitleSelector(state) {
	const res =
		state
		&& state[VMZPAGES]
		&& state[VMZPAGES].listTitle;
	if (typeof res === 'string') {
		return res;
	} else {
		return null;
	}
}

export const specialPageSelector = createSelector(
	state => state[VMZPAGES],
	/**
	 * @param {object|null} _
	 * @returns {string}
	 */
	_ => _ && _.specialPage
);

export const isPageCameras = specialPage => (specialPage === SPECIAL_PAGE_CAMERAS);
export const isPageContent = specialPage => (specialPage === SPECIAL_PAGE_CONTENT || CONTENT_PAGES.includes(specialPage));
export const isPageHome = specialPage => (specialPage === SPECIAL_PAGE_HOME);
export const isPagePrerouting = specialPage => (specialPage === SPECIAL_PAGE_NAVIGATION);
export const isPageSchool = specialPage => (specialPage === SPECIAL_PAGE_URGENT_SCHOOL);
export const isPageSitemap = specialPage => (specialPage === SPECIAL_PAGE_SITEMAP);


export const preroutingEnabledSelector = createSelector(specialPageSelector, isPagePrerouting);

export const pageCamerasSelector = createSelector(specialPageSelector, isPageCameras);
export const pageContentSelector = createSelector(specialPageSelector, isPageContent);
export const pageHomeSelector = createSelector(specialPageSelector, isPageHome);
export const pageSchoolSelector = createSelector(specialPageSelector, isPageSchool);

export const camerasOverviewSelector = createSelector(
	state => state[VMZPAGES] && state[VMZPAGES].cameras,
	cams =>
		// count undefined et.al. as true
		!cams || cams.overview !== false
);

// die feature-sources befragen
export const createUrgentSelector = (magic, urgentType) => {
	const urgentFeatureSources = (magic.topics || [])
		.filter((topic) => topic.type === urgentType).map(
			(urgent) => (
				urgent.noArea ? urgent.uri :
					(urgent.maxArea ?
						`${urgent.maxArea}-${urgent.uri}` :
						`bundesweit--${urgent.uri}`)
			)
		);
	return createSelector(
		state => state[FEATURE_SOURCES],
		_ => urgentFeatureSources.reduce(
			(features, source) => ([...features, ...getPath(_, [source, 'data', 'features'], [])]),
			[]
		)
	);
};
export const createUrgentBlueSelector = magic => createUrgentSelector(magic, SPECIAL_PAGE_URGENT_BLUE);
export const createUrgentGreenSelector = magic => createUrgentSelector(magic, SPECIAL_PAGE_URGENT_GREEN);
export const createUrgentRedSelector = magic => createUrgentSelector(magic, SPECIAL_PAGE_URGENT_RED);
export const createUrgentSchoolSelector = magic => createUrgentSelector(magic, SPECIAL_PAGE_URGENT_SCHOOL);

export function createPanelOptionSelector(k) {
	return createSelector(
		state => state[VMZPAGES] && state[VMZPAGES].panelOptions,
		/**
		 * @param {object|null} _
		 * @returns {boolean}
		 */
		_ => _ && _[k]
	);
}

export const detailsFeatureSelector = createSelector(
	createStructuredSelector({
		featureSelection: state => state[FEATURE_SELECTIONS][FEATURE_SELECTION_SELECT],
		featureSources: state => state[FEATURE_SOURCES],
	}),
	/**
	 * @returns {null | Record<string, any>}
	 */
	({featureSelection, featureSources}) => {
		const features = getFilteredFeatures(featureSelection);
		const featureId = features && features[0];
		if (featureId) {
			return findFeatureInFeatureSourcesById(featureSources, featureId) || null;
		}
		return null;
	},
);

export const detailsSelector = createSelector(
	detailsFeatureSelector,
	(feature) => feature !== null,
);

/**
 * @param {*} sources
 * @param {string} featureId
 * @returns {null | string}
 */
export function findFeatureSourceIdForFeatureId(sources, featureId) {
	const res = Object.entries(sources)
		.find(([_, src]) => src.ids && src.ids.includes(featureId));
	return res !== undefined ? res[0] : null;
}

export const featureSelectionSelector = state => state[FEATURE_SELECTIONS][FEATURE_SELECTION_SELECT];

export const featureSourceInfoSelector = createSelector(
	createStructuredSelector({
		featureSources: state => state[FEATURE_SOURCES],
		featureSelection: featureSelectionSelector,
		listSourceId: state => state[FEATURE_LIST].featureSource,
	}),
	({featureSources, featureSelection, listSourceId}) => {
		const features = getFilteredFeatures(featureSelection);
		const featureId = features && features[0];

		// prefer list feature source
		const source = featureSources[listSourceId] && findFeatureSourceIdForFeatureId({[listSourceId]: featureSources[listSourceId]}, featureId) ?
			listSourceId :
			findFeatureSourceIdForFeatureId(featureSources, featureId);

		let totalCount = null;
		let name = null;
		if (source !== null) {
			totalCount = getPath(featureSources, [source, 'data', 'features', 'length'], null);
			name = getPath(featureSources, [source, 'name'], null);
		}

		return {
			source,
			listSource: listSourceId,
			featureId,
			totalCount,
			name,
			foreign: listSourceId !== source,
		};
	}
);

export const preselectedFeatureInListOneSelector = createSelector(
	createStructuredSelector({
		featureSources: state => state[FEATURE_SOURCES],
		featureSelection: state => state[FEATURE_SELECTIONS][FEATURE_SELECTION_SELECT],
		listSourceId: state => state[FEATURE_LIST].featureSource,
	}), ({featureSources, featureSelection, listSourceId}) => {
		const features = getFilteredFeatures(featureSelection);
		const featureId = features && features[0];

		// prefer list feature source
		// const source = featureSources[listSourceId] && findFeatureSourceIdForFeatureId({[listSourceId]: featureSources[listSourceId]}, featureId) ?
		// 	listSourceId :
		// 	findFeatureSourceIdForFeatureId(featureSources, featureId);
		const source = featureSources[listSourceId] && findFeatureSourceIdForFeatureId({[listSourceId]: featureSources[listSourceId]}, featureId);
		return source && featureId;
	}
);
