import {set, throwOnEmptyPath, withPath} from '@mapsight/core/lib/base/actions';
import {batchActions} from 'redux-batched-actions';

import {FEATURE_LIST, FEATURE_SOURCES} from '@mapsight/ui/src/js/config/constants/controllers';
import {setTagFilterControl as uiSetTagFilterControl} from '@mapsight/ui/src/js/store/actions';

import {FEATURE_LIST_TWO, VMZPAGES} from './controller';

export {setListIsVisible as setListPanelIsVisible} from '@mapsight/ui/src/js/store/actions';

export function setTagSwitcherControl(show, featureSourcesControllerName, featureSourceId, tagSwitcherOptions) {
	return batchActions([
		set([VMZPAGES, 'tagSwitcher', 'show'], show),
		uiSetTagFilterControl(false, featureSourcesControllerName, featureSourceId, tagSwitcherOptions),
	]);
}

export function setListSortControl(visible) {
	return set([VMZPAGES, FEATURE_LIST, 'sortControl'], visible);
}

export function setListFilterControl(visible) {
	return set([VMZPAGES, FEATURE_LIST, 'filterControl'], visible);
}

export function replaceFeatureSources(featureSources) {
	// TODO needs repair
	//  a) ein set() verwirft bereits geladene Daten, was aber von OL ignoriert wird. dh. man sieht sie die POI weiterhin auf der Karte kann sie aber nicht auswählen
	//  b) merge erhält wahrscheinlich die Daten. allerdings bleiben dann zu entfernende feature-sources erhalten.
	//  In beiden Fällen fehlt die Aktualisierung von OL, die Aktualisierung der Karte
	// diese Action braucht einen eigenständigen reducer, der die feature sources einzeln durchgeht und bei gleichbleibender Definition die Daten beibebehält. Und der dann OL Bescheid gibt.
	//
	// Wir nutzen einen Workaround: replaceFeatureSources wird nur beim Preview von Textseiten aufgerufen, wo es eh keine Karte gibt. Wenn der User vom Preview über das Menü auf eine andere Seite wechselt, ändern wir gleichzeitig den HTTP-Host und laden alles neu.
	// Dadurch können wir so weitermachen, ohne die Action richtig umzusetzen.
	return set([FEATURE_SOURCES], featureSources);
}

export function replaceMapLayers(mapLayers) {
	return set(['map', 'layers'], mapLayers);
}

/**
 * set visibility of list one
 *
 * @param {boolean} listVisible
 */
export function setListVisible(listVisible) {
	return set([VMZPAGES, FEATURE_LIST, 'visible'], listVisible);
}

// TODO move to @mapsight/ui
export function setListFeatureSource(featureSource) {
	return set([FEATURE_LIST, 'featureSource'], featureSource);
}

export function setListTwoVisible(listTwoVisible) {
	return set([VMZPAGES, FEATURE_LIST_TWO, 'visible'], listTwoVisible);
}

export function setListTwoFeatureSource(featureSource) {
	return set([FEATURE_LIST_TWO, 'featureSource'], featureSource);
}

export function setNavigationOpen(navOpen) {
	return set([VMZPAGES, 'navigation', 'open'], navOpen);
}

/**
 * @param {null | string} title
 */
export function setListTitle(title) {
	return set([VMZPAGES, 'listTitle'], title);
}

/**
 * @param {null | string} pageType
 */
// TODO write action and add a reducer to controller to ensure only allowed constants SPECIAL_PAGE_XXX get set
export function setSpecialPage(pageType) {
	return set([VMZPAGES, 'specialPage'], pageType);
}

export function setCamerasOverview(showOverview) {
	return set([VMZPAGES, 'cameras', 'overview'], showOverview);
}

/**
 * set state of PanelOption k
 *
 * @param {string} k k of PanelOption to set
 * @param {boolean} value true if PanelOption is open
 */
export function setPanelOption(k, value) {
	return set([VMZPAGES, 'panelOptions', k], value);
}


// for internal use by route-to-store
export const ACTION_SET_FROM_ROUTE = 'MAPSIGHT_SET_FROM_ROUTE';

export function setFromRoute(path, value) {
	throwOnEmptyPath(path);

	return withPath({
		type: ACTION_SET_FROM_ROUTE,
		value: value,
	}, path);
}

export function selectExclusivelyFromRoute(controllerName, selectionId, featureId) {
	return setFromRoute(
		[controllerName, selectionId, 'features'],
		Array.isArray(featureId) ? featureId : [featureId]
	);
}

export function deselectAllFromRoute(controllerName, selectionId) {
	return selectExclusivelyFromRoute(controllerName, selectionId, []);
}

// This is only to hide action from mapsight automatically switching the view TODO: Fix it there.
export const ACTION_SET_VIA_HISTORY = 'SET_VIA_HISTORY';

export function selectExclusivelyViaHistory(controllerName, selectionId, featureId) {
	return setFromRoute(
		[controllerName, selectionId, 'features'],
		Array.isArray(featureId) ? featureId : [featureId]
	);
}

export function deselectAllViaHistory(controllerName, selectionId) {
	return selectExclusivelyViaHistory(controllerName, selectionId, []);
}
