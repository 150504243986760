import React, {Fragment, memo, useRef} from 'react';
import {useSelector} from 'react-redux';

import {isMapOutOfViewportSelector} from '@mapsight/ui/src/js/store/selectors';

import {isViewMobileSelector, pageCamerasSelector, pageContentSelector} from '../store/selectors';
import {useNavPos} from '../hooks';
import {withErrorBoundary} from '../../modules/error-boundary';
import {memoForever} from '../helpers';

import AreaSwitcher from './area-switcher';
import Menu from './menu';
import Logo from './logo';
import MenuButton from './menu-button';
import Content from './content';
import SideMenu from './side-menu';
import SkipLink from './skip-link';



function scrollToTop() {
	if (window) {
		window.scrollTo({top: 0, behavior: 'smooth'});
	}
}

const MapOutOfViewButton = memo(() => {
	const isMapOutOfViewport = useSelector(isMapOutOfViewportSelector); // FIXME funktioniert nicht, weil immer false im store.
																		// entweder in ms/ui fixen, indem ein Element tierfer im DOM überwacht wird
																		// oder hier einfach per gucken, ob window.scrollTop größer ist als das Window
	return isMapOutOfViewport && (
		<button className="vmznds-button-scroll-to-map" onClick={scrollToTop} aria-label="Zur Karte wechseln"
				type="button">
			<span className="ms3-viewport-button__label">Karte</span>
		</button>
	);
});

/** @type {import("@mapsight/ui/src/js/components/contexts").AppWrapperStart} */
const AppWrapperStart = withErrorBoundary(memo(function AppWrapperStart({}) {
		const isMobile = useSelector(isViewMobileSelector);
		const pageContent = useSelector(pageContentSelector);
		const isPageCamera = useSelector(pageCamerasSelector);
		const {error404} = useNavPos();

		const menuButtonRef = useRef();

		if (!isMobile) {
			return null;
		}

		return (
			<Fragment>
				<SkipLink />
				<Logo />
				<MenuButton ref={menuButtonRef} />
				<Menu buttonRef={menuButtonRef} />
				{!pageContent && <AreaSwitcher />}
				{(pageContent || isPageCamera) && <Content forMobile={true} />}
				{(pageContent && !error404) && <SideMenu />}
				<MapOutOfViewButton />
			</Fragment>
		);
	},
	memoForever
));
export default AppWrapperStart;
