import {BaseController} from '@mapsight/core/lib/base/controller';

/**
 * this constant is used twice:
 * a) for the store-area of the mapsight-core feature list controller of list two
 * b) for the sub-area regarding list two of the vmz pages controller
 */
export const FEATURE_LIST_TWO = 'listTwo';
export const VMZPAGES = 'vmzPages';

/** start page **/
export const SPECIAL_PAGE_HOME = 'home';
/** content page derived from magic.additionalContent, used internally only **/
export const SPECIAL_PAGE_CONTENT = 'content';
// identisch mit den type-Einträge im magic js-blob:
/** topic to be displayed as content aggregating all features in one page **/
export const SPECIAL_PAGE_CONTENTSUM = 'contentsum';
/** topic to be displayed as content with a list on the left to select feature **/
export const SPECIAL_PAGE_CONTENTLIST = 'contentlist';
/** webcameras' page **/
export const SPECIAL_PAGE_CAMERAS = 'cameras';
/** graphmaster prerouting, other services may be added later **/
export const SPECIAL_PAGE_NAVIGATION = 'prerouting';
/** page with school dropouts **/
export const SPECIAL_PAGE_URGENT_SCHOOL = 'school';
/** page with sitemap **/
export const SPECIAL_PAGE_SITEMAP = 'sitemap';
export const CONTENT_SITEMAP_URL = 'site-map'; // "sitemap" wird zumindest auf staging falsch geroutet und kommt nicht an
/** urgent messages (red alert) **/
export const SPECIAL_PAGE_URGENT_RED = 'urgent';
/** not so urgent messages, related to enverionmental protection (green alert) **/
export const SPECIAL_PAGE_URGENT_GREEN = 'info_green';
/** not so urgent messages, information (vmz-blue alert) **/
export const SPECIAL_PAGE_URGENT_BLUE = 'info_blue';

/**
 * the sources of this pages get loaded automatically from beginning for their lagest area.
 * that sources are watched by the start page's boxes
 *
 * @type {string[]}
 */
export const URGENT_PAGES = [SPECIAL_PAGE_URGENT_SCHOOL, SPECIAL_PAGE_URGENT_RED, SPECIAL_PAGE_URGENT_GREEN, SPECIAL_PAGE_URGENT_BLUE];
export const CONTENT_PAGES = [SPECIAL_PAGE_CONTENT, SPECIAL_PAGE_URGENT_RED, SPECIAL_PAGE_URGENT_GREEN, SPECIAL_PAGE_URGENT_BLUE, SPECIAL_PAGE_CONTENTLIST, SPECIAL_PAGE_CONTENTSUM, SPECIAL_PAGE_SITEMAP];

// re-export to enable future changes
export {ListController as ListTwoController} from '@mapsight/core/lib/list/controller';


/** default state of store[VMZPAGES]. This serves as documentation of the layout too  */
export const defaultVMZPagesState = {
	navigation: {open: false},
	[FEATURE_LIST_TWO]: {
		visible: true,
		title: 'Baustellen'
	}
};

export class VMZPagesController extends BaseController {
}
