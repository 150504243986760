import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {translate} from '@mapsight/ui/src/js/helpers/i18n';

import {memoForever} from '../../../mapsight-vmznds/helpers';

import {formatTripDuration} from '../store/api/helpers';
import {modalitySelector, tripParameterSelector, tripsSelector, tripValid} from '../store/selectors';
import {ROUTE_FETCH_MATRIX} from '../store/constants';

import {ModalityEntry} from './modality';


const COPYRIGHTS = {
	car: 'Route: © Graphmasters',
	bike: 'Route: © Bike Citizens',
	openRouteService: 'Route: © openrouteservice',
	public: 'Route: © GVH / EFA.de'
};

function getCopyright(modaltiy, trip) {
	return (modaltiy === 'bike') && COPYRIGHTS[trip.service] || COPYRIGHTS[modaltiy];
}

function otherHeuristic(trip, otherTrip, otherModality) {
	// // pädagogischer Auftrag (auskommentiert)
	// if(otherModality === 'car') {
	// 	return otherTrip.durationSecs > trip.durationSecs + 60;
	// }
	if (otherModality === 'bike') {
		return otherTrip.durationSecs < trip.durationSecs + 30 * 60;
	}
	return otherTrip.durationSecs < trip.durationSecs +
		Math.max(
			20 * 60,
			// Faktor mit dem es per Öffi länger dauern kann, max. 60 Minuten mal Konstante länger
			Math.min(trip.durationSecs, 3600) * 0.75
		);
}

export default memo(
	function NavigationNote() {
		const tripParameters = useSelector(tripParameterSelector);
		const modality = useSelector(modalitySelector);
		const trips = useSelector(tripsSelector);
		/**@type{import('./types').TripState}*/const trip = trips[modality];

		const content = [];
		if (!tripValid(tripParameters, trips, modality)) {
			content.push(<p key="route-t">
				Unser Routenplaner erlaubt Ihnen eine Routenempfehlung einzuholen.
				Geben Sie dazu über Eingabemaske oder Karte Start- und Zielpunkt ein und legen Sie die Startzeit fest.
			</p>);
		} else {
			content.push(
				<p key="route-c">{getCopyright(modality, trip)}</p>
			);

			const other = [];
			Object.keys(ROUTE_FETCH_MATRIX).forEach(m => {
				if (
					m !== modality && ROUTE_FETCH_MATRIX[modality][m]
					&& tripValid(tripParameters, trips, m)
					&& trips[m].durationSecs && otherHeuristic(trip, trips[m], m)
				) {
					other.push(
						<li key={`note-${m}`}>
							<ModalityEntry modality={m} className="vmznds-list__more-link">
								{translate(`navigation.modality.per.${m}`)}<br />
								{formatTripDuration(trips[m].durationSecs)}
							</ModalityEntry>
						</li>
					);
				}
			});
			if (other.length) {
				content.push(<ul key="note-ul" className="ms3-pre-routing-note__other"
								 aria-label="Fahrzeit mit anderen Verkehsmitteln">{other}</ul>);
			}
		}

		return (<div className="vmznds-panel-note ms3-pre-routing-note">
			{content}
		</div>);
	},
	memoForever
);
