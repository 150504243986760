import React from 'react';
import ReactDOM from 'react-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import getQueryStringParameter from '@neonaut/lib-js/string/get-query-string-parameter';

import loadJson from './helpers/xhr-json';
import config from './config';
import {mapFeaturesToStations} from './helpers';
import ChartWrapper from './components/ChartWrapper';

const queryClient = new QueryClient();

const now = new Date();
const daysInLastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate();
const currentDate = now.getDate();

const charts = [
	{
		selector: '#radzaehler-vergleich-000',
		resolution: 'hourly',
		limit: 48,
		type: 'area',
	},
	{
		selector: '#radzaehler-vergleich-001',
		resolution: 'daily',
		limit: 7,
		type: 'column',
	},
	{
		selector: '#radzaehler-vergleich-002',
		resolution: 'daily',
		// limit is set to the number of days in the running month plus the number of days in the previous month
		limit: currentDate + daysInLastMonth - 1,
		type: 'area',
	},
	{
		selector: '#radzaehler-vergleich-003',
		resolution: 'monthly',
		limit: now.getMonth(),
		type: 'area',
	},
	{
		selector: '#radzaehler-vergleich-004',
		resolution: 'yearly',
		limit: Math.min(10, now.getFullYear() - config.EARLIEST_YEAR),
		type: 'area',
	},
];

export function render() {
	let stations = [];
	let showStationsList = getQueryStringParameter(window.location.search, 'stationId');
	showStationsList = showStationsList ? showStationsList.split(',') : false;

	function renderChart(chartConfig) {
		ReactDOM.render(
			<QueryClientProvider client={queryClient}>
				<ChartWrapper
					type={chartConfig.type || 'column'}
					showStationsList={showStationsList}
					stations={stations}
					resolution={chartConfig.resolution}
					limit={chartConfig.limit}
				/>
			</QueryClientProvider>,
			chartConfig.containerElement
		);
	}

	const visibleCharts = [];
	charts.forEach(chartConfig => {
		const containerElement = document.querySelector(chartConfig.selector);
		if (!containerElement) {
			return;
		}

		chartConfig.containerElement = containerElement;
		renderChart(chartConfig);

		visibleCharts.push(chartConfig);
	});

	if (!visibleCharts.length) {
		return;
	}

	loadJson(config.LIST_URL_OVERVIEW, ({features}) => {
		stations = mapFeaturesToStations(features);
		visibleCharts.forEach(chartConfig => {
			renderChart(chartConfig);
		});
	});
}
