import isEmpty from 'lodash/isEmpty';

import {formatTripLength} from '../helpers';
import {modeToString} from '../../../../public-transport/components/type';

/**
 * @type {Array<import("../../types").TurnInfo["turnCommand"]>}
 */
const ADDITIONAL_MERGEABLE = [
	'None',
	'Unknown',
	'RampEntry',
	'RampExit',
];

function addOptionals(a, b) {
	if (b === null) {
		return a;
	}

	if (a === null) {
		return b;
	}

	return a + b;
}

/**
 * @param {Array<import("../../types").TripStep>} acc
 * @param {import("../../types").TripStep} step
 * @returns {Array<import("../../types").TripStep>}
 */
function mergeStepsWithSameStreetName(acc, step) {
	const previousStep = acc.length > 0 ? acc[acc.length - 1] : null;

	// normalisieren
	if (!isEmpty(step.referenceName)) {
		step.referenceName = step.referenceName.trim().replace(' ', ' '); // U+202F narrow no-break space
	}
	if (step.turnCommand && step.turnCommand.startsWith('RoundaboutExit')) {
		step.roundaboutExit = step.turnCommand.substr(14);
		step.turnCommand = 'RoundaboutExit';
	}
	// FIX für GM
	if (
		previousStep && previousStep.turnCommand && previousStep.turnCommand.startsWith('Roundabout')
		&& step.turnCommand === 'None'
	) {
		step.turnCommand = 'Straight';
	}
	if (
		step.turnCommand && step.turnCommand.startsWith('Roundabout')
		&& previousStep.turnCommand === 'None'
	) {
		previousStep.turnCommand = 'Straight';
	}

	const isSameStreet = previousStep && (
		(
			step.streetName === previousStep.streetName
			&& step.referenceName === previousStep.referenceName
		)
		|| (
			step.keep !== undefined && step.keep
			// wenn ein Straßenname gesetzt ist, der sich wegen dem ersten Teil vom vorhergehenen unterscheidet,
			// fassen wir nicht zusammen sondern zeigen den neuen Straßennamen an obowhl es laut Service dieselbe Straße ist
			&& isEmpty(step.streetName)
		)
	);
	const canMerge =
		(isSameStreet || (
			// RoundaboutExit wird von GM 2x je Kreisverkehr gesetzt
			previousStep?.turnCommand === 'RoundaboutExit' && step.turnCommand === 'RoundaboutExit' && step.lengthM < 45
			// wenn roundaboutExit mit einem konkreten Wert überschrieben werden soll, dann ist das wohl doch ein weiterer Kreisverkehr
			// → positiv formuliert: im vorherigen oder im aktuellen step muss das Feld leer sein
			&& (isEmpty(previousStep.roundaboutExit) || isEmpty(step.roundaboutExit))
		)) && (
			step.turnCommand === previousStep.turnCommand
			|| !step.turnCommand || ADDITIONAL_MERGEABLE.includes(step.turnCommand)
			|| !previousStep.turnCommand || ADDITIONAL_MERGEABLE.includes(previousStep.turnCommand)
			// RoundaboutStraight wird von GM auch beim Hineinfahren in den Kreisverkehr gesetzt → ausfiltern
			|| previousStep.turnCommand === 'RoundaboutStraight' && step.turnCommand === 'RoundaboutExit'
		);

	const skip = !canMerge
		// RoundaboutStraight wird von GM auch beim Hinausfahren in den Kreisverkehr gesetzt → ausfiltern
		&& (!step.turnCommand || step.turnCommand === 'None' || step.turnCommand === 'Unknown' || step.turnCommand === 'RoundaboutStraight')
		&& (step.lengthM < 100)
		&& previousStep;

	// console.log(`navigation mergeStepsWithSameStreetName ${step.streetName} (${step.referenceName}) ${step.turnCommand}`, {
	// 	skip, canMerge, isSameStreet, previousStep, step, acc
	// });

	if (skip) {
		previousStep.locations.push(...step.locations);
		return acc;
	}

	if (canMerge) {
		previousStep.turnCommand = step.turnCommand && step.turnCommand !== 'None' && step.turnCommand !== 'Unknown' ?
			step.turnCommand : previousStep.turnCommand;
		previousStep.locations.push(...step.locations);
		previousStep.lengthM = addOptionals(
			previousStep.lengthM,
			step.lengthM,
		);
		// auch speziell für GM: der Name des Kreisels steht im letztn RoundAboutExit, die Nummer des Exit im ersten (dort mit falschem Namen)
		previousStep.streetName = step.streetName;
		previousStep.referenceName = step.referenceName;
		previousStep.roundaboutExit = !isEmpty(previousStep.roundaboutExit) ? previousStep.roundaboutExit : step.roundaboutExit;
	} else {
		acc.push({
			...step,
			// copy locations array
			locations: [...step.locations],
			enteringTurnCommand: previousStep && previousStep.turnCommand || 'None',
			keep: step.keep || isSameStreet
		});
	}
	return acc;
}

function getLineStringBetweenSteps(lastStepLoc, step) {
	return [
		...(lastStepLoc ? [lastStepLoc] : []),
		...step.locations,
	]
		.map(({lat, lon}) => [lon, lat]);
}

/**
 * @param {import('./types').TurnInfo["turnCommand"]} turnCommand
 * @returns {string}
 */
function mapTurnCommandToStepIconId(turnCommand) {
	switch (turnCommand) {
		case 'RampLeft':
		case 'Left':
			return 'arrow-turn-left';

		case 'RampRight':
		case 'Right':
			return 'arrow-turn-right';

		case 'MergeToLeft':
		case 'SlightLeft':
			return 'arrow-turn-slight-left';

		case 'MergeToRight':
		case 'SlightRight':
			return 'arrow-turn-slight-right';

		case 'SharpRight':
			return 'arrow-turn-sharp-right';

		case 'SharpLeft':
			return 'arrow-turn-sharp-left';

		case 'RoundaboutExit':
			return 'arrow-roundabout';

		case 'RoundaboutStraight':
			return 'arrow-roundabout-straight';

		case 'Uturn':
			return 'arrow-uturn';

		case 'RampStraight':
		case 'Straight':
		case 'Through':
			return 'arrow-straight';

		case 'Interchange':
		case 'Walk':
			return 'transit-walk';

		case 'Ferry':
			return 'transit-ferry';

		default:
			return 'unknown';
	}
}

/**
 * @param {import('./types').Step} step
 * @returns {string}
 */
function mapTurnCommandToStepIcon(step) {
	let mapsightIconId = mapTurnCommandToStepIconId(step.turnCommand);
	let inner = `<img src="/assets/img/mapsight-icons-svg/${mapsightIconId}-default.svg" alt=""/>`;

	if (step.triasMode || step.triasSubmode) {
		let number = step.number;
		if (number && !/[A-Za-z]/.test(number)) {
			// eigentlich müsste unsere API noch die OperatorRef als triasOperatorRef mitliefern, aber
			// erstmal wird hier gehofft, dass HKX, FlixTrain u.a. falls sie überhaupt in der TRIAS-Datenbank sind dann auch ihr Kürzel direkt in die Namen schreiben
			// und die DB die einzige ist, die nur die blanke Nummer erfasst
			switch (step.triasSubmode) {
				case 'interregionalRail':
					number = 'IC ' + number;
					break;

				case 'highSpeedRail':
					number = 'ICE ' + number;
					break;

				default:
			}
		}

		const desc = modeToString(step.type, step.triasModeName);
		const descHTML = desc ? `<span class="visuallyhidden">${desc}</span>` : '';
		inner = `<span class="vmznds-feature-details-pundr__stop-symbol">
			${descHTML}
			<span class="vmznds-pt-type vmznds-pt-type--${step.type}" aria-hidden="true"></span>` +
			`<span class="vmznds-pt-line vmznds-pt-line--mode-${step.type} vmznds-pt-line--line-${step.type}-${step.number}">${number}</span>
		</span>`;
		mapsightIconId = 'trias';
	}

	return `<span class="ms3-list__icon ms3-list__icon--id-${mapsightIconId} vmznds-feature-details--prerouting__icon">${inner}</span>`;
}

function mapStepToInstruction(step) {
	if (step.turnCommand === 'RoundaboutExit' && step.roundaboutExit) {
		return `<p><span class="visuallyhidden">Kreisverkehr </span>verlassen auf ${step.roundaboutExit}. Ausfahrt</p>`;
	}
	if (step.turnCommand === 'KeepLeft') {
		return '<p>Links halten</p>';
	}
	if (step.turnCommand === 'KeepRight') {
		return '<p>Rechts halten</p>';
	}

	if (step.turnType === 'hold') {
		return '<p>Straße folgen</p>';
	}
	if (step.turnType === 'cross') {
		return '<p>Straße überqueren</p>';
	}


	if (step.instruction &&
		(step.turnCommand === 'Unknown'
			// || (!step.steetName && !step.referenceName)
		)
	) {
		return `<p>${step.instruction}</p>`;
	}

	return '';
}

/**
 * @param {import("../../types").TripStep} step
 * @param {number} index
 * @param {Array<import("../../types").TripStep>} steps
 * @returns {object} geojson feature
 */
function mapStepToFeature(step, index, steps) {
	const previousStepLoc = (() => {
		if (index === 0) {
			return null;
		}

		const previousStep = steps[index - 1];
		if (previousStep === undefined) {
			return null;
		}

		const previousLoc = previousStep.locations[previousStep.locations.length - 1];
		if (previousLoc === undefined) {
			return null;
		}
		return previousLoc;
	})();

	const name = (() => {
		if (step.streetName) {
			if (step.referenceName) {
				return `${step.streetName} (${step.referenceName})`;
			} else {
				return step.streetName;
			}
		} else {
			return step.referenceName;
		}
	})();


	const mapsightIcon = mapTurnCommandToStepIcon(step);
	const length = !step.lengthM ? '' : `<p>${formatTripLength(step.lengthM)}</p>`;
	const triasLine = step.lineDestination && `Richtung ${step.lineDestination}` || '';
	const displayName = triasLine || name ||
		(index === steps.length - 1 ? 'Ziel erreicht' : 'Unbekannte Straße');
	const instruction = isEmpty(triasLine) ?
		mapStepToInstruction(step) :
		name && `<p>Aussteigen in <b><br />${name}</b></p>` || '';

	return {
		id: `vmznds-pre-routing-trip-part-${index}`,
		type: 'Feature',
		properties: {
			name: displayName,
			type: 'route',
			// // //length: step.lengthM,
			// // //duration: step.durationSecs,
			// // no description as navigation command details are never shown
			// description: `
			// 	<h3>${displayName}</h3>
			// 	<p>
			// 		Länge: ${formatTripLength(step.lengthM)}
			// 	</p>`,
			// TODO vmznds-feature-details--prerouting__icon nach links
			// no mapsightIconId as individual steps don't have an icon on map
			// mapsightIconId: mapsightIconId,
			overrideListHtml: `
                    ${mapsightIcon}
					<span class="ms3-list__main ${step.triasMode || step.triasSubmode ? 'ms3-list__main--trias' : ''} vmznds-feature-details--prerouting__main">
					    <h1>${displayName.replace(', ', ',<br>')}</h1>
						${length}
					</span>
					${instruction}`,
		},
		geometry: {
			type: 'LineString',
			coordinates: getLineStringBetweenSteps(previousStepLoc, step),
		},
	};
}

/**
 * @param {import("../../types").Trip} trip
 */
export function getGeoJSONFromTrip(trip) {
	const features = trip.steps
		.reduce(mergeStepsWithSameStreetName, [])
		.map(mapStepToFeature);

	return {
		type: 'FeatureCollection',
		features: features,
	};
}
