import React, {useState} from 'react';

import {RefsContext} from '../contexts';


export const RefsContextProvider = ({children}) => {
	const [pageRef, setPageContentRef] = useState();
	const [mobilePageRef, setMobilePageContentRef] = useState();
	const [msRef, setMapsightContentRef] = useState();
	const refsValue = {
		pageContentRef: {current: pageRef},
		mapsightContentRef: {current: msRef},
		mobilePageContentRef: {current: mobilePageRef},
		setPageContentRef,
		setMobilePageContentRef,
		setMapsightContentRef
	};
	// console.log('RefsContextProvider for skip-links rerender', {refsValue});
	return (
		<RefsContext.Provider value={refsValue}>
			{children}
		</RefsContext.Provider>
	);
};
