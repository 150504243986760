import React, {Fragment, memo, useContext, useLayoutEffect, useRef} from 'react';
import {useSelector} from 'react-redux';

import {viewSelector} from '@mapsight/ui/src/js/store/selectors';
import {VIEW_FULLSCREEN, VIEW_MAP_ONLY} from '@mapsight/ui/src/js/config/constants/app';

import {withErrorBoundary} from '../../modules/error-boundary';
import {useMagic, useNavPos, useOnCloseDetails} from '../hooks';
import {RefsContext} from '../contexts';
import {findAreaAndTopicFromSourceId} from '../routing/helpers';
import {
	detailsFeatureSelector,
	featureSourceInfoSelector,
	isPageContent,
	listTitleSelector,
	specialPageSelector
} from '../store/selectors';

import Menu from './menu';
import MenuButton from './menu-button';
import Logo from './logo';
import SideMenu from './side-menu';
import Boxes from './boxes';
import PanelNote from './boxes/panel-note';
import PanelBox from './boxes/panel-box';
import FeatureDetailsSummary from './feature-details-content/feature-details-summary';
import SkipLink from './skip-link';


/**
 * @type {import("@mapsight/ui/src/js/components/contexts.js").MainPanelContainer}
 */
const MainPanelContainer = memo(function MainPanelContainer({children}) {
	const magic = useMagic();
	const navPos = useNavPos();
	const listTitle = useSelector(listTitleSelector);
	const detailsFeature = useSelector(detailsFeatureSelector);
	const details = detailsFeature !== null;
	const view = useSelector(viewSelector);
	const specialPage = useSelector(specialPageSelector);

	const pageContent = isPageContent(specialPage) || navPos.error404;

	const isViewMapOnly = view === VIEW_MAP_ONLY;
	const isViewFullscreen = view === VIEW_FULLSCREEN;

	const featureSourceInfo = useSelector(featureSourceInfoSelector);
	const {topic: detailsTopic} = featureSourceInfo.source ? findAreaAndTopicFromSourceId(magic, featureSourceInfo.source) : {topic: null};
	const detailsBigMobile = details && (
		// ((!detailsTopic || !featureSourceInfo.foreign) && pageCameras) ||
		// (detailsTopic && isPageCameras(detailsTopic.type)) ||
		(!detailsTopic && navPos.topic && navPos.topic.bigMobile) ||
		(detailsTopic && detailsTopic.bigMobile)
	);

	const onCloseDetails = useOnCloseDetails();

	const menuButtonRef = useRef();

	const {mapsightContentRef, setMapsightContentRef} = useContext(RefsContext);

	useLayoutEffect(() => {
			if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
				console.log('main-panel-container useLayoutEffect', {detailsFeature, mapsightContentRef: mapsightContentRef.current});
			}

			if (isViewMapOnly && detailsFeature !== null) { // details sichtbar und entweder die Sichtbarkeit oder das sichtbare detail haben sich geändert
				if (mapsightContentRef?.current) {
					mapsightContentRef.current.scrollTop = 0;
				}
			}
		},
		// nicht ausführen wenn sich nur die mapsightContentRef geändert hat, deshalb wird die hier absichtlich nicht erwähnt
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isViewMapOnly, detailsFeature]
	);

	if (isViewMapOnly) {
		if (details) {
			// note has to go into the Box on mapOnly (aka mobile devices)
			const note = (detailsTopic && detailsTopic.detailsMarginal) ?
				(<PanelNote html={`${detailsTopic.detailsMarginal}`} />) :
				undefined;

			return (
				<PanelBox
					tabIndex={0}
					ref={setMapsightContentRef}
					k={'mapOnly-selectionInfo'}
					onClose={onCloseDetails}
					headline={
						!detailsTopic && featureSourceInfo.foreign && featureSourceInfo.name ? featureSourceInfo.name :
							(detailsTopic && detailsTopic !== navPos.topic ? detailsTopic.pageName : listTitle)
					}
					mods={detailsBigMobile ? ['details-bigmobile'] : undefined}
					footer={<FeatureDetailsSummary />}
				>
					{children}
					{note}
				</PanelBox>
			);
		} else {
			return (
				<Fragment>
					{children}
				</Fragment>
			);
		}
	}

	// implicit view !isViewMapOnly and by the logic of current mapsight/ui it !isViewMobile.
	// the latter is a break of conventions we shall not trust forever, as MainPanelContainerStart and -End get called even if isViewMobile.
	// that if(isViewFullscreen) is here to be compatible with possible future breaking of current mapsight/ui logic.
	if (isViewFullscreen) {
		return (
			<Fragment>
				<SkipLink />
				<Logo />
				<MenuButton ref={menuButtonRef} />
				<div className="vmznds-main-panel-container__content">
					<Menu buttonRef={menuButtonRef} />

					<Boxes boxesRef={mapsightContentRef}>
						{children}
					</Boxes>

					{pageContent && !navPos.error404 && (
						<SideMenu />
					)}
				</div>
			</Fragment>
		);
	}

	return null;
});

export default withErrorBoundary(MainPanelContainer);
