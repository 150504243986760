import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';

import {createListFeatureSelector} from '@mapsight/core/lib/list/selectors';

import {FEATURE_LIST} from '@mapsight/ui/src/js/config/constants/controllers';
import getPath from '@neonaut/lib-js/es/object/getPath';

import ListSummaryInner from './list-summary-inner';

const totalCountSelector = createSelector(
	[createListFeatureSelector(FEATURE_LIST, 'featureSources')],
	source => getPath(
		source,
		['data', 'features', 'length']
	)
);

export default memo(
	function ListOneSummary({}) {
		const totalCount = useSelector(totalCountSelector);

		return (
			<ListSummaryInner
				totalCount={totalCount}
			/>
		);
	}
);
