import React, {Fragment, memo} from 'react';
import {useSelector} from 'react-redux';

import {translate} from '@mapsight/ui/src/js/helpers/i18n';

import {memoForever} from '../../../mapsight-vmznds/helpers';
import {modalitySelector, tripParameterSelector, tripsSelector, tripValid} from '../store/selectors';

import {formatTripDuration, formatTripLength} from '../store/api/helpers';

import {NavAppLink} from './qr-link';


export default memo(
	function NavigationResult() {
		const tripParameters = useSelector(tripParameterSelector);
		const modality = useSelector(modalitySelector);
		const trips = useSelector(tripsSelector);
		/**@type{import('./types').TripState}*/const trip = trips[modality];
		/**@type{string}*/const error = trip?.error; // for values see import('./types').Error

		console.log('navigation-result', {
			condValid: ( // data is not valid
				!tripParameters.origin.requestId || !tripParameters.dest.requestId
				|| !tripParameters.origin.location || !tripParameters.dest.location
				|| tripParameters.origin.requestId !== trip.requestIds?.origin
				|| tripParameters.dest.requestId !== trip.requestIds?.dest
			),
			condError: !!error,
			condLoading: !trip.steps,
			trip, modality, trips
		});


		if(!tripValid(tripParameters, trips, modality)) {
			return null;
		}

		if(error) {
			return (
				<p className="vmznds-panel-box__info vmznds-state--1"
				   dangerouslySetInnerHTML={{__html: translate(error)}} />
			);
		}

		if(!trip.steps) { //  implicit: && data is valid && !error
			return (
				<p className="vmznds-panel-box__info vmznds-state--1">
					berechne …<br />
					<br />
				</p>
			);
		}


		//  implicit: data is valid && !error && not loading
		return (<Fragment>
			<NavAppLink name={tripParameters.dest.name}
						origin={tripParameters.origin}
						dest={tripParameters.dest}
						service={trip.service}
						nunavLink={tripParameters.dest.location?.nunavDeepLink}
			/>

			<div className="ms3-pre-routing-box__result">
				<dl className="ms3-pre-routing-box__meta">
					{trip.durationSecs ? (
						<Fragment>
							<dt className="ms3-pre-routing-box__meta-term">
								Fahrtzeit:
							</dt>
							<dd className="ms3-pre-routing-box__meta-detail">
								{formatTripDuration(trip.durationSecs)}
							</dd>
						</Fragment>
					) : null}

					{trip.lengthM && (
						<Fragment>
							<dt className="ms3-pre-routing-box__meta-term">
								Gesamtlänge:
							</dt>
							<dd className="ms3-pre-routing-box__meta-detail">
								{formatTripLength(trip.lengthM)}
							</dd>
						</Fragment>
					)}
				</dl>
			</div>
		</Fragment>);
	},
	memoForever
);
