/**
 * This was created by Thomas and Herbert hacked in code taken from render-await-feature-sources-loaded from @ms/ui
 */
import {compose} from 'redux';

import get from '@neonaut/lib-js/es/object/getPath';

import {load, LOAD_FEATURE_SOURCE_ERROR, LOAD_FEATURE_SOURCE_SUCCESS} from '@mapsight/core/lib/feature-sources/actions';

import createActionWatcher from '@mapsight/ui/src/js/helpers/create-action-watcher';
import {viewSelector} from '@mapsight/ui/src/js/store/selectors';
import {FEATURE_LIST, FEATURE_SOURCES} from '@mapsight/ui/src/js/config/constants/controllers';

import {onInit, onMagicLocationChange, onMagicLocationLoggedInChange, onMagicLocationViewChange} from '../routing/route-to-store';
import {FEATURE_LIST_TWO} from '../store/controller';

const defaultLoadOptions = {};

/**
 * @param {{
 *   locPathName: string,
 *   magic: import('../types').Magic,
 * }} options
 */
export default function renderRouteToStoreAndAwaitFeaturesPlugin({locPathName, magic, loadOptions = defaultLoadOptions}) {
	const actionWatcher = createActionWatcher();

	return {
		afterInit: function renderAwaitListFeatureSourcesLoadedInitPlugin(context) {
			context.storeEnhancer = compose(context.storeEnhancer, actionWatcher.enhancer);
		},

		/**
		 * @param {{
		 *   store: import("redux").Store,
		 *   canPluginsDelayRender: boolean,
		 * }} context
		 */
		beforeRender({store, canPluginsDelayRender}) {
			if (!canPluginsDelayRender) {
				return Promise.resolve(undefined);
			}

			// execute routeToStore to prepare store for rendering. this also set's the needed feaature sources
			const additionalSources = []; // TODO den funktionen eine load unterjubeln, damit wir das dann hier laden können
			const dispatch = store.dispatch;
			const view = viewSelector(store.getState());
			onInit(dispatch);
			onMagicLocationChange(magic, locPathName, null, dispatch, source => additionalSources.push(source));
			onMagicLocationViewChange(magic, locPathName, null, view, dispatch);
			onMagicLocationLoggedInChange(magic, locPathName, null, false, null, dispatch); // FIXME wenn das FE-Login irgendwann umgesetzt ist und tatsächlich geschützte Seiten durch diesen Code laufen, sollte das hier berücksichtigt werden können, oder?

			// fetch list of sources to be loaded (
			const state = store.getState();

			let featureSourcesToBeLoaded = getVMZFeatureSourcesToBeLoaded(state, additionalSources);

			if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
				console.log('route-server plugin - call', {featureSourcesToBeLoaded, additionalSources});
			}

			return new Promise(function (resolve) {
				if (!featureSourcesToBeLoaded.length) {
					resolve();
					return;
				}

				actionWatcher.handler = action => {
					// wait for feature sources to be loaded
					if (action.type === LOAD_FEATURE_SOURCE_SUCCESS || action.type === LOAD_FEATURE_SOURCE_ERROR) {
						if (featureSourcesToBeLoaded.indexOf(action.id) > -1) {
							featureSourcesToBeLoaded = featureSourcesToBeLoaded.filter(f => f !== action.id);
						}

						if (!featureSourcesToBeLoaded.length) {
							resolve();
						}
					}
				};

				featureSourcesToBeLoaded.forEach(featureSourceId => dispatch(load(FEATURE_SOURCES, featureSourceId, loadOptions)));
			});
		},
	};
}

function getVMZFeatureSourcesToBeLoaded(state, additionalSources) {
	const sources = [
		...additionalSources,
		get(state, [FEATURE_LIST, 'featureSource']),
		get(state, [FEATURE_LIST_TWO, 'featureSource']),
	];
	return sources.filter((a, index) => a && sources.indexOf(a) === index);
}
