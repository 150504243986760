import React, {memo} from 'react';
import {Link as ReactLink} from 'react-router-dom';

import {useNavPos} from '../hooks';

import {QS_REDUCED} from '../routing/helpers';

// we need support for any <Link> in jsx-a11y/anchor-has-content. this is not the right place to complain.
// most time we will have content, it's member children inside props
/* eslint-disable jsx-a11y/anchor-has-content */

/**
 * @param {{
 *     reduced?: boolean,
 *     to: string | {
 *         pathname: string,
 *         state?: null | {
 *             area: string | null,
 *             nunav: {name: string, link: string | null, longitude: string | null, latitude: string | null},
 *             select: string | null
 *         }
 *     }
 * }} props
 */
const Link = (props) => {
	const {to: _to, reduced, ...otherProps} = props;
	const navPos = useNavPos();

	let to = _to || {};
	if (typeof to === 'string') {
		to = {pathname: to};
	}

	const {area, areaFound} = useNavPos();

	if (to.pathname && to.pathname.includes('://')) {
		return <a href={to.pathname} {...props} />;
	}

	//  /y in /x/y will be removed when link target is a simple search string
	// Workaround I: take adress from Browser if available
	if (!to.pathname && typeof window !== 'undefined') {
		to.pathname = window.location.pathname;
	}

	// Workaround II: let all Links end with / which will hide the problem on SSR
	if (to.pathname && !to.pathname.endsWith('/')) {
		to.pathname = to.pathname + '/';
	}

	let toReduced = navPos.reduced;
	if (reduced !== undefined && reduced !== null) {
		toReduced = reduced;
	}

	if (toReduced) {
		to.pathname = `${to.pathname ? to.pathname : ''}?${QS_REDUCED}`;
	} else if (!to.pathname) {
		to.pathname = '?';
	}

	to.state = to.state || {};
	to.state.area = to.state.area || (areaFound && area.uri || undefined);

	return <ReactLink to={to} {...otherProps} />;
};


export default memo(Link);
