import React, {forwardRef, memo} from 'react';

import {useNavPos} from '../../hooks';
import {getBaseUrl} from '../../routing/helpers';

import Link from '../link';


// tabIndex={-1} aria-hidden={true} nur auf der Startseite, falls dieses Element später auch für Deep-Links verwendet werden soll
export default memo(forwardRef(function StartPageItem(props, ref) {
	const navPos = useNavPos();
	const {to, children, className, feature, ...other} = props;
	const target = to ? to :
		feature ? `${getBaseUrl()}${navPos.area && !navPos.topic.noArea ? navPos.area.uri + '/' : ''}${navPos.topic.pageUri}/${feature.id}` : '#';

	return (
		<li className={className} ref={ref}>
			<Link to={target} tabIndex={-1} aria-hidden={true} {...other}>
				{children}
			</Link>
		</li>
	);
}));
