import React, {memo, useCallback, useContext} from 'react';

import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';

import {RefsContext} from '../../contexts';

// Es gibt zwei ContextBox im React-Tree: einmal in app-wrapper-start für Mobil und einmal in map-overlay-start für Desktop
// relevant ist das nur für das Speichern des Referenz des Link-Ziels für den skipLinks wenn auf Tablets durch drehen des Geräts von einer zur anderen Ansicht gewechselt wird:
// dann würden sich die Referenzen gegenseitig überschreiben, weshalb es für jede der beiden ContentBox jetzt eine eigene Ref gibt.
const ContentBox = memo(
	/**
	 * @param {{
	 *   forMobile: boolean,
	 *   k?: string,
	 *   alignWithPanelContent?: boolean,
	 *   fullWidth?: boolean,
	 *   onClose?: () => void,
	 *   mods: Record<string, boolean> | Array<string | boolean | null | undefined> | string | boolean | null | undefined,
	 *   children: React.ReactNode
	 * }} props
	 * @returns {React.ReactElement}
	 */
	function ContentBox({
		forMobile = false,
		k = '',
		alignWithPanelContent = false,
		fullWidth = false,
		onClose: origOnClose,
		mods,
		children,
	}) {
		const {setPageContentRef, setMobilePageContentRef} = useContext(RefsContext);
		const onClose = useCallback(
			/**
			 * @param {React.MouseEvent} e
			 */
			(e) => {
				if (origOnClose && e.target === e.currentTarget) {
					origOnClose();
				}
			},
			[origOnClose],
		);

		return (
			<main ref={forMobile ? setMobilePageContentRef : setPageContentRef} tabIndex="-1"
				 className={modClasses('vmznds-content-box', {
					'align-with-panel-content': alignWithPanelContent,
					'full-width': fullWidth,
					[`key-${k}`]: true,
					...mods
				})}
				onClick={onClose}
			>
				<article className="vmznds-content-box__content">
					{children}
				</article>
			</main>
		);
	}
);
export default ContentBox;
