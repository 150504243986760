module.exports=[
	{
		"title": "In Niedersachsen",
		"type": "group",
		"entries": [
			{
				"title": "Hannover",
				"x": 9.737300,
				"y": 52.37285
			},
			{
				"title": "Braunschweig",
				"x": 10.52885,
				"y": 52.36602
			},
			{
				"title": "Göttingen",
				"x": 9.938280,
				"y": 51.53125
			},
			{
				"title": "Lüneburg",
				"x": 10.41554,
				"y": 53.24820
			},
			{
				"title": "Oldenburg",
				"x": 8.212190,
				"y": 53.15573
			},
			{
				"title": "Osnabrück",
				"x": 8.047860,
				"y": 52.27411
			},
			{
				"title": "Wittmund",
				"x": 7.784440,
				"y": 53.57562
			},
			{
				"title": "Cuxhaven",
				"x": 8.698050,
				"y": 53.85862
			},
			{
				"title": "Meppen",
				"x": 7.293060,
				"y": 52.68967
			},
			{
				"title": "Walsrode",
				"x": 9.586370,
				"y": 52.86383
			},
			{
				"title": "Goslar",
				"x": 10.43348,
				"y": 51.90668
			},
			{
				"title": "Hameln",
				"x": 9.357530,
				"y": 52.10231
			}
		]
	},
	{
		"title": "In Nachbarländern",
		"type": "group",
		"entries": [
			{
				"title": "Bremen",
				"x": 8.80418,
				"y": 53.07564
			},
			{
				"title": "Hamburg",
				"x": 9.99283,
				"y": 53.55367
			},
			{
				"title": "Schwerin",
				"x": 11.41767,
				"y": 53.62661
			},
			{
				"title": "Stendal",
				"x": 11.86188,
				"y": 52.60626
			},
			{
				"title": "Magdeburg",
				"x": 11.62809,
				"y": 52.13234
			},
			{
				"title": "Kassel",
				"x": 9.49213,
				"y": 51.31295
			},
			{
				"title": "Minden",
				"x": 8.91482,
				"y": 52.28948
			},
			{
				"title": "Bielefeld",
				"x": 8.53532,
				"y": 52.02425
			},
			{
				"title": "Hengelo (NL)",
				"x": 6.79268,
				"y": 52.26722
			},
			{
				"title": "Hoogeveen (NL)",
				"x": 6.47721,
				"y": 52.72573
			},
			{
				"title": "Groningen (NL)",
				"x": 6.57553,
				"y": 53.21875
			}
		]
	}
]
