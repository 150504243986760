import React, {Fragment, memo, useMemo} from 'react';

import {cmpByKey, cmpInvert, isDefined} from '../../../mapsight-vmznds/helpers';

import ParkingArea from './parking-area';


/**
 * @type {import("react").ComponentType<{
 *   occupation: import("../occupation").Occupation,
 *   isBike: boolean,
 * }>}
 */
const ParkingUtilization = memo(
	function ParkingUtilization({occupation, isBike}) {
		const areas = useMemo(
			() => {
				const newAreas = occupation.areas.slice();

				// sort areas by number of "slot-types" with occupation data. So that the area with
				// the most occupation data is at the top.
				newAreas.sort(cmpInvert(cmpByKey((area) =>
					[
						area.charging?.occupied,
						area.disabled?.occupied,
						area.motorVehicle?.occupied,
						area.woman?.occupied,
					]
						.filter(isDefined)
						.length
				)));

				return newAreas;
			},
			// NOTE: eslint react does not understand this trick of unpacking the array here
			// eslint-disable-next-line react-hooks/exhaustive-deps
			occupation.areas
		);

		return (<Fragment>
			{areas.map((area, index) => {
				const headline = (() => {
					// Keine Überschrift für die einzelne ParkingArea wenn der Parkplatz nur diese eine einzige hat.
					if (areas.length <= 1) {
						return null;
					}

					if (area.name !== null) {
						return area.name;
					} else {
						return `Der ${index + 1}. Bereich`;
					}
				})();

				return (
					<section
						key={index}
						className="vmznds-feature-details-pundr__area"
					>
						{headline !== null && (
							<h2 className="vmznds-feature-details-pundr__area-headline">
								{headline}
							</h2>
						)}

						<ParkingArea area={area} isBike={isBike} />
					</section>
				);
			})}
		</Fragment>);
	}
);

export default ParkingUtilization;
