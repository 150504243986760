import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import debounce from 'lodash/debounce';

import AutoSuggest from 'react-autosuggest';

import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';

import {clearSuggestions, getSuggestions, selectSuggestion, setInput, setInputUserLocation} from '../store/actions';
import {inputSelectors, suggestionsSelectors, userGeolocationSelector} from '../store/selectors';
import {NAVIGATION} from '../store/constants';

import InputContainer from './input-container';

// TODO so refactoren, dass der an autosuggest übergebene value ein fester String ist und keine Komponente
// onChange darf nur neue Suggestions auslösen, aber nicht das Eingabefeld setzen ... oder das onChange einfach verzögern?
// zusätzlich ref wieder setzen auf inputElementRef

function renderSuggestion(suggestion) {
	return (
		<span className={modClasses('ms3-trip-stop-input__suggestion', {event: !!suggestion.location?.nunavEventId})}>
			{suggestion.name}
		</span>
	);
}

function getSuggestionValue(suggestion) {
	return suggestion.name;
}

function shouldRenderSuggestions(value, reason) {
	return value.trim().length > 2;
}

function sortSuggestionEntries(entries, isEvents) {
	const events = entries.filter(e => !!e.location?.nunavEventId);
	if(isEvents) {
		return events;
	} else {
		return [
			...events,
			...entries.filter(e => !e.location?.nunavEventId).sort((a, b) => a.name.length - b.name.length)
		];
	}
}

// TODO get label and placeholder texts from i18n using inputTarget as a partial key
export default memo(
	/**
	 * @param {{
	 *   inputTarget: import("../store/types").InputTarget,
	 *   label?: string,
	 *   placeholder?: string
	 * }} props
	 */
	function LocationInput({
							   inputTarget,
							   label,
							   placeholder,
						   }) {
		const dispatch = useDispatch();
		/** @var {React.Ref<HTMLElement>} inputElementRef */
		const inputElementRef = useRef();

		const selectors = useMemo(
			() => ({
				input: inputSelectors[inputTarget],
				suggestions: suggestionsSelectors[inputTarget],
			}),
			[inputTarget]
		);
		const inputData = useSelector(_ => selectors.input(_));
		const {
			location,
			locationName,
			locationSetBy
		} = useMemo(
			() => ({
				location: inputData.location,
				locationName: inputData.name || '',
				locationSetBy: inputData.setBy
			}),
			[inputData]
		);
		const locationIsEvent = !!location?.nunavEventId;
		const suggestions = useSelector(selectors.suggestions);
		const suggestionsRequestId = suggestions.requestId;

		const [isEvents, setIsEvents] = useState(false);
		const setEvents = useCallback(
			() => setIsEvents(!isEvents),
			[isEvents, setIsEvents]
		);

		// WONT wegen BUDGET: die nachfolgende Zeile wurde aus dem alten Code übernommen und wenn sie was bezwecken soll dann, dass beim Fokussieren nach Auswahl eine POI keine Liste kommt
		// auf der aktuellen Website funktioniert das nicht: die Auswahl kommt trotzdem
		// jetzt nach dem Rewrite funktioniert es zu gut: bei Refokus kommt nie eine Liste
		// → weggelassen da es dem aktuellen Stand entspricht und keine Zeit das zu suchen
		// const suggestionsEntries = suggestions.showSuggestions ? Object.values(suggestions.entries || {}) : [];
		const suggestionsEntries = useMemo(
			() => sortSuggestionEntries(Object.values(suggestions.entries || {}), isEvents),
			[suggestions, isEvents]
		);

		const [inputLabel, setInputLabel] = useState('');
		const onChangeInputLabel = (event, {newValue}) => {
			setInputLabel(newValue);
		};
		useEffect(
			() => setInputLabel(locationName),
			[locationName]
		);

		const onSuggestionSelected = useCallback(
			(_, {suggestion}) => {
				dispatch(selectSuggestion(NAVIGATION, inputTarget, suggestion.id, suggestionsRequestId));
				setIsEvents(false);
			}, [dispatch, inputTarget, suggestionsRequestId]
		);

		// this is to have a requestId with Date.now when the debounce is called and not when debounce calls the function
		// so a clear while debouncing will not be overwritten which alows for even longer waiting times
		const debouncedDispatchGetSuggestions = useMemo(
			() => debounce(
				(value, requestId) => dispatch(getSuggestions(NAVIGATION, inputTarget, value, requestId)),
				1000
			),
			[dispatch, inputTarget]
		);
		const onSuggestionsFetchRequested = useCallback(
			({value}) => debouncedDispatchGetSuggestions(value, Date.now()),
			[debouncedDispatchGetSuggestions]
		);

		const onSuggestionsClearRequested = useCallback(() => {
			dispatch(clearSuggestions(NAVIGATION, inputTarget));
		}, [dispatch, inputTarget]);

		const resetInput = useCallback(() => {
			if (inputElementRef.current) {
				inputElementRef.current.value = '';
				inputElementRef.current.focus();
			}
			setIsEvents(false);
			dispatch(
				setInput(NAVIGATION, inputTarget, false, null, '', 'text')
			);
			},
			[dispatch, inputTarget, inputElementRef]
		);


		const userLocation = useSelector(userGeolocationSelector);
		const haveUserLocation = userLocation && userLocation.latitude && userLocation.longitude && !userLocation.error;
		const setUserLocation = useMemo(
			// eslint-disable-next-line no-confusing-arrow
			() => haveUserLocation ?
				// eslint-disable-next-line no-extra-parens
				(() => dispatch(setInputUserLocation(NAVIGATION, inputTarget))) :
				undefined,
			[haveUserLocation, inputTarget, dispatch]
		);

		// console.log('location-input', {haveUserLocation, userLocation, setUserLocation, inputTarget, locationName, locationSetBy, suggestionsEntries});

		return (
			<InputContainer
				label={isEvents ? 'Eventsuche:' : label}
				htmlFor={`l-${inputTarget}`}
				reset={(locationName !== '' || isEvents) && resetInput}
				setUserLocation={setUserLocation}
				setEvents={setEvents}
				isEvents={isEvents}
				haveEvent={locationIsEvent}
			>
				<div className="ms3-trip-stop-input">
					<AutoSuggest
						suggestions={suggestionsEntries}
						onSuggestionsFetchRequested={onSuggestionsFetchRequested}
						onSuggestionsClearRequested={onSuggestionsClearRequested}
						onSuggestionSelected={onSuggestionSelected}
						getSuggestionValue={getSuggestionValue}
						renderSuggestion={renderSuggestion}
						shouldRenderSuggestions={shouldRenderSuggestions}
						inputProps={{
							id: `l-${inputTarget}`,
							className: modClasses('ms3-input-container__element', {
								incomplete: !location,
								'from-map': locationSetBy === 'map',
								'from-user-geolocation': locationSetBy === 'userGeolocation',
							}),
							value: inputLabel,
							onChange: onChangeInputLabel,
							disabled: origin === 'userGeolocation',
							placeholder: placeholder,
						}}
					/>
				</div>
			</InputContainer>
		);
	}
);
