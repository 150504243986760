import React, {memo} from 'react';

import QrCodeSVG from 'qrcode-svg';

const QrCode = memo(
	function QrCode({value, className, size}) {
		const options = Object.assign({
				container: 'svg-viewbox',
				content: value,
				ecl: 'M', // qr code error correction mode, M is level 2
				join: true,
				xmlDeclaration: false,
			}, size ? {
				width: size,
				height: size,
			} : undefined
		);
		const code = new QrCodeSVG(options);
		const svg = code.svg();
		const cssClass = 'vmznds-qr ' + (className ? className : '');

		return (
			<img
				alt="QR-Code zum scannen mit dem Mobiltelefon bei installierter App"
				className={cssClass}
				src={`data:image/svg+xml;base64,${btoa(svg)}`}
			/>
		);
	}
);

export default QrCode;
