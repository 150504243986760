import React, {forwardRef, memo} from 'react';

import getFeatureProperty from '@mapsight/ui/src/js/helpers/get-feature-property';

import useCameraUrl from '../content/camera-url';

import LinkItem from './link-item';

const ListOneCamerasListItem = memo(forwardRef(
	// TODO: eslint jsdoc does not compute this typescript
	// eslint-disable-next-line jsdoc/valid-types
	/**
	 * @param {{feature: Record<string, any>} & Record<string, any>} props
	 * @param {React.Ref} ref
	 */
	function ListOneCamerasListItem({feature, ...wrapperProps}, ref) {
		const imgHref = useCameraUrl(feature);
		if (
			typeof wrapperProps === 'object'
			&& wrapperProps !== null
			&& typeof wrapperProps.className === 'string'
		) {
			wrapperProps.className += ' vmznds-list-one-cameras__list-item-wrapper';
		}

		return (
			<LinkItem
				ref={ref}
				feature={feature}
				{...wrapperProps}
			>
				<article className="vmznds-list-one-cameras__list-item">
					<div className="vmznds-list-one-cameras__list-item-img-wrapper-wrapper">
						<div className="vmznds-list-one-cameras__list-item-img-wrapper">
							{imgHref && (
								<img
									className="vmznds-list-one-cameras__list-item-img"
									src={imgHref}
									alt=""
								/>
							)}
						</div>
					</div>

					<h1 className="vmznds-list-one-cameras__list-item-headline">
						{getFeatureProperty(feature, 'title')}
					</h1>
				</article>
			</LinkItem>
		);
	}
));

export default ListOneCamerasListItem;
