/* eslint-disable jsdoc/valid-types */
// TODO: eslint does not know all the typescript defs (true instead of boolean, Record<>). Either use "normal" types or fix eslint?

// FIXME use cross-fetch
import fetch from '../../../../fetch';
import {pointsToBoundingBox} from '../pointsToBoundingBox';
import {genUrlParams} from '../helpers';


/**
 * @template T
 * @param {T} value
 * @return {value is NonNullable<T>}
 */
function nonNullable(value) {
	return value !== null && value !== undefined;
}

/**
 * @param {import("./types").Step} step
 * @returns {import("../../types").TripStep}
 */
function mapTripStep(step) {
	const locations = (() => {
		if (!step.geoPoints) {
			return [];
		}

		return step.geoPoints
			.map(({latitude, longitude}) =>
				(!latitude || !longitude ? null : {
					lat: latitude,
					lon: longitude,
				}))
			.filter(nonNullable);
	})();

	return {
		turnCommand: step.type ? step.type[0].toUpperCase() + step.type.slice(1) : 'Unknown',
		lengthM: step.length,
		locations,
		// und nachfolgend bei turnCommand, locations und lengthM die oben errechneten Werte ggf. durch gelieferte überschreiben
		...step.turnInfo,
		...step
	};

	// return {
	// 	turnCommand: step.turnInfo && step.turnInfo.turnCommand || 'Unknown',
	// 	...(step.turnInfo || {}), // überschreibt turnCommand von oben und übernimmt auch zusätzliche Parameter
	// 	lengthM: step.lengthM || step.length || null,
	// 	streetName: step.streetName || null,
	// 	referenceName: step.referenceName || null,
	// 	locations: step.locations || locations,
	// 	instruction: step.instruction
	// };
}

/**
 * @param {import("./types").GeoLocation} geoLocation
 * @returns {*[]|null}
 */
function mapGeoLocationToCoords(geoLocation) {
	const {latitude: lat, longitude: lon} = geoLocation;

	return lat && lon ? [lat, lon] : null;
}

/**
 * flatten and filter steps
 *
 * @param {Array<import("./types").Step>} steps the input to this function
 * @return {Array<import("../../types").TripStep>} simplified step object
 */
function handleSteps(steps) {
	//return steps
	//	// we only want the first and the last point and intermediary points that have an command
	//	// and are not suppressed
	//	.filter((step, index, arr) =>
	//		index === 0 ||
	//		index === arr.length - 1 ||
	//		(
	//			!step.turnInfo.shouldSuppressTurnCommand &&
	//			step.turnInfo.turnCommand !== 'None'
	//		)
	//	)
	//	.map(
	//		({length, streetName, referenceName, turnInfo: {turnCommand}}) => ({
	//			length, streetName, referenceName, turnCommand
	//		})
	//	);

	return steps.map(mapTripStep);
}

/**
 * @param {import("./types").Step[]} steps
 * @return {null | import("../../types").Bbox}
 */
function getBoundingBoxFromSteps(steps) {
	if (!steps) {
		return null;
	}

	const points = steps
		.flatMap((step) => {
			if (!step.geoPoints) {
				return [];
			}

			return step.geoPoints
				.map(mapGeoLocationToCoords)
				.filter(nonNullable);
		});

	return pointsToBoundingBox(points);
}

/**
 *
 * @param {string} url
 * @param {string} defaultServiceValue
 * @returns {Promise<import("../../types").TripResult>}
 */
async function fetchRouting(url, defaultServiceValue) {
	const res = await fetch(url);
	const {
		/**string*/err,
		/**FIXME*/trips,
		...other
	} = await res.json();
	const route = trips && trips[0];
	const {lengthM, durationSecs, steps} = route || {};
	const service = route?.service || trips?.service || other?.service;

	return {
		error: err,
		steps: steps ? handleSteps(steps) : [],
		lengthM: lengthM || null,
		durationSecs: durationSecs,
		bbox: steps ? getBoundingBoxFromSteps(steps) : null,
		service: service || defaultServiceValue,
	};
}

/**
 * @param {Date} datetime
 * @param {import("../../types").Location} originLoc
 * @param {import("../../types").Location} destinationLoc
 * @returns {Promise<import("../../types").TripResult>}
 */
export async function fetchTripGM(datetime, originLoc, destinationLoc) {
	const urlParams = {
		origin: `${originLoc.lat},${originLoc.lon}`,
		destination: `${destinationLoc.lat},${destinationLoc.lon}`,
		dt: `${Math.floor(datetime.getTime() / 1000)}`,
	};

	if(destinationLoc.nunavEventId) {
		urlParams.eventId = destinationLoc.nunavEventId;
		urlParams.eventRole = destinationLoc.nunavEventRole;
	}

	const url = `/graphmasters-api/route.php?${genUrlParams(urlParams)}`;

	return fetchRouting(url, 'nunav');
}


/**
 * @param {import("../../types").Location} originLoc
 * @param {import("../../types").Location} destinationLoc
 * @returns {Promise<import("../../types").TripResult>}
 */
export async function fetchTripBikeRouting(originLoc, destinationLoc) {
		const urlParams = {
			originLat: `${originLoc.lat}`,
			originLon: `${originLoc.lon}`,
			destinationLat: `${destinationLoc.lat}`,
			destinationLon: `${destinationLoc.lon}`,
		};
		const url = `/bike-routing/main.php?${genUrlParams(urlParams)}`;

		return fetchRouting(url, 'bikeCitizens');
}


/**
 * @param {Date} datetime
 * @param {import("../../types").Location} originLoc
 * @param {import("../../types").Location} destinationLoc
 * @returns {Promise<import("../../types").TripResult>}
 */
export async function fetchTripTrias(datetime, originLoc, destinationLoc) {
	const urlParams = {
		originLat: `${originLoc.lat}`,
		originLon: `${originLoc.lon}`,
		destinationLat: `${destinationLoc.lat}`,
		destinationLon: `${destinationLoc.lon}`,
		dt: `${Math.floor(datetime.getTime() / 1000)}`,
	};
	const url = `/trias/routing.php?${genUrlParams(urlParams)}`;
	return fetchRouting(url, 'efa');
}
