/**
 * Updates a given html element's src attribute regularly in the given time.
 *
 * Example usage (default):
 * 	<!-- This image will be updated every 5 seconds with the current timestamp append as a query string (e.g. /url/to/image.jpg?1234) -->
 * 	<img src="/url/to/image.jpg" data-refresh-interval="5">
 *
 * Example usage (path based cache busting):
 * 	<!-- This image will be updated every 5 seconds with the current timestamp append as a path segment (e.g. /url/to/image.jpg/1234/) -->
 * 	<img src="/url/to/image.jpg" data-refresh-interval="5">
 *
 *
 * In this example the image would be refreshed every 5 seconds with an appended query string that
 * should bust any client side caches and force it to reload the source.
 *
 * TODO: Consider supporting in-filename based cache busting (name.1212121212.jpg)
 *
 * @param {HTMLElement} targetElement element whose src attribute should be refreshed
 */
export default function refreshingImage(targetElement) {
	const refreshInterval = parseInt(targetElement.dataset.refreshInterval, 10) * 1000;
	const usePathBasedCacheBusting = targetElement.dataset.usePathBasedCacheBusting === 'true';
	const omitTrailingSlash = targetElement.dataset.omitTrailingSlash === 'true';

	// get and save original untempered source url. save it as data prop for subsequent calls that.
	const originalSrc = targetElement.dataset.originalSrc || targetElement.getAttribute('src');
	targetElement.dataset.originalSrc = originalSrc;

	function refresh() {
		// Removed as we now add the timestamps in the bitmap
		//updateStatusThrottled();

		const cacheBustingString = '' + (Math.round(Date.now() / refreshInterval) * refreshInterval);

		let currentUrl;
		if (usePathBasedCacheBusting) {
			// path
			const base = originalSrc.replace(/\/$/, '') + '/';
			const tail = omitTrailingSlash ? '' : '/';
			currentUrl = base + cacheBustingString + tail;
		} else {
			// query string
			currentUrl = originalSrc + '?' + cacheBustingString;
		}

		targetElement.setAttribute('src', currentUrl);
		targetElement.dataset.refresher = JSON.stringify(window.setTimeout(refresh, refreshInterval));
	}

	if (refreshInterval && originalSrc) {
		/** {Number} existingRefresher */
		const existingRefresher = targetElement.dataset.refresher && JSON.parse(targetElement.dataset.refresher);
		if (existingRefresher) {
			clearTimeout(existingRefresher);
		}

		// early initial refresh
		targetElement.dataset.refresher = JSON.stringify(window.setTimeout(refresh, 100));
	}
}
