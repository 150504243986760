import React from 'react';
import {Route, Switch} from 'react-router';

import MapsightApp from '@mapsight/ui/src/js/components/app';

// v6 benötigt <Route path="*" element={<InnerRouting {...props} />} />
function RoutedMapsightApp({...props}) {
	return (
		<Switch>
			<Route path="*">
				<MapsightApp {...props} />
			</Route>
		</Switch>
	);
}

export default RoutedMapsightApp;
