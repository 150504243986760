import React, {memo} from 'react';

const CloseButton = memo(
	/**
	 * @param {{
	 *   onClick: () => void,
	 *   shortDescription?: string,
	 * }} props
	 * @returns {React.ReactElement}
	 */
	function CloseButton({
		 onClick,
		 shortDescription = 'schließen',
	}) {
		return (
			<button
				onClick={onClick}
				className="vmznds-close-button"
			>
				<span className="visuallyhidden">{shortDescription}</span>
			</button>
		);
	}
);
export default CloseButton;
