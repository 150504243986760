/* eslint-disable jsdoc/valid-types,jsdoc/no-undefined-types */
// TODO: eslint does not know all the typescript defs (true instead of boolean, Record<>). Either use "normal" types or fix eslint?

// TODO move them to NNLIBJS

/**
 * saves the comparision on memo-ized components without params, ie. ignore if the using component supplies one
 * this does NOT inhibit rerender due to calls to any useXXX()
 *
 * @return {true}
 */
export const memoForever = () => true;

/**
 * @template T
 * @param {T | null | undefined} val
 * @returns {val is T}
 */
export function isDefined(val) {
	return val !== null && val !== undefined;
}

/**
 * @param {any} val
 * @returns {val is Record<string, any>}
 */
export function isRecord(val) {
	return val !== null && typeof val === 'object';
}

/**
 * @template T
 * @template R
 * @param {Array<T>} array
 * @param {R} init
 * @param {(acc: R, val: T) => R} f
 * @returns {R}
 */
export function arrayReduce(array, init, f) {
	return array.reduce(f, init);
}

/**
 * @param {any} val
 * @returns {val is string}
 */
export function isString(val) {
	return typeof val === 'string';
}

/**
 * @param {any} val
 * @return {string | null}
 */
export function tryEnsureString(val) {
	if (typeof val === 'string') {
		return val;
	} else {
		return null;
	}
}

/**
 * @param {any} val
 * @return {string | null}
 */
export function tryEnsureNonEmptyString(val) {
	if (typeof val === 'string' && val !== '') {
		return val;
	} else {
		return null;
	}
}

/**
 * @template T
 * @template R
 * @param {(val: T) => R} f
 * @return {(a: T, b: T) => -1 | 0 | 1}
 */
export function cmpByKey(f) {
	return function cmpByKeyInner(a, b) {
		const aKey = f(a);
		const bKey = f(b);
		if (aKey === bKey) {
			return 0;
		} else if (aKey < bKey) {
			return -1;
		} else {
			return 1;
		}
	};
}

/**
 * @template T
 * @param {(a: T, b: T) => number} f
 * @return {(a: T, b: T) => -1 | 0 | 1}
 */
export function cmpInvert(f) {
	return function cmpInvertInner(a, b) {
		const res = f(a, b);
		if (res === 0) {
			return 0;
		} else if (res < 0) {
			return 1;
		} else {
			return -1;
		}
	};
}

/**
 * @param {any} feature
 * @returns {{properties: Record<string, any>}}
 */
export function cleanFeature(feature) {
	if (typeof feature !== 'object' || feature === null) {
		return {
			properties: {},
		};
	}

	const props = feature.properties;
	if (typeof props !== 'object' || props === null) {
		return {
			...feature,
			properties: {},
		};
	}

	return feature;
}
