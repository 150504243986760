import {batchActions} from 'redux-batched-actions';
import isNumeric from '@neonaut/lib-js/es/types/is-numeric';

import {async, set, unset, withPath} from '@mapsight/core/lib/base/actions';
import {setInteractionStatus, setLayerVisibility} from '@mapsight/core/lib/map/actions';
import {setData} from '@mapsight/core/lib/feature-sources/actions';
import {FEATURE_SOURCES, MAP} from '@mapsight/ui/src/js/config/constants/controllers';

import {PREROUTING_LEGEND} from '../../../mapsight-vmznds/components/map-legends';

import formatLocation from './api/helpers';

import {fetchReverseGeocode, fetchSuggestions} from './api/geocoding';
import {fetchTripBikeRouting, fetchTripGM, fetchTripTrias} from './api/routing';
import {getGeoJSONFromTrip} from './api/routing/getGeoJSONFromTrip';

import {userGeolocationSelector} from './selectors';
import {DRAW_INTERACTION, MODIFY_INTERACTION, NAVIGATION, ROUTE_LAYERS, ROUTE_STOPS_LAYER} from './constants';

/* eslint-disable no-use-before-define */

/**
 * @param {string} controllerName
 * @param {import('./types').InputTarget} inputTarget
 * @param {import('./types').Location} location
 * @return {Promise}
 */
export const setInputLocation = (controllerName, inputTarget, location) => async(function handleSetInputLocation(dispatch, getState) {
	// const currentState = getState()[controllerName][inputTarget].input || {};
	// const {requestId = 0} = currentState
	const nextRequestId = Date.now();
	console.log('setInputLocation for ' + inputTarget, {location, name: formatLocation(location), nextRequestId});
	dispatch((setInput(
		controllerName, inputTarget, false, location, formatLocation(location), 'map', nextRequestId
	)));
	return fetchReverseGeocode(location).then(
		function handleReverseGeocodeResolved({displayName, tags}) {
			console.log('handleReverseGeocodeResolved for ' + inputTarget, {
				cond: !!displayName,
				location,
				name: displayName,
				nextRequestId,
				controllerName
			});

			dispatch((setInput(
				controllerName,
				inputTarget,
				false,
				{tags: tags || [], ...location},
				displayName || formatLocation(location),
				'map',
				nextRequestId
			)));
		}
	);
	// man könnte hier im .catch() loaction mit {tags: [], error: true, ...location} setzen, nur damit das beschnitte BikeRouting im Falle eines Ausfalls des geocodings einen Fehler meldet
	// WON'T: wir hoffen mal, dass die Beschränkung aufgehoben wird.
});

/**
 * set's input to user location if it's known
 *
 * @param {string} controllerName
 * @param {import('./types').InputTarget} inputTarget
 * @return {Promise}
 */
export const setInputUserLocation = (controllerName, inputTarget) => async(function handleSetInputLocation(dispatch, getState) {
	const nextRequestId = Date.now();
	const geoLocation = userGeolocationSelector(getState());
	/**import(./types).Location*/const location = !geoLocation.error && isNumeric(geoLocation.latitude) && isNumeric(geoLocation.longitude) ?
		{lat: geoLocation.latitude, lon: geoLocation.longitude} : null;

	// debug code kept for reference:
	// const location = inputTarget === 'origin' ? {
	// 	lat: 52.36774, lon: 9.737492
	// } : {
	// 	lat: 52.37895, lon: 9.730282
	// };

	if (location) {
		dispatch((setInput(
			controllerName, inputTarget, false, location, formatLocation(location), 'userLocation', nextRequestId
		)));
		return fetchReverseGeocode(location).then(
			function handleReverseGeocodeResolved({displayName, tags}) {
				// console.log('handleReverseGeocodeResolved for ' + inputTarget, {
				// 	cond: !!displayName,
				// 	location,
				// 	name: displayName,
				// 	nextRequestId,
				// 	controllerName
				// });

				dispatch((setInput(
					controllerName,
					inputTarget,
					false,
					{tags: tags || [], ...location},
					displayName || formatLocation(location),
					'userGeo',
					nextRequestId
				)));
			}
		);
		// man könnte hier im .catch() loaction mit {tags: [], error: true, ...location} setzen, nur damit das beschnitte BikeRouting im Falle eines Ausfalls des geocodings einen Fehler meldet
		// WON'T: wir hoffen mal, dass die Beschränkung aufgehoben wird.
	} else {
		return undefined; // NOP ist more suitable then: return setInput(controllerName, inputTarget, false, null, '', '', nextRequestId);
	}
});


/**
 * @param {string} controllerName
 * @param {import('./types').InputTarget} inputTarget
 * @param {string} query
 * @param {number} thisRequestsId to be set to Date.now()
 * @return {Promise}
 */
export const getSuggestions = (controllerName, inputTarget, query, thisRequestsId) => async(function handleTextInput(dispatch, getState) {
	// this is called debounced, so it could be, that while waiting the debounce time in location-input.jsx a clear will be faster and overwritten again
	// so we just added thisRequestsId to the parameters and set it to Date.now at calling the debounced function.
	// the reducer then can check the requestId as usual, as it is now longer contains the debounce delay

	// we do not clear stale suggestion data, as it maybe will still be useful even it doesn't fit the query
	dispatch(set([controllerName, inputTarget, 'suggestions', 'requestId'], thisRequestsId));
	return fetchSuggestions(query).then(
		function handleSuggestionsResolved(suggestions) {
			dispatch(setSuggestions(controllerName, inputTarget, suggestions, thisRequestsId));
		},
		// function handleSuggestionsRejected() {
		//   // TODO reschedule or something like this?
		// }
	);
});

export const clearSuggestions = (controllerName, inputTarget) => set([controllerName, inputTarget, 'suggestions'], {
	requestId: Date.now(),
	entries: [],
});

export const SET_INPUT = 'NAVIGATION_SET_INPUT';
/**
 * @param {string} controllerName
 * @param {import('./types').InputTarget} inputTarget
 * @param {boolean} showSuggestions
 * @param {import('./types').Location} location
 * @param {string} name
 * @param {import('./types').SetBy} setBy
 * @param {number} [requestId]
 * @return {*}
 */
export const setInput = (controllerName, inputTarget, showSuggestions, location, name, setBy, requestId) => async(withPath(
	{
		type: SET_INPUT,
		inputTarget,
		showSuggestions,
		data: {
			location,
			name,
			setBy,
			requestId: requestId || Date.now(),
		}
	},
	[controllerName, inputTarget, 'input']
));

export const SET_SUGGESTIONS = 'NAVIGATION_SET_SUGGESTIONS';
const setSuggestions = (controllerName, inputTarget, suggestionEntries, requestId) => async(withPath({
		type: SET_SUGGESTIONS,
		inputTarget,
		entries: suggestionEntries,
		requestId,
	},
	[controllerName, inputTarget, 'suggestions']
));

export const SELECT_SUGGESTION = 'NAVIGATION_SELECT_SUGGESTION';
export const selectSuggestion = (controllerName, inputTarget, id, requestId) => withPath({
		type: SELECT_SUGGESTION,
		inputTarget,
		id,
		requestId,
	},
	[controllerName, inputTarget, 'suggestions']
);


export const SET_DATETIME = 'NAVIGATION_SET_DATETIME';
export const setDatetime = (date) => ({
	type: SET_DATETIME,
	date: date ? date.toISOString() : null,
});


// TODO move to constants and use them
export const routeStopFeatureIds =
	{
		origin: 'vmznds-pre-routing-trip-start',
		dest: 'vmznds-pre-routing-trip-finish'
	};


/**
 *
 * @param {import("./types").Modality} modality
 * @param {import("./types").TripState} data
 */
export const TRIP_RESULT = 'NAVIGATION_TRIP_RESULT';

export const tripResult = (controllerName, modality, data) => async(withPath({
		type: TRIP_RESULT,
		data: data,
		modality
	},
	[controllerName, 'trips', modality]
));


// die Fetch... setzen alle zu Beginn eine leere Liste aber schon mit datetime & requestIds, danach aktualisierien sie diese
// der Reducer guckt immer, ob die zu den dann aktuellen Werten passen
/**
 * @param {import('./types').Location} origin
 * @param {import('./types').Location} dest
 * @param {string} datetime
 * @param {import('./types').TripRequestIds} requestIds
 * @returns {*}
 */
// TODO clear and set list data
	// dann noch die bbox im observe
export const getTripCar = (origin, dest, datetime, requestIds) => async(function handleFetchTripCar(dispatch, getState) {
		const date = new Date(datetime);

		dispatch(batchActions([
			tripResult(NAVIGATION, 'car', {datetime, requestIds}),
			setData(FEATURE_SOURCES, ROUTE_LAYERS.car, {
				type: 'FeatureCollection',
				features: [],
			})
		]));

		return fetchTripGM(date, origin, dest)
			.then(
				(routeResult) => {
					dispatch(async(batchActions([
						tripResult(NAVIGATION, 'car', {...routeResult, datetime, requestIds}),
						setData(FEATURE_SOURCES, ROUTE_LAYERS.car, getGeoJSONFromTrip(routeResult))
					])));
				})
			.catch(reason => {
				console.error('Fehler beim Holen der Route', reason);
				dispatch(async(tripResult(NAVIGATION, 'car', {datetime, requestIds, error: 'Netzwerkfehler'})));
			});
	});


export const getTripBike = (origin, dest, datetime, requestIds) => async(function handleFetchTripBike(dispatch, getState) {
	dispatch(batchActions([
		tripResult(NAVIGATION, 'bike', {datetime, requestIds}),
		setData(FEATURE_SOURCES, ROUTE_LAYERS.bike, {
			type: 'FeatureCollection',
			features: [],
		})
	]));

	// FIXME fetchTripBike
	return fetchTripBikeRouting(origin, dest)
		.then(
			(routeResult) => {
				dispatch(async(batchActions([
					tripResult(NAVIGATION, 'bike', {...routeResult, datetime, requestIds}),
					setData(FEATURE_SOURCES, ROUTE_LAYERS.bike, getGeoJSONFromTrip(routeResult))
				])));
			})
		.catch(reason => {
			console.error('Fehler beim Holen der Route', reason);
			dispatch(async(tripResult(NAVIGATION, 'bike', {datetime, requestIds, error: 'Netzwerkfehler'})));
		});
});

export const getTripPublic = (origin, dest, datetime, requestIds) => async(function handleFetchTripPublic(dispatch, getState) {
	const date = new Date(datetime);

	dispatch(batchActions([
		tripResult(NAVIGATION, 'public', {datetime, requestIds}),
		setData(FEATURE_SOURCES, ROUTE_LAYERS.public, {
			type: 'FeatureCollection',
			features: [],
		})
	]));


	return fetchTripTrias(date, origin, dest)
		.then(
			(routeResult) => {
				dispatch(async(batchActions([
					tripResult(NAVIGATION, 'public', {...routeResult, datetime, requestIds}),
					setData(FEATURE_SOURCES, ROUTE_LAYERS.public, getGeoJSONFromTrip(routeResult))
				])));
			})
		.catch(reason => {
			console.error('Fehler beim Holen der Route', reason);
			dispatch(async(tripResult(NAVIGATION, 'public', {datetime, requestIds, error: 'Netzwerkfehler'})));
		});
});


const setModalityActions = (modality) => [
	set([NAVIGATION, 'modality'], modality),
	set(['list', 'featureSource'], ROUTE_LAYERS[modality]),
	setLayerVisibility(MAP, ROUTE_LAYERS.car, modality === 'car'),
	setLayerVisibility(MAP, ROUTE_LAYERS.bike, modality === 'bike'),
	setLayerVisibility(MAP, ROUTE_LAYERS.public, modality === 'public'),
];

export function setModality(modality) {
	return batchActions(setModalityActions(modality));
}

export function enableNavigation(modality) {
	return batchActions([
		...setModalityActions(modality),
		setInteractionStatus(MAP, DRAW_INTERACTION, true), // FIXME remove and let only observe change it
		setInteractionStatus(MAP, MODIFY_INTERACTION, true),
		set(['map', 'layers', ROUTE_STOPS_LAYER], {
			type: 'Vector',
			metaData: {
				title: 'Routenplaner',
				miniLegend: PREROUTING_LEGEND,
			},
			options: {
				visible: true,
				style: 'features',
				renderBuffer: 200,
				selections: {
					//mousedown: 'select',
					mouseover: 'highlight',
					//touch: 'select',
				},
				source: {
					type: 'VectorFeatureSource',
					options: {
						projection: 'EPSG:4326',
						featureSourceId: ROUTE_STOPS_LAYER,
						featureSourcesControllerName: 'featureSources',
						featureSelectionsControllerName: 'featureSelections',
						keepFeaturesInViewSelections: ['select'],
						fitFeaturesInViewSelections: ['select'],
						canAnimate: true,
						canCluster: true,
						useSelectionOverlay: true,
						featureSelections: [],
					},
				},
			},
		}),
		setInputUserLocation(NAVIGATION, 'origin'),
	]);
}

export function disableNavigation() {
	return batchActions([
		setInteractionStatus('map', DRAW_INTERACTION, false),
		setInteractionStatus('map', MODIFY_INTERACTION, false),
		unset(['map', 'layers', ROUTE_STOPS_LAYER]),
		setLayerVisibility(MAP, ROUTE_LAYERS.car, false),
		setLayerVisibility(MAP, ROUTE_LAYERS.bike, false),
		setLayerVisibility(MAP, ROUTE_LAYERS.public, false),
	]);
}
