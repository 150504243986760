import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {translate} from '@mapsight/ui/src/js/helpers/i18n';

import {getBaseUrl} from '../../../mapsight-vmznds/routing/helpers';
import {memoForever} from '../../../mapsight-vmznds/helpers';
import Link from '../../../mapsight-vmznds/components/link';

import {modalitySelector} from '../store/selectors';
import {NAVIGATION_URIS} from '../store/constants';


const navigationUrl = getBaseUrl() + 'navigation';

export const ModalityEntry = ({modality, children, black, ...attr}) => (
	<Link
		  className="ms3-pre-routing-modality__button"
		  to={`${navigationUrl}/${NAVIGATION_URIS[modality]}`}
		  {...attr}
	>
		<img src={`/assets/img/icons/navigation/modality-${modality}${black ? '-black' : ''}.svg`} alt="" aria-hidden="true" />
		{children}
	</Link>
);

export default memo(
	function Modality() {
		const modality = useSelector(modalitySelector);

		return (<div role="radiogroup" className="ms3-pre-routing-modality">
			{Object.keys(NAVIGATION_URIS).map(m =>
				(<ModalityEntry key={m} modality={m} role="radio" aria-checked={m === modality} black={m === modality}>
					<span className="visuallyhidden">{translate(`navigation.modality.${m}`)}</span>
				</ModalityEntry>)
			)}
		</div>);
	},
	memoForever
);
