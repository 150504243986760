import React, {Fragment, memo, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {batchActions} from 'redux-batched-actions';
import memoizee from 'memoizee';

import {deselectAll} from '@mapsight/core/lib/feature-selections/actions';

import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';
import {FEATURE_SELECTIONS} from '@mapsight/ui/src/js/config/constants/controllers';
import {FEATURE_SELECTION_PRESELECT, FEATURE_SELECTION_SELECT} from '@mapsight/ui/src/js/config/feature/selections';

import {calculateMaxAreas, getBaseUrl} from '../routing/helpers';
import {deselectAllViaHistory, setNavigationOpen} from '../store/actions';
import {SPECIAL_PAGE_NAVIGATION} from '../store/controller';
import {navOpenSelector} from '../store/selectors';

import {modalitySelector} from '../../modules/navigation/store/selectors';
import {NAVIGATION_URIS} from '../../modules/navigation/store/constants';
import {CONTENT_NAVAPP_URLS} from '../../modules/navigation/components/qr-link';

import {useLoginState, useMagic, useNavPos} from '../hooks';

import Link from './link';


function areaForTopic(maxAreas, maxArea, areaUri) {
	return areaUri; // provoke 404 and info box with suitable area

	// // TODO das ist für die Variante mit einem Popup wenn das Gebiet durch das menü gewechselt wird
	// // dafür muss dann aber auf dem Server 303 zurückgemeldet werden
	// return recommendedArea(maxAreas, maxArea, areaUri);
}

// open === undefined wird von side-menu genutzt
export const Entry = memo(
	/**
	 * @param {{
	 *   href: string,
	 *   name: string,
	 *   open: boolean | null | undefined
	 * }} props
	 */
	function Entry({authorization, href, name, open}) {
		const location = useLocation();
		const dispatch = useDispatch();
		const isLogin = `/${useMagic().authorization.url}` === href; // TODO later add "isLogin" to the properties of the entries in magic.additionalContent

		const active = location.pathname === href;
		const onClick = useCallback(
			() => {
				const actions = [
					setNavigationOpen(false),
					deselectAll(FEATURE_SELECTIONS, FEATURE_SELECTION_PRESELECT),
					deselectAllViaHistory(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT),
				];
				// Menü einfach immer schließen statt:
				// if (active) {
				// 	actions.push(setNavigationOpen(false));
				// }
				dispatch(batchActions(actions));
			},
			[dispatch]//, active],
		);

		//if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
		//	console.log('menu entry', {authorization, href, name, isLogin, magic: useMagic()});
		//}

		const l = authorization || isLogin ?
			(<a
				href={href}
				className="vmznds-menu__entry-link"
				tabIndex={open === false ? -1 : undefined}
			>
				→ {name}
			</a>) :
			(<Link
				to={href}
				className="vmznds-menu__entry-link"
				onClick={onClick}
				tabIndex={open === false ? -1 : undefined}
			>
				{name}
			</Link>);

		return (
			<li
				className={modClasses('vmznds-menu__entry', {active, [name]: true})}
				tabIndex={open===false?-1:undefined}
			>
				{l}
			</li>
		);
	}
);

/** @typedef {{
 *   name: string,
 *   menu: string,
 *   href: string,
 * }} MenuEntryData
 */

export const calculateMenus = memoizee(
	/**
	 * @param {import("../types").Magic} magic
	 * @param {any} maxAreas
	 * @param {any} area
	 * @param {boolean} loggedIn
	 * @param {import("../../modules/navigation/store/types").Modality} routingModality
	 * @returns {Record<string, {sectionName: string, entries: Array<MenuEntryData>}>}
	 */
	function calculateMenus(magic, maxAreas, area, loggedIn, routingModality) {
		const baseUrl = getBaseUrl();

		/** @type {Record<string, {sectionName: string, entries: Array<MenuEntryData>}>} */
		const sections = {};

		(magic.topics || []).forEach(
			({pageName, menu, pageUri, maxArea, authorization, hidden, noArea, type}) => {
				if (hidden !== true && (authorization !== true || loggedIn)) {
					const section = sections[menu] || {sectionName: menu, entries: []};
					sections[menu] = section;
					const hrefBase = `${baseUrl}${noArea ? '' : areaForTopic(maxAreas, maxArea, area.uri) + '/'}`;
					const hrefAppendix = type === SPECIAL_PAGE_NAVIGATION ? `/${NAVIGATION_URIS[routingModality]}` : '';
					section.entries.push({
						name: pageName,
						menu,
						href: `${hrefBase}${pageUri}${hrefAppendix}`,
					});
				}
			}
		);

		(magic.additionalContent || []).forEach(
			({pageName, menu, url, authorization, hidden}) => {
				if (
					hidden !== true && (authorization !== true || loggedIn)
					// Typo3-Anbindung ignoriert den Schalter "Im Menü anzeigen", daher hier hart kodiert:
					&& !Object.values(CONTENT_NAVAPP_URLS).includes(url.replace(/\/$/, ''))
				) {
					const section = sections[menu] || {sectionName: menu, entries: []};
					sections[menu] = section;
					section.entries.push({
						name: pageName,
						menu,
						href: `${baseUrl}${url}`,
					});
				}
			}
		);

		return sections;
	}
);

const Sections = memo(
	/**
	 * @param {{
	 *   open: boolean | null | undefined
	 * }} props
	 */
	function Sections({open}) {
		const magic = useMagic();
		const {area} = useNavPos();
		const {maxAreas} = useMemo(
			() => calculateMaxAreas(magic.areas),
			[magic.areas]
		);
		const loggedIn = useLoginState();
		const routingModality = useSelector(modalitySelector);

		const menuData = calculateMenus(magic, maxAreas, area, loggedIn, routingModality);
		const sections = Object.values(menuData).map(({sectionName, entries}) => (
			<li
				key={sectionName}
				className="vmznds-menu__section"
			>
				<span className="vmznds-menu__section-name">
					{sectionName}
				</span>

				<ul className="vmznds-menu__section-list">
					{entries.map(({href, name}) => (
						<Entry
							key={href}
							href={href}
							name={name}
							open={open}
						/>
					))}
				</ul>
			</li>
		));

		return (
			<Fragment>
				{sections}
			</Fragment>
		);
	}
);

export default memo(
	/**
	 * @param {{}} props
	 */
	function Menu({buttonRef}) {
		const baseUrl = getBaseUrl();
		const open = useSelector(navOpenSelector);
		const {area, areaFound} = useNavPos();

		const moveFocus = useCallback(
			() => buttonRef.current && buttonRef.current.focus(),
			[buttonRef]
		);

		return (
			<nav
				tabIndex={-1}
				aria-hidden={!open ? true : undefined}
				className={modClasses('vmznds-menu', {open})}
				id="vmznds-menu-nav"
			>
				<ul className="vmznds-menu__list">
					<Entry href={baseUrl + (areaFound?`${area.uri}/`:'')} name="Startseite" open={open} />
					<Sections open={open} />
				</ul>
				<button aria-hidden={'true'} onFocus={moveFocus} className="visuallyhidden" tabIndex={!open ? -1 : undefined} />
			</nav>
		);
	}
);
