import React, {memo} from 'react';
import {useLocation} from 'react-router';

import {translate} from '@mapsight/ui/src/js/helpers/i18n';
import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';

import QrCode from '../../qr-code';

import {appendQuerySting, getBaseUrl} from '../../../mapsight-vmznds/routing/helpers';

import Link from '../../../mapsight-vmznds/components/link';
import {genUrlParams} from '../store/api/helpers';

// keys are valid values for TripsState.service, ie. import("../store/types").RoutingService
export const CONTENT_NAVAPP_URLS = {
	nunav: 'wissenswertes/nunav-app',
	//efa: 'wissenswertes/gvh-app',
	bikeCitizens: 'wissenswertes/bikecitizens-app',
	//openrouteservice: null,
};

const CALC_APP_URL = {
	nunav: calcNunavUrl,
	bikeCitizens: calcBikecitizensUrl,
};


/**
 * returns nunav-Link if data attribute is found in description
 *
 * @param {string} description haystack to be searched, `properties.description` of feature
 * @return {string|null}
 */
export function readNunavDataLink(description) {
	const nunavMatches = /data-navigation-link="([^"]*)"/.exec(description);
	return nunavMatches && nunavMatches[1] ? nunavMatches[1] : null;
}

/**
 *
 * @type {React.NamedExoticComponent<{
 *   nunavLink?: string,
 *   name?: string,
 *   service?: import("../store/types").RoutingService,
 *   origin?: import("../store/types").Location,
 *   dest?: import("../store/types").Location,
 *   withQr?: boolean
 * }>}
 */
export const NavAppLink = memo(
	function NavAppLink({name, nunavLink, origin, dest, withQr, service = 'nunav'}) {
		if(!CONTENT_NAVAPP_URLS[service]) {
			return null;
		}

		const qrLink = withQr && !!CALC_APP_URL[service] && CALC_APP_URL[service](origin, dest, name, nunavLink);

		return (
			<div className={modClasses('vmznds-navapp-link', [service])} >
				<p>
					{translate(`navigation.qr.link-message.${service}`)}<br />
					<Link className="vmznds-list__more-link" to={{
						pathname: getBaseUrl() + CONTENT_NAVAPP_URLS[service],
						state: {
							navApp: {name, nunavLink, origin, dest, service}
						}
					}}>
						Mehr erfahren ...
					</Link>
				</p>
				{qrLink &&
					<QrCode value={qrLink} size={128} />
				}
			</div>
		);
	}
);

const NavQR = memo(
	function Nunav() {
		const location=useLocation();

		if(!location.state || !location.state.navApp) {
			// console.warn('NavQr missing location.state.navApp');
			return  null;
		}

		// eslint-disable-next-line prefer-const
		let {name, nunavLink, origin, dest, service} = location.state.navApp;
		const link = CALC_APP_URL[service] &&  CALC_APP_URL[service](origin, dest, name, nunavLink);

		// console.log('NavQr', {link, cond: !!link, name, nunavLink, origin, dest, service, calc: CALC_APP_URL[service]});

		// TODO more translations
		if(link) {
			return (
				<div className={`vmznds vmznds-qr-link vmznds-qr-link__${service}`}>
					<p>
						<b>{translate(`navigation.qr.name.${service}`)}-App {name ? `für ${name}${name.includes(',') ? ',' : ''}` : ''} aufrufen
							und QR-Code scannen</b>

						<a href={link} rel="nofollow" aria-hidden="true" tabIndex="-1">
							<QrCode value={link} />
						</a>

						<a href={link} rel="nofollow">
							Bei installierter App: zu {translate(`navigation.qr.name.${service}`)} wechseln und Ziel
							übernehmen ...
						</a>
					</p>
				</div>
			);
		}
		return null;
	}
);

export default NavQR;


function calcNunavUrl(origin, dest, name, nunavLink) {
	if(!nunavLink) {
		const {lat, lon} = dest?.location || {};
		if(lat && lon) {
			nunavLink = `https://www.nunav.to/destination?latitude=${encodeURIComponent(lat)}&longitude=${encodeURIComponent(lon)}`;
			if(name) {
				nunavLink = appendQuerySting(nunavLink, `label=${encodeURIComponent(name)}`);
			}
		}
	}
	return nunavLink;
}

function calcBikecitizensUrl(origin, dest) {
	let urlParams = null;
	const originLoc = origin?.location || {};
	const destLoc = dest?.location || {};
	if (destLoc?.lat && destLoc?.lon) {
		if (originLoc?.lat && originLoc?.lon) {
			urlParams = {
				startLat: originLoc.lat,
				startLon: originLoc.lon,
				destLat: destLoc.lat,
				destLon: destLoc.lon,
			};
		}
		// ja, die Parameter heißen bei BikeCitizens unterschiedlich, je nachdem ob es noch andere gibt ...
		urlParams = {
			lat: destLoc.lat,
			lon: destLoc.lon
		};
	}
	return urlParams && `https://bikecitizens.net/app-link/navi?${genUrlParams(urlParams)}`;
}
