import React, {memo, useCallback, useContext} from 'react';
import {useSelector} from 'react-redux';

import {detailsSelector, isPageContent, specialPageSelector} from '../store/selectors';
import {useNavPos} from '../hooks';
import {RefsContext} from '../contexts';



const SkipLink = memo(function ({}) {
	const navPos = useNavPos();
	const details = useSelector(detailsSelector);
	const specialPage = useSelector(specialPageSelector);
	const pageContent = isPageContent(specialPage) || navPos.error404;

	const {mapsightContentRef, pageContentRef, mobilePageContentRef} = useContext(RefsContext);

	const onSkipToContent = useCallback(
		(ev) => {
			const target =
				mapsightContentRef.current ?
					mapsightContentRef.current :
					pageContentRef.current ?
						pageContentRef.current :
						mobilePageContentRef.current;

			// console.log('skip-link onSkipToContent', {
			// 	details,
			// 	contentSkipRef_value: mapsightContentRef.current,
			// 	contentPageRef_value: pageContentRef.current,
			// 	contentMobilePageRef_value: mobilePageContentRef.current,
			// 	target,
			// });

			if(target) {
				target.focus();
			}
			ev.preventDefault();
		},
		[mapsightContentRef, pageContentRef, mobilePageContentRef]
	);

	if (
			(details && mapsightContentRef.current) ||
			(pageContent && (pageContentRef.current || mobilePageContentRef.current))
		) {
		return (
			<a className="vmznds-skip-link visuallyhidden focusable"
			   href="#vmznds-main"
			   onClick={onSkipToContent}
			>
				Springe direkt zum Inhalt
			</a>
		);
	}

	if (navPos.topic?.uri && navPos.topic.pageName && !details && !pageContent && mapsightContentRef.current) {
		return (
		<a className="vmznds-skip-link visuallyhidden focusable"
		   href="#vmznds-main"
		   onClick={onSkipToContent}
		>
			{`Springe direkt zum Inhalt. Das ist eine Liste zum Thema ${navPos.topic.pageName}.`}
		</a>
		);
	}

	return null;
});

export default SkipLink;
