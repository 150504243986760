import escapeCssName from '@neonaut/lib-js/es/string/escapeCssName';

import {translate} from '@mapsight/ui/src/js/helpers/i18n';


const formatEntry = (title, legend) => (title ? `<h2>${title}</h2>` : '') + `<div class="vmznds-legend__${escapeCssName(title)}">${legend}</div>`;

export const PREROUTING_LEGEND = '<div class="vmznds-legend vmznds-legend--prerouting">' +
	'<span><img class="vmznds-legend__icon" src="/assets/img/mapsight-icons-svg/flag-start-xsmall.svg"/> Start </span> ' +
	'<span><img class="vmznds-legend__icon" src="/assets/img/mapsight-icons-svg/flag-finish-xsmall.svg"/> Ziel </span> ' +
	'<span><span class="vmznds-legend__icon vmznds-legend__icon--prerouting"></span> Route</span> ' +
	'</div>';

export const LOS_LEGEND = '<div class="vmznds-legend vmznds-legend--los">' +
	'<span><span class="vmznds-legend__icon--los vmznds-legend__icon--los--free"></span><span> frei </span></span> ' +
	'<span><span class="vmznds-legend__icon--los vmznds-legend__icon--los--tough"></span><span> zähfließend </span></span> ' +
	'<span><span class="vmznds-legend__icon--los vmznds-legend__icon--los--jam"></span><span> stockend, Stau</span></span> ' +
	'</div>';

export const ANOMALIES_LEGEND = '<div class="vmznds-legend vmznds-legend--anomalies">' +
	'<span><span class="vmznds-legend--anomalies__sign"> +5</span> Verzögerungszeit </span> ' +
	'<span><span class="vmznds-legend--anomalies__sign"> ▼</span> Tendenz fallend </span> ' +
	'<span><span class="vmznds-legend--anomalies__sign"> ▲</span> Tendenz steigend</span> ' +
	'</div>';

export const BASE_LEGEND = '<div class="vmznds-legend vmznds-legend--base">' +
	'<span><span class="vmznds-legend__icon vmznds-legend__icon--base vmznds-legend__icon--base--a"></span> Autobahn </span> ' +
	'<span><span class="vmznds-legend__icon vmznds-legend__icon--base vmznds-legend__icon--base--b"></span> Bundesstr. </span> ' +
	'<span><span class="vmznds-legend__icon vmznds-legend__icon--base vmznds-legend__icon--base--l"></span> Landes- oder Kreisstr. </span>' +
	'</div>';


export const PARKEN_OHNE_PUNDR_ICONS =
	'<span><img class="vmznds-legend__icon" src="/assets/img/mapsight-icons-svg/parkflaeche-default.svg"/> Parkplatz </span> ' +
	'<span><img class="vmznds-legend__icon" src="/assets/img/mapsight-icons-svg/parkhaus-default.svg"/> Parkhaus </span> ' +
	'<span><img class="vmznds-legend__icon" src="/assets/img/mapsight-icons-svg/pundr-default.svg"/> P+R-Anlage</span> ';
// Achtung: Diese Legende muss ohne die Icons für Parkplatz und Parkhaus und ohne die Zeile für die Tendenz
// Hinweis: Die Konsole auf Firefox macht ohnehin ein JSON.stringify, dort nur den String ausführen
// JSON.stringify(
// 	'<div class="vmznds-legend vmznds-legend--parking">' +
// 	'<span><img class="vmznds-legend__icon" src="/assets/img/mapsight-icons-svg/pundr-plain.svg"/> P+R-Anlage, </span>' +
// 	'<span>' +
// 	'Auslastung: ' +
// 	'<img class="vmznds-legend__icon vmznds-legend__icon--pundr" src="/assets/img/mapsight-icons-svg/pundr-plain.svg">' +
// 	'<img class="vmznds-legend__icon vmznds-legend__icon--occupation" src="/assets/img/parking-dynamic/constant_40.png">' +
// 	' gering </span>' +
// 	'<span>' +
// 	'<img class="vmznds-legend__icon vmznds-legend__icon--pundr" src="/assets/img/mapsight-icons-svg/pundr-plain.svg">' +
// 	'<img class="vmznds-legend__icon vmznds-legend__icon--occupation" src="/assets/img/parking-dynamic/constant_80.png">' +
// 	' hoch </span>' +
// 	'<span>' +
// 	'<img class="vmznds-legend__icon vmznds-legend__icon--pundr" src="/assets/img/mapsight-icons-svg/pundr-plain.svg">' +
// 	'<img class="vmznds-legend__icon vmznds-legend__icon--occupation" src="/assets/img/parking-dynamic/constant_90.png">' +
// 	' voll </span>' +
// 	'</div>'
// );


// durch JSON.stringify konvertiert werden und der String dann in Typo3 zusätzlich als .miniLegend des topic eingetragen werden
export const PARKEN_MIT_PUNDR_LEGEND = '<div class="vmznds-legend vmznds-legend--parking">' +
	PARKEN_OHNE_PUNDR_ICONS + '<br />' +
	// Zeile Auslastung
	'<h3>Auslastung</h3>' +
	'<span>' +
	'<img class="vmznds-legend__icon vmznds-legend__icon--pundr" src="/assets/img/mapsight-icons-svg/pundr-default.svg">' +
	'<img class="vmznds-legend__icon vmznds-legend__icon--occupation" src="/assets/img/parking-dynamic/constant_40.png">' +
	' gering </span>' +
	' <span>' +
	'<img class="vmznds-legend__icon vmznds-legend__icon--pundr" src="/assets/img/mapsight-icons-svg/pundr-default.svg">' +
	'<img class="vmznds-legend__icon vmznds-legend__icon--occupation" src="/assets/img/parking-dynamic/constant_80.png">' +
	' hoch </span>' +
	' <span>' +
	'<img class="vmznds-legend__icon vmznds-legend__icon--pundr" src="/assets/img/mapsight-icons-svg/pundr-default.svg">' +
	'<img class="vmznds-legend__icon vmznds-legend__icon--occupation" src="/assets/img/parking-dynamic/constant_90.png">' +
	' voll</span>' +
	// Zeile Tendenz
	'<br/><h3>Tendenz</h3>' +
	'<span>' +
	'<img class="vmznds-legend__icon vmznds-legend__icon--pundr" src="/assets/img/mapsight-icons-svg/pundr-default.svg">' +
	'<img class="vmznds-legend__icon vmznds-legend__icon--occupation" src="/assets/img/parking-dynamic/decreasing_40.png">' +
	' sinkend </span>' +
	' <span>' +
	'<img class="vmznds-legend__icon vmznds-legend__icon--pundr" src="/assets/img/mapsight-icons-svg/pundr-default.svg">' +
	'<img class="vmznds-legend__icon vmznds-legend__icon--occupation" src="/assets/img/parking-dynamic/constant_40.png">' +
	' gleichbleibend </span>' +
	' <span>' +
	'<img class="vmznds-legend__icon vmznds-legend__icon--pundr" src="/assets/img/mapsight-icons-svg/pundr-default.svg">' +
	'<img class="vmznds-legend__icon vmznds-legend__icon--occupation" src="/assets/img/parking-dynamic/increasing_40.png">' +
	' steigend</span>' +
	// Abschluss des äußern divs
	'</div>';


export const FURTHER_LEGEND = '<div class="vmznds-legend">' +
	'<span><img class="vmznds-legend__icon" src="/assets/img/mapsight-icons-svg/meldung-default.svg"/>div. Verkehrszeichen:</span> <span>Baustellen- oder Verkehrsmeldung</span><br/>' +
	'<span><img class="vmznds-legend__icon" src="/assets/img/mapsight-icons-svg/schule-default.svg"/> Schulausfall </span> ' +
	'<span><img class="vmznds-legend__icon" src="/assets/img/mapsight-icons-svg/cam-default.svg"/> Vekehrskamera</span> ';

const completeLegend = (magic) => (
	// TODO legende in magic unterstützen
	`<article aria-label="${translate('ui.map-overlay.info.legend')}">` +
	`<h1>${translate('ui.map.usage.heading')}</h1>` +
	formatEntry(null, `<div class="vmznds-legend">${translate('ui.map.usage.html')}</div>`) +
	'<h1>Legende</h1>' +
	formatEntry('Grundkarte', BASE_LEGEND) +
	formatEntry('Verkehrsfluss', LOS_LEGEND) +
	formatEntry('Verzögerungen', ANOMALIES_LEGEND) +
	formatEntry('Routenplaner', PREROUTING_LEGEND) +
	formatEntry('Parken', PARKEN_MIT_PUNDR_LEGEND) +
	formatEntry('Weitere Kartenebenen', FURTHER_LEGEND) +
	'<br></article>'
);

export default completeLegend;
