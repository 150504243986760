import React, {Fragment, memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';
import {batchActions} from 'redux-batched-actions';

import {deselectAll} from '@mapsight/core/lib/feature-selections/actions';
import {updateMapSize} from '@mapsight/core/lib/map/actions';

import {VIEW_MAP_ONLY, VIEW_MOBILE} from '@mapsight/ui/src/js/config/constants/app';
import {FEATURE_SELECTIONS} from '@mapsight/ui/src/js/config/constants/controllers';
import {FEATURE_SELECTION_PRESELECT, FEATURE_SELECTION_SELECT} from '@mapsight/ui/src/js/config/feature/selections';

import {setView, toggleUserPreferenceListVisible} from '@mapsight/ui/src/js/store/actions';
import {isViewMobile, userPreferenceListVisibleSelector, viewSelector} from '@mapsight/ui/src/js/store/selectors';

import usePrevious from '@mapsight/ui/src/js/hooks/usePrevious';
import ListToggleButton from '@mapsight/ui/src/js/components/main-panel/list-toggle-button';

import {deselectAllViaHistory} from '../store/actions';
import {detailsSelector} from '../store/selectors';
import {useNavPos} from '../hooks';

import ReducedButton from './reduced-button';

// we replace the real button with a fake one to play our transition but get it really disabled not only invisible
const FAKEBUTTON = '<div class="ms3-layer-switcher-overlay "><button disabled type="button" class="ms3-map-overlay__button ms3-map-overlay__button--with-icon ms3-map-overlay__button--layers"><span class="ms3-map-overlay__button__label"><span aria-hidden="true">Ebenen</span><span class="visuallyhidden">Ebenenauswahl öffnen</span></span></button><div class="ms3-layer-switcher-placeholder"></div></div>';

const listVisibleSelector = createSelector(
	userPreferenceListVisibleSelector,
	viewSelector,
	(userPrefListVisible, view) => {
		if (isViewMobile(view)) {
			return view === VIEW_MOBILE;
		} else {
			return !!userPrefListVisible;
		}
	},
);

/** @type {import("@mapsight/ui/src/js/components/contexts").MapOverlayTopRight} */
const MapOverlayTopRight = memo(function MapOverlayTopRight({children}) {
	const dispatch = useDispatch();
	const navPos = useNavPos();
	const listVisible = useSelector(listVisibleSelector);
	const view = useSelector(viewSelector);
	const details = useSelector(detailsSelector);

	const toggleList = useCallback(
		() => {
			if (view === VIEW_MOBILE) {
				dispatch(setView(VIEW_MAP_ONLY));
			} else if (view === VIEW_MAP_ONLY) {
				const actions = [
					deselectAllViaHistory(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT),
					setView(VIEW_MOBILE),
				];
				if (!details) {
					actions.push(deselectAll(FEATURE_SELECTIONS, FEATURE_SELECTION_PRESELECT));
				}

				if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
					console.log('toogleButton from mapOnly -> mobile', batchActions(actions));
				}
				dispatch(batchActions(actions));
			} else {
				dispatch(batchActions([
					toggleUserPreferenceListVisible(),
					deselectAllViaHistory(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT),
				]));
			}
		},
		[dispatch, view, details],
	);

	const prevListVisible = usePrevious(listVisible);
	useEffect(function () {
		if (prevListVisible !== listVisible) {
			dispatch(updateMapSize('map', {
				from: 'left',
				to: 'left',
				reCenter: true,
			}));
		}
	}, [prevListVisible, listVisible, dispatch]);

	return (
		<Fragment>
			<div className="vmznds-map-overlay-top-right__list-toggle-button-wrapper">
				<div className="ms3-map-overlay__button ms3-map-overlay">
					<ListToggleButton active={!listVisible} onClick={toggleList} />
				</div>
				<div className="ms3-map-overlay__button ms3-map-overlay">
					<ReducedButton />
				</div>
			</div>
			{(!navPos.topic?.noArea) &&
				<div className={`effecthideable ${navPos.reduced ? 'effecthidden' : ''}`} aria-hidden={navPos.reduced}
					 dangerouslySetInnerHTML={navPos.reduced ? {__html: FAKEBUTTON} : undefined}
					 children={navPos.reduced ? undefined : children}
				/>
			}
		</Fragment>
	);
});

export default MapOverlayTopRight;
