import React, {memo, useCallback} from 'react';
import {batchActions} from 'redux-batched-actions';
import {useDispatch, useSelector} from 'react-redux';

import {deselectAll} from '@mapsight/core/lib/feature-selections/actions';

import {viewSelector} from '@mapsight/ui/src/js/store/selectors';
import {setView} from '@mapsight/ui/src/js/store/actions';
import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';
import {VIEW_FULLSCREEN, VIEW_MAP_ONLY, VIEW_MOBILE} from '@mapsight/ui/src/js/config/constants/app';
import {FEATURE_SELECTION_PRESELECT, FEATURE_SELECTION_SELECT} from '@mapsight/ui/src/js/config/feature/selections';
import {FEATURE_SELECTIONS} from '@mapsight/ui/src/js/config/constants/controllers';

import {getBaseUrl} from '../routing/helpers';
import {deselectAllViaHistory, setListPanelIsVisible, setNavigationOpen} from '../store/actions';
import {useNavPos} from '../hooks';

import Link from './link';

const Logo = memo(
	/**
	 * @param {{small?: boolean}} props
	 */
	function Logo({
		small = false,
	}) {
	const dispatch = useDispatch();
	const {area, areaFound, topic, content, sitemap} = useNavPos();
	const baseUrl = getBaseUrl();
	const view = useSelector(viewSelector);

	const startPage = !(topic && topic.uri) && !content && !sitemap;

	const onClick = useCallback(
		() => {
			const actions = [
				setNavigationOpen(false),
				deselectAll(FEATURE_SELECTIONS, FEATURE_SELECTION_PRESELECT),
				deselectAllViaHistory(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT),
			];
			if(view === VIEW_MAP_ONLY) {
				actions.unshift(setView(VIEW_MOBILE));
			} else if(small && view === VIEW_FULLSCREEN) {
				actions.unshift(setListPanelIsVisible(true));
			}
			dispatch(batchActions(actions));
		},
		[dispatch, view, small]
	);

	const T = startPage ? 'span' : Link;
	const params = startPage ? {} : {
		to: `${baseUrl}${areaFound?area.uri+'/':''}`
	};

	return (
		<div
			className={modClasses('vmznds-logo', small && 'small-over-map')}
			onClick={onClick}
		>
			<T
				className="vmznds-logo__link"
				{...params}
			>
				<span className="visuallyhidden">zur Startseite der Verkehrsmanagementzentrale Niedersachsen und Region Hannover</span>
				<span className="vmznds-logo__img" />
			</T>
		</div>
	);
});

export default Logo;
