import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {getGeolocation} from '@mapsight/core/lib/user-geolocation/actions';
import {geolocationStatusSelector} from '@mapsight/core/lib/user-geolocation/selectors';

import {listSortingSelector, placesSelector} from '@mapsight/ui/src/js/store/selectors';
import {sortList} from '@mapsight/ui/src/js/store/actions';
import {USER_GEOLOCATION} from '@mapsight/ui/src/js/config/constants/controllers';

import FeatureSorter from './feature-list-sorting';

export default connect(
	createStructuredSelector({
		sorting: listSortingSelector,
		places: placesSelector,
		geolocationStatus: state => geolocationStatusSelector(state[USER_GEOLOCATION]),
	}),
	// dispatch map:
	{
		onChange: sortList,
		requestGeolocation: getGeolocation,
	}
)(FeatureSorter);
