import React, {memo, Fragment} from 'react';

/**
 * @type {import("@mapsight/ui/src/js/components/contexts.js").FeatureDetailsContent}
 */
const FeatureDetailsContentAnomaly = memo(
	function FeatureDetailsContentAnomaly({feature}) {
		if (typeof feature !== 'object' || feature === null) {
			return null;
		}
		const props = feature.properties;
		if (typeof props !== 'object' || props === null) {
			return null;
		}

		return (
			<div className="ms3-feature-details-content ms3-feature-details-content--description">
				<span className="ms3-list__icon">
					<img
						src="/assets/img/mapsight-icons-svg/stau-default.svg"
						alt=""
					/>
				</span>
				<article className="vmznds-list__item vmznds-situation--tic">
					<span className="ms3-list__main">
						<header>
							<h1 className="vmznds-situation--tic__road-number">
								{typeof props.roadName === 'string' && (
									<Fragment>
										{props.roadName}
										{' '}
									</Fragment>
								)}

								<div
									className="vmznds-situation--tic__shortDesc vmznds-state vmznds-state--1"
								>
									Verzögerung
								</div>
							</h1>

							{typeof props.minsLost === 'number' && (
								<Fragment>
									<h2 className="vmznds-situation--tic__direction">
										ca. +{props.minsLost} Minuten
									</h2>
								</Fragment>
							)}
						</header>
					</span>

					<p>
						{'Tendenz: '}
						{(() => {
							const minsLostDelta = Number.parseInt(props.minsLostDelta, 10);
							if (isNaN(minsLostDelta)) {
								return '?';
							} else if (minsLostDelta > 0) {
								return 'steigend';
							} else if (minsLostDelta < 0) {
								return 'fallend';
							} else {
								return 'gleichbleibend';
							}
						})()}

						<br />

						{'Geschwindigkeit: ca. '}
						{(() => {
							const avgSpeedMps = Number.parseInt(props.avgSpeedMps, 10);
							if (isNaN(avgSpeedMps)) {
								return '?';
							} else {
								return Math.round(avgSpeedMps / 1000 * 3600);
							}
						})()}
						{' km/h'}
					</p>

					{(props.creationDatetime && props.creationDatetimeFmt && (
						<h3 className="vmznds-situation--tic__subheading">
							{'Beginn: '}
							<time
								className="vmznds-situation--tic__datetime"
								dateTime={props.creationDatetime}
							>
								{props.creationDatetimeFmt}
							</time>
						</h3>
					))}
				</article>
			</div>
		);
	}
);

export default FeatureDetailsContentAnomaly;
