import React, {memo} from 'react';

import {calculateMaxAreas, getBaseUrl, recommendedArea} from '../../routing/helpers';
import {useMagic, useNavPos} from '../../hooks';

import Link from '../link';


export default memo(
	/**
	 * @param {{
	 *   totalCount?: number,
	 *   topic: object,
	 * }} props
	 */
	function ListSummaryInner({totalCount, topic: topic}) {
		const baseUrl = getBaseUrl();
		const {area: navArea, topic: navTopic, error404, content} = useNavPos();
		const {maxAreas} = calculateMaxAreas(useMagic().areas);

		const startPage = !(navTopic && navTopic.uri) && !content;
		const tabIndex = (error404 || startPage) ? -1 : undefined;

		if (!topic || !topic.pageUri) {
			topic = navTopic;
		}
		if (!topic || !topic.pageUri) {
			topic = {name: 'Verkehrslage', pageUri: 'verkehrslage'};
		}

		const areaUri = recommendedArea(maxAreas, topic.maxArea, navArea.uri);

		const page = (topic.pageName || topic.name) ?
			(topic.pageName || topic.name) + ' ' : '';

		return (
			<Link
				tabIndex={tabIndex}
				aria-hidden="true"
				className="vmznds-list__more-link"
				to={`${baseUrl}${topic.noArea ? '' : `${areaUri}/`}${topic.pageUri}`}
			>
				{typeof totalCount === 'number' ?
					(totalCount === 1) ? 'Zeige eine Meldung an ...' : `Zeige alle ${totalCount} an ...` :
					`Zur Seite ${page} ...`
				}
			</Link>
		);
	}
);
