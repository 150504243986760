import * as uiConfig from '@mapsight/ui/config';
import createFeatureInteractions from '@mapsight/ui/src/js/config/map/featureInteractions';

import {DRAW_INTERACTION, MODIFY_INTERACTION, ROUTE_STOPS_LAYER} from '../../modules/navigation/store/constants';

import featureSources from './feature-sources';
import mapLayers from './map-layers';
import mapView from './map-view';



export default function configIndex(magic, locPathname, locState) {
	const config = Object.assign(
		// mapView will be set later by routing.jsx
		uiConfig.map(
			mapLayers(magic), mapView, undefined, true,
			createFeatureInteractions({deselectUncontrolledOnClick: ['select']})
		),
		uiConfig.features(featureSources(magic, locPathname, locState)),
		uiConfig.featureList(null, true), // feature source is not known yet. will be set in routing.jsx
	);
	config.map.interactions = {
		...config.map.interactions,
		[DRAW_INTERACTION]: {
			type: 'Draw',
			options: {
				active: false,
				type: 'Point',
				style: 'graphmastersRouteStopsDraw',
				source: {
					type: 'VectorFeatureSource',
					options: {
						projection: 'EPSG:4326',
						featureSourceId: ROUTE_STOPS_LAYER,
						featureSourcesControllerName: 'featureSources',
						featureSelectionsControllerName: 'featureSelections',
						keepFeaturesInViewSelections: [],
						fitFeaturesInViewSelections: [],
						canAnimate: false,
						canCluster: false,
						useSelectionOverlay: false,
					},
				},
			},
		},
		[MODIFY_INTERACTION]: {
			type: 'Modify',
			options: {
				active: false,
				pixelTolerance: 26,
				style: 'draw',
				source: {
					type: 'VectorFeatureSource',
					options: {
						projection: 'EPSG:4326',
						featureSourceId: ROUTE_STOPS_LAYER,
						featureSourcesControllerName: 'featureSources',
						featureSelectionsControllerName: 'featureSelections',
						keepFeaturesInViewSelections: [],
						fitFeaturesInViewSelections: [],
						canAnimate: false,
						canCluster: false,
						useSelectionOverlay: false,
					},
				},
			},
		},
	};

	return config;
}
