import invert from 'lodash/invert';

export const NAVIGATION = 'navigation';
export const ROUTE_STOPS_LAYER = 'graphmastersRouteStops';
export const DRAW_INTERACTION = ROUTE_STOPS_LAYER + '_draw';
export const MODIFY_INTERACTION = ROUTE_STOPS_LAYER + '_modify';

// TODO keep in sync with vector-style.scss
export const ROUTE_CAR_LAYER = 'graphmastersRoute';
export const ROUTE_BIKE_LAYER = 'bikeRoute';
export const ROUTE_PUBLIC_LAYER = 'publicRoute';

export const ROUTE_LAYERS = {
	car: ROUTE_CAR_LAYER,
	bike: ROUTE_BIKE_LAYER,
	public: ROUTE_PUBLIC_LAYER
};

export const NAVIGATION_URIS = {
	car: 'pkw',
	bike: 'fahrrad',
	public: 'oepnv'
};
export const NAVIGATION_URI_MODALITIES = invert(NAVIGATION_URIS);

// we fetch other modality to let the user compare: what routes to fetch for which modality
// x → x must be set to true
export const ROUTE_FETCH_MATRIX = {
	car: {car: true, bike: true, public: true},
	bike: {car: false, bike: true, public: true},
	public: {car: false, bike: true, public: true},
};
