import getPath from '@neonaut/lib-js/es/object/getPath';
import {plain, withFilter, xhrJson, xhrJsonRefreshing} from '@mapsight/ui/config/feature-sources';

import {calculateMaxAreas, contentUrlToId, getBaseUrl, navPosition, PREVIEW_FEATURE_SOURCE_ID} from '../routing/helpers';
import {
	ROUTE_BIKE_LAYER,
	ROUTE_CAR_LAYER,
	ROUTE_PUBLIC_LAYER,
	ROUTE_STOPS_LAYER
} from '../../modules/navigation/store/constants';

import {SPECIAL_PAGE_NAVIGATION, SPECIAL_PAGE_URGENT_RED, URGENT_PAGES} from '../store/controller';

import {AUTHORIZATION_SOURCE_ID} from './map-layers';

// move this to config/feature/sources.js in @mapsight/ui
// currently only used by our feature-details-summary
export function withName(featureSource, featureSourceName) {
	return {
		...featureSource,
		name: featureSourceName
	};
}

// TODO kein refresh für Content-Seiten die durch additionalContent entstanden

const topicSource = (topic, topicArea) => withName(withFilter(
	xhrJsonRefreshing(
		(topic.url ?
				topic.url : // not appendQuerySting(topic.url, `vmz-area=${topicArea}`) as a queryString will break CDN and vmz-area isn't currently supported anywhere
				topic.noArea ? `/geojson/${topic.uri}.geojson` : `/geojson/${topicArea}-${topic.uri}.geojson`
		),
		SPECIAL_PAGE_URGENT_RED === topic.type ? 60 * 1000 :
			(URGENT_PAGES.includes(topic.type) || topic.uri === 'park-and-ride2' ? 5 * 60 * 1000 :
					10 * 60 * 1000
			)
	),
	'tagFilter'),
	topic.pageName ? topic.pageName : topic.name
);


export default function configFeatureSources(magic, locPathName, locState) {
	const baseUrl = getBaseUrl();
	const navPos = locPathName ? navPosition(magic, locPathName, locState) : null;
	const sources = {};

	const previewUrl = getPath(navPos, ['content', 'preview']);
	if (previewUrl) {
		sources[PREVIEW_FEATURE_SOURCE_ID] = xhrJson(previewUrl);
	}

	const {maxAreas, areasList} = calculateMaxAreas(magic.areas);

	magic.topics.forEach(topic => {
		if (topic.noArea) {
			sources[topic.uri] = topicSource(topic, undefined);
		} else {
			const topicAreas = topic.maxArea && maxAreas[topic.maxArea] ? maxAreas[topic.maxArea] : areasList;
			topicAreas.forEach(topicArea => {
				if (topic.type !== SPECIAL_PAGE_NAVIGATION) {
					if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
						console.log(`${topicArea}-${topic.uri}`, topicArea);
					}

					sources[`${topicArea}-${topic.uri}`] = topicSource(topic, topicArea);
				}
			});
		}
	});

	magic.additionalContent.forEach(content => {
		sources[contentUrlToId(content.url)] = xhrJson(`${baseUrl}${content.url.replace(/\/$/, '')}.geojson`);
	});

	if (magic.authorization && magic.authorization.url) {
		sources[AUTHORIZATION_SOURCE_ID] = xhrJson(`${baseUrl}${magic.authorization.url.replace(/\/$/, '')}.geojson`);
	}

	Object.assign(sources, {
		'404': xhrJson(`${baseUrl}404.geojson`),  // eslint-disable-line quote-props
		userGeolocation: plain(),
		searchResult: plain(),
		[ROUTE_BIKE_LAYER]: plain(),
		[ROUTE_CAR_LAYER]: plain(),
		[ROUTE_PUBLIC_LAYER]: plain(),
		[ROUTE_STOPS_LAYER]: plain(),
		anomalies: withName(xhrJsonRefreshing('/geojson/niedersachsen-anomalies.geojson', 5 * 60 * 1000), 'Verkehrsfluss'),
	});

	if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
		console.warn('config_feature_sources', {magic, areas: areasList, maxAreas, sources});
	}
	return sources;
}
