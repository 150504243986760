import 'focus-visible/dist/focus-visible';

import './mapsight-vmznds';
import renderRefreshingImage from './modules/refreshing-image';

import './modules/photoswipe';

// This is needed by the webcam-admin page which is a regular html page directly created by typo3
/* Image refresher */
// TODO move this to app-admin.js
// TODO this code is also in mapsight-vmznds/index.js. have a look
[...document.querySelectorAll('img[data-refresh-interval]')].forEach(renderRefreshingImage);
