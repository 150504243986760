import memoizee from 'memoizee';
import merge from 'lodash/merge';

import {NAVIGATION_URIS} from '../../modules/navigation/store/constants';
import {CONTENT_NAVAPP_URLS} from '../../modules/navigation/components/qr-link';
import {CONTENT_SITEMAP_URL, SPECIAL_PAGE_NAVIGATION} from '../store/controller';


export const QS_REDUCED = 'reduziert';

export function appendQuerySting(url, qs) {
	return (url.includes('?') ? `${url}&${qs}` : `${url}?${qs}`);
}

// TODO move newURL to NNSIMPLEJS
export const newURL = l => {
	try {
		return new URL(l);
	// eslint-disable-next-line no-empty
	} catch(e) {
	}
	return null;
};

/** @type {null | string} */
let baseUrl = null;

/**
 * @returns {string}
 */
export function getBaseUrl() {
	if (baseUrl !== null) {
		return baseUrl;
	}

	// TODO: fix for SSR or certify '/' is ok
	baseUrl = '/';

	const location = typeof window !== 'undefined' && window ? window.location : undefined;
	if (location) {
		if (location.pathname.startsWith('/proto')) {
			// code for test sites
			baseUrl = '/proto/';
		}

		if (location.hostname.includes('cms.')) {
			if (location.hostname.startsWith('cms.')) {
				baseUrl = `${location.protocol}//${location.host.replace(/cms\./, 'www.')}${baseUrl}`;
			} else {
				baseUrl = `${location.protocol}//${location.host.replace(/cms\./, '.')}${baseUrl}`;
			}
		}
	}

	return baseUrl;
}

// this url has to fulfill PREVIEW_FEATURE_SOURCE_ID === contentUrlToId(PREVIEW_FEATURE_SOURCE_ID), ie. must not contain / or space
export const PREVIEW_FEATURE_SOURCE_ID = '_preview';
export const isPreview = () => (typeof window !== 'undefined' && (
		/preview=1/.test(window.location.search) ||
		/preview=preview/.test(window.location.search) ||
		/news_preview/.test(window.location.search) ||
		/cms/.test(window.location.hostname)
	)
);


// das ist eine Art selector auf die von react-router bereitgestellte location
// navPosition kann bei withRouter(connect()) beim connect in der mapStateToProps auf ownProps.location angewendet werden
const navPositionCache = {};
// TODO klären welche area für content-Seiten gilten, weil das vom Menü benötigt wird.
let lastArea = null;

/**
 * @param {import('../types').Magic} magic
 * @param {string} path
 * @param {any} state
 */
export function navPosition(magic, path, state) {
	if (navPositionCache.result && navPositionCache.magic === magic && navPositionCache.path === path && navPositionCache.isPreview === isPreview() && navPositionCache.state === state) {
		return navPositionCache.result;
	}
	navPositionCache.isPreview = isPreview();
	navPositionCache.magic = magic;
	navPositionCache.path = path;
	navPositionCache.state = state;

	const search = path.match(/\?([^#]*)/i);
	const reduced = !!(search && search[1] && new URLSearchParams(search[1]).has(QS_REDUCED));

	const pathname = path.match(/(^[^?#]*)/i)[1]
		.replace('/proto', '') // debug code
		.replace(/^\/+/, '')  // notwendig
		.replace(/\/+$/, '')  // notwendig
		.replace(/index.html$/, '');  // notwendig

	let area = lastArea ? lastArea
	: (magic.areas[2] && path.match(/navigation\/fahrrad/) ? magic.areas[2]
			: (magic.areas[1] ? magic.areas[1]
				: magic.areas[0] ? magic.areas[0]
					: {uri: 'niedersachsen'})); // configuration fault will lead to error below
	// content-Urls haben manchmal area

	let areaFound = false;
	const parts = (pathname === '' ? '/' : pathname.replace(/\/$/, '')).split('/', 4);

	// console.log('v- navPosition searching area', {pathname, parts, magic: magic ? magic : 'no magic', location: document.location});
	(magic.areas || []).forEach(element => {
		if (parts[0] === element.uri) {
			area = element;
			areaFound = true;
		}
		// console.log('+- navPosition searching area', element.uri, parts[0], area, areaFound, parts);
	});

	let stateAreaFound = false;
	if (!areaFound && state && state.area) {
		(magic.areas || []).forEach(element => {
			if (state.area === element.uri) {
				area = element;
				stateAreaFound = true;
			}
			// console.log('+- navPosition searching state area', {state.area, parts0: parts[0], area, areaFound, stateAreaFound, parts});
		});
	}

	let result = null;

	(magic.additionalContent || []).forEach(element => {
		if (
			(pathname.replace(/\/$/, '') === element.url.replace(/\/$/, '')) ||
			(element.deepContent && pathname.startsWith(element.url.replace(/\/$/, '')))
		) {
			result = element.noArea && areaFound ? null : {
				content: element,
				area,
				areaFound: areaFound || stateAreaFound,
				areaChanged: (areaFound || stateAreaFound) && area !== lastArea,
				navService: Object.values(CONTENT_NAVAPP_URLS).includes(element.url.replace(/\/$/, '')),
				reduced,
			};
		}
	});


	if (isPreview()) {
		result = merge(
			{
				content: {
					name: 'Vorschau',
					pageName: 'Vorschau'
				},
				area,
				areaFound: areaFound || stateAreaFound,
				areaChanged: (areaFound || stateAreaFound) && area !== lastArea,
				reduced,
			},
			result, // Take pageName and name from content if it is found in additionalContent
			{
				content: {
					preview: appendQuerySting(
						window.location.pathname.replace(/\/$/, '').replace(/\/\?/, '?') + window.location.search,
						'type=666'),
					url: PREVIEW_FEATURE_SOURCE_ID,
				}
			});
	}

	// TODO warnings (needs definition of url-schemes). needed: entries without menu property will not go to menu, do this accordingly for all topics and contentPages

	if(!result && pathname.replace(/\/$/, '') === CONTENT_SITEMAP_URL) {
		result = {
			sitemap: true,
			area,
			areaFound: areaFound || stateAreaFound,
			areaChanged: (areaFound || stateAreaFound) && area !== lastArea,
			reduced,
		};
	}

	if (!result) {
		let topic = null;
		// let hannover = undefined;
		//
		// hannover/ which chooser only for map pages with topic
		// TODO Herbert: die Gebietsschalter zeigen region-hannover nicht an, deshalb jetzt hier 404
		// if (parts[0] === 'hannover') {
		// 	hannover = true; // TODO use hannover  to show chooser beetween region or town
		// 	area = 'region-hannover';
		// 	areaFound = true;
		// }

		const topicIndex = areaFound ? 1 : 0; // make /topic work if url has not /area/topic
		(magic.topics || []).forEach(element => {
			if (
				parts[topicIndex] === element.pageUri &&
				(element.noArea !== true || !areaFound)  // if noArea is true and areaFound too, then this expression is false
			) {
				topic = element;
			}
		});

		// Test auf Verfügbarkeit der Kombination area/topic. → verschoben nach RouteToStore
		/** @var {boolean} error404 */
		const error404 =
			(!topic && !!parts[topicIndex]) ||
			(topic && !topic.deepLinks && !topic.deepContent && parts[topicIndex + 1]) || // unerlaubter deepLink
			(parts[topicIndex + 1] && topic?.type === SPECIAL_PAGE_NAVIGATION && !Object.values(NAVIGATION_URIS).includes(parts[topicIndex + 1])) || // keine gültige Modalität für Navigation
			(topic && !topic.deepContent && parts[topicIndex + 2]); // deepLink mit verbotenem extra /

		//TODO  für die Navigation deepLinks auf true setzen → bei error404 testen ob es die erlaubten modalitäten sind

		result = {
			area,
			areaFound: areaFound || stateAreaFound,
			areaChanged: (areaFound || stateAreaFound) && area !== lastArea,
			// hannover,
			topic: !error404 && topic ? topic : (area ? {uri: ''} : undefined),
			deepLink: !error404 && topic ? parts[topicIndex + 1] : undefined,
			error404: error404,
			content: error404 ? {url: '404', name: '404', menu: '404 — Seite nicht gefunden'} : undefined,
			reduced,
		};
	}

	if (result.topic && result.topic.deepContent && parts[areaFound ? 2 : 1]) {
		result = {
			...result,
			error404: false,
			content: {
				name: result.topic.name,
				pageName: result.topic.pageName,
				menu: result.topic.menu,
				hidden: true,
				preview: '/' + pathname.replace(/$/, '.geojson'),
				url: PREVIEW_FEATURE_SOURCE_ID,
			}
		};
	}

	if (
		!result.error404 && !result.deepLink && !result.content && !result.sitemap &&
		state?.select
	) {
		result = {
			...result,
			select: state.select,
		};
	}

	// if(newUrl) useHistory.replace ...

	if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
		console.log('navPosition', {lastArea, path, state, result, areaFound, stateAreaFound});
	}

	lastArea = result.area;
	navPositionCache.result = result;

	return result;
}

export const calculateMaxAreas = memoizee(function (areas) {
	const areasByUri = {};
	const maxAreas = {};
	const areasList = [];

	areas.forEach(area => {
		if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
			console.log('config_feature_sources_maxAreas', area);
		}
		areasByUri[area.uri] = area;
		areasList.push(area.uri);
		maxAreas[area.uri] = [area.uri];

		for (let i = 0; i <= areasList.length - 2; i++) {
			maxAreas[areasList[i]].push(area.uri);
		}
	});

	return {maxAreas, areasList, areasByUri};
});

export function contentUrlToId(url) {
	return url && url.replace(/\/$/, '').replace(/\//, '-');
}

export const findAreaAndTopicFromSourceId = memoizee(function (magic, featureSourceId) {
	if (!featureSourceId) {
		return null;
	}

	// da noArea-sources zufällig mit dem Namen einer Area anfängen können, diese zuerst suchen.
	let topic = null;
	(magic.topics || []).forEach(element => {
		if (element.noArea && element.uri === featureSourceId) {
			topic = element;
		}
	});

	let area = null;
	if (!topic) {
		(magic.areas || []).forEach(element => {
			if (featureSourceId.startsWith(element.uri)) {
				area = element;
			}
		});

		if (area) {
			const topicUri = featureSourceId.substr(area.uri.length + 1);
			(magic.topics || []).forEach(element => {
				if (topicUri === element.uri) {
					topic = element;
				}
			});
		}
	}

	return {
		area: topic && area,
		topic
	};
});

/**
 * @param {Array} maxAreas
 * @param {string|null} maxAreaUri
 * @param {string} areaUri
 * @return {string} uri of recommended area
 */
export function recommendedArea(maxAreas, maxAreaUri, areaUri) {
	if (!maxAreaUri) {
		return areaUri;
	}

	return maxAreas[maxAreaUri].includes(areaUri) ?
		areaUri :
		maxAreaUri;
}

// should go to store/selectors.js
export const reduceToLayerIdsSetVisible = (layers) => (layers ?
		Object.keys(layers).reduce(
			(acc, layerId) => (
				layers[layerId].options.visible ? [...acc, layerId] : acc
			),
			[]
		) : []
);
