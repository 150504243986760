import React, {Fragment, memo} from 'react';
import {useSelector} from 'react-redux';

import getPath from '@neonaut/lib-js/es/object/getPath';
import getFeatureProperty from '@mapsight/ui/src/js/helpers/get-feature-property';

import {newURL} from '../../routing/helpers';

import {useMagic} from '../../hooks';
import {
	createUrgentBlueSelector,
	createUrgentGreenSelector,
	createUrgentRedSelector,
	createUrgentSchoolSelector
} from '../../store/selectors';
import {
	SPECIAL_PAGE_URGENT_BLUE,
	SPECIAL_PAGE_URGENT_GREEN,
	SPECIAL_PAGE_URGENT_RED,
	SPECIAL_PAGE_URGENT_SCHOOL,
} from '../../store/controller';

import ListSummaryInner from '../lists/list-summary-inner';
import Link from '../link';
import {internalLink} from '../content';

import PanelBox from './panel-box';


// Überlegungen:
// Vorarbeiten: SideStartpage ausbauen zu CommonBoxes, dass alle gemeinsamen Inhalte aus main-panel-container und additional-container-content
// diese Element muss dann die Anzahl der Zusatzboxen als className bereitstellen
// auf der Startseite: alle Boxen anzeigen
// auf den Meldungsseiten: alle Boxen darstellen außer der eigenen
// auf der Seite Schulausfall: nur die wichtigste Box anderer Kategorien
// auf allen übrigen Seiten: nur die wichtigste Box
//
// prio: rot, orange, grün, blau
//
// wenn Boxen nicht dargestellt werden Hinweis im Link-Text oder Text kürzen auf 'Meldungen anzeigen ...'
//
// CommonBoxes zieht ein div mit eigener CSS-Klaase ein .common-boxes
// weitere Steuerung alles über common-boxes__ wie __sacrify für die Liste 2 ...
//   das wird den Boxen dann per className mitgegeben


// 159 chars are 4 lines of text
function shortDesc(shortDescription) {
	if (shortDescription.length > 160) {
		const idx = shortDescription.lastIndexOf(' ', 160);
		if (idx > 100) {
			return shortDescription.substr(0, idx + 1) + '…';
		} else {
			return shortDescription.substr(0, 160) + '…';
		}
	}
	return shortDescription;
}

function urgentBoxContent(features, topic, boxCount, forceContent) {
	const showContent = forceContent || boxCount < 2 && features.length === 1;

	if (!features.length)
		return null;

	const href = topic.noArea ? `/${topic.pageUri}` :
		(topic.maxArea ? `/${topic.maxArea}/${topic.pageUri}` : `/bundesweit/${topic.pageUri}`);

	const linkInternal0 = internalLink(features[0] && features[0].properties);
	const {
		shortDescription: shortDescription0,
		link: link0,
	} = features[0] && features[0].properties || {};
	const shortDesc0 = features.length === 1 && shortDesc(shortDescription0);
	const hostname0 = !linkInternal0 && link0 && newURL(link0) && (newURL(link0)).hostname;

	const content = showContent && features.length > 1 && features.map((f, i) => (
		i < forceContent && (<li
			className="ms3-list__item vmznds-panel-box--key-urgent__entry" key={`ue${i}`}
			dangerouslySetInnerHTML={{__html: shortDesc(getFeatureProperty(f, 'shortDescription'))}}
		/>)
	));

	return (
		<PanelBox
			k={topic.type}
			href={href}
			headline={`${topic.pageName}`}
			urgent={topic.type}
			footer={showContent && features.length === 1 ?
				<Fragment>
					<span dangerouslySetInnerHTML={{__html: shortDesc0}} />
					<br />
					<Link
						tabIndex={linkInternal0 || link0 ? undefined : -1}
						className="vmznds-list__more-link"
						to={linkInternal0 || link0 || href}
					>
						{hostname0 ? `weiter zu ${hostname0} ...` : 'mehr ...'}
					</Link>
				</Fragment> :
				<ListSummaryInner totalCount={features.length}
								  topic={topic}
				/>
			}
			height={showContent && features.length > 1 ? 'keep' : undefined}
			mods={['main', 'body-no-scroll']}
		>
			{showContent && features.length > 1 &&
			<div className="vmznds-main-panel-container__main-list-wrapper">
				<div className="ms3-list-wrapper">
					<ul className="ms3-list ms3-list--features  ms3-featurelist--status-ok">
						{content}
					</ul>
				</div>
			</div>
			}
		</PanelBox>
	);
}

export const UrgentSchoolBox = memo(function ({boxCount, forceContent}) {
	const magic = useMagic();
	const urgentSchoolFeatures = useSelector(createUrgentSchoolSelector(magic));
	const topic = getPath(magic.topics.filter(t => t.type === SPECIAL_PAGE_URGENT_SCHOOL), [0]);

	// console.warn('UrgentSchoolBox', {topic, urgentSchoolFeatures});

	return urgentBoxContent(urgentSchoolFeatures,
		topic ? topic : {
			pageName: 'Schulausfälle',
			maxArea: 'niedersachsen',
			pageUri: 'schulausfall',
			type: SPECIAL_PAGE_URGENT_SCHOOL
		},
		boxCount,
		forceContent
	);
});

export const UrgentRedBox = memo(function ({boxCount, forceContent}) {
	const magic = useMagic();
	const urgentRedFeatures = useSelector(createUrgentRedSelector(magic));
	const topic = getPath(magic.topics.filter(t => t.type === SPECIAL_PAGE_URGENT_RED), [0]);

	// console.warn('UrgentRedBox', {topic, urgentRedFeatures});

	return urgentBoxContent(
		urgentRedFeatures,
		topic ? topic : {
			pageName: urgentRedFeatures.length !== 1 ? 'Eilmeldungen' : 'Eilmeldung',
			noArea: true,
			pageUri: 'eilmeldungen',
			type: SPECIAL_PAGE_URGENT_RED
		},
		boxCount,
		forceContent
	);
});

export const UrgentGreenBox = memo(function ({boxCount, forceContent}) {
	const magic = useMagic();
	const urgentGreenFeatures = useSelector(createUrgentGreenSelector(magic));
	const topic = getPath(magic.topics.filter(t => t.type === SPECIAL_PAGE_URGENT_GREEN), [0]);

	// console.warn('UrgentGreenBox', {topic, urgentGreenFeatures});

	return urgentBoxContent(
		urgentGreenFeatures,
		topic ? topic : {
			pageName: 'Umwelt-Info',
			noArea: true,
			pageUri: 'umwelt-info',
			type: SPECIAL_PAGE_URGENT_GREEN
		},
		boxCount,
		forceContent
	);
});

export const UrgentBlueBox = memo(function ({boxCount, forceContent}) {
	const magic = useMagic();
	const urgentBlueFeatures = useSelector(createUrgentBlueSelector(magic));
	const topic = getPath(magic.topics.filter(t => t.type === SPECIAL_PAGE_URGENT_BLUE), [0]);

	// console.warn('UrgentBlueBox', {topic, urgentBlueFeatures});

	return urgentBoxContent(
		urgentBlueFeatures,
		topic ? topic : {
			pageName: 'Information',
			noArea: true,
			pageUri: 'informationen',
			type: SPECIAL_PAGE_URGENT_BLUE
		},
		boxCount,
		forceContent
	);
});
