import React, {Fragment, memo} from 'react';
import {useSelector} from 'react-redux';

import {viewSelector} from '@mapsight/ui/src/js/store/selectors';
import {VIEW_MAP_ONLY} from '@mapsight/ui/src/js/config/constants/app';

import {memoForever} from '../helpers';

import SkipLink from './skip-link';


/** @type {import("@mapsight/ui/src/js/components/contexts").MapWrapper} */
const MapWrapper = memo(function MapWrapper({children}) {
		const view = useSelector(viewSelector);

		return (<Fragment>
			{(view === VIEW_MAP_ONLY) && <SkipLink />}
			{children}
		</Fragment>);
	},
	memoForever
);

export default MapWrapper;
