import React, {Fragment, memo} from 'react';
import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';

export function modeToString(mode, description) {
	if (description !== undefined) {
		return description;
	}

	switch (mode) {
		case 'u':
			// dont know if its "U-bahn" or "Stadtbahn", in der Region Hannover ist es immer Stadtbahn.
			return 'Stadtbahn';

		case 's': return 'S-Bahn';
		case 'bus': return 'Bus';
		case 'rb': return 'Regionalbahn';
		case 're': return 'Regional-Express';
		case 'db': return 'Deutsche Bahn Fernverkehr';
		case 'ast': return 'Bedarfsverkehr';
		default: return null;
	}
}

const PtType = memo(
	/**
	 * @param {{
	 *   mode: import("../get-stops").Mode,
	 *   description?: string,
	 * }} props
	 */
	function PtType({mode, description}) {
		const desc = modeToString(mode, description);
		return (
			<Fragment>
				{desc !== null && (
					<span className="visuallyhidden">
						{desc}
					</span>
				)}

				<span
					className={modClasses('vmznds-pt-type', mode)}
					aria-hidden="true"
				>
					{mode === 'ast' && 'AST'}
				</span>
			</Fragment>
		);
	}
);

export default PtType;
