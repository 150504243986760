import React, {memo} from 'react';

import PtLine from './line';
import PtType from './type';

// use Parameter
const StopComp = memo(
	/**
	 * @param {{stop: import('../get-stops').Stop}} props
	 */
	function Stop({stop}) {
		return (
			<section className="vmznds-feature-details-pundr__stop">
				<div className="vmznds-feature-details-pundr__stop-symbol">
					<PtType mode={stop.mode} />
					<PtLine mode={stop.mode} name={stop.lineName} />
				</div>

				<div className="vmznds-feature-details-pundr__stop-body">
					<div className="vmznds-feature-details-pundr__stop-destination">
						{'Richtung Zentrum, Fahrziel '}
						{stop.destinationName}
						{':'}
					</div>

					<ol className="vmznds-feature-details-pundr__stop-events">
						{/* TODO: show only "future" events */}
						{stop.departures.map(({dt: departureDt}, eventIndex) => (
							<li
								key={eventIndex}
								className="vmznds-feature-details-pundr__stop-event"
							>
								{(() => {
									// low-prio TODO: USE `react-intl`
									// https://fabrik.neonaut-intern.net/T1965
									const min = departureDt.getMinutes();
									const str = `${departureDt.getHours()}:${min <= 9 ? '0' : ''}${min}`;
									return (
										<time dateTime={departureDt.toISOString()}>
											{str}
										</time>
									);
								})()}
							</li>
						))}
					</ol>
					{' Uhr'}

					{stop.description !== null && (
						<div dangerouslySetInnerHTML={{__html: stop.description}} />
					)}
				</div>
			</section>
		);
	}
);

export default StopComp;
