import React, {memo} from 'react';
import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';

/**
 * @param {number} capacity
 * @param {number|null} occupied
 * @returns {number|null}
 */
function calcAvailable(capacity, occupied = null) {
	if (occupied !== null) {
		return capacity - occupied;
	} else {
		return null;
	}
}

function calcAvailabilityStatus(availableNum) {
	if (availableNum === null) {
		return 'unknown';
	} else if (availableNum > 0) {
		return 'available';
	} else {
		return 'full';
	}
}

function renderSlotValueIcon(status) {
	switch (status) {
		case 'available':
			return (
				<img
					className="vmznds-feature-details-pundr__area-slot-value-icon"
					src="/assets/img/icons/check.svg"
					alt="frei"
				/>
			);
		case 'full':
			return (
				<img
					className="vmznds-feature-details-pundr__area-slot-value-icon"
					src="/assets/img/icons/close.svg"
					alt="belegt"
				/>
			);
		case 'unknown':
		default:
			return null;
	}
}

const UtilizationEntry = memo(
	// TODO: eslint jsdoc does not compute this typescript
	// eslint-disable-next-line jsdoc/valid-types
	/**
	 * @param {{
	 *   name: React.ReactNode,
	 * } & import('../occupation').UtilizationEntry} props
	 */
	function UtilizationEntry({name, capacity, occupied = null, free = null}) {
		const availableNum = free !== null
			? free
			: calcAvailable(capacity, occupied);
		const status = calcAvailabilityStatus(availableNum);

		return (
			<div className={modClasses('vmznds-feature-details-pundr__area-slot', status)}>
				<dt className="vmznds-feature-details-pundr__area-slot-key">
					{name} ({capacity})
				</dt>

				<dd className="vmznds-feature-details-pundr__area-slot-value">
					{'Frei: '}
					{availableNum !== null ? availableNum.toString() : 'unbekannt'}
					{' '}
					{renderSlotValueIcon(status)}
				</dd>
			</div>
		);
	}
);

export default UtilizationEntry;
