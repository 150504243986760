import isObject from 'lodash/isObject';
import flatten from 'lodash/flatten';
import map from 'lodash/map';

const EMPTY_BRACKETS_REGEXP = /\[]$/;

function stringifyValue(value) {
	return typeof value === 'function' ? stringifyValue(value()) : (value === null || value === undefined) ? '' : value;
}

function encodePair(key, value) {
	return encodeURIComponent(key) + '=' + encodeURIComponent(stringifyValue(value));
}

/**
 * Returns an array of url encoded key-value pairs based on the given object obj.
 * The method serializes deep objects recursively to accommodate modern scripting languages and frameworks such as PHP
 * and Ruby on Rails. If the object passed is in an Array, it must be an array of objects of the format {name: …, value: …}.
 *
 * @param {*} obj object or array to encode
 * @param {string} prefix prefix for keys (used for internal recursion)
 *
 * @returns {string[]} array of url encoded key-value pairs
 */
export function buildParams(obj, prefix = '') {
	if (prefix) {
		if (Array.isArray(obj)) {
			return flatten(obj.map((element, key) => {
				if (EMPTY_BRACKETS_REGEXP.test(prefix)) {
					return [encodePair(prefix, element)];
				}

				return buildParams(element, prefix + '[' + (typeof element === 'object' ? key : '') + ']');
			}));
		}

		if (isObject(obj)) {
			return flatten(map(obj, (element, key) => buildParams(element, prefix + '[' + key + ']')));
		}

		return [encodePair(prefix, obj)];
	}

	if (Array.isArray(obj)) {
		return obj.map(({name, value}) => encodePair(name, value));
	}

	return flatten(map(obj, buildParams));
}

/**
 * Returns a url encoded string of key-value pairs based on the given object obj.
 * The method serializes deep objects recursively to accommodate modern scripting languages and frameworks such as PHP
 * and Ruby on Rails. If the object passed is in an Array, it must be an array of objects of the format {name: …, value: …}.
 *
 * @param {*} obj object or array to encode
 *
 * @returns {string} url encoded string of key-value pairs
 */
export default function (obj) {
	return buildParams(obj).join('&').replace(/%20/g, '+');
}
