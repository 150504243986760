import React, {forwardRef, memo, useLayoutEffect, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';

import {listQuerySelector} from '@mapsight/ui/src/js/store/selectors';
import {filterFeatures} from '@mapsight/ui/src/js/components/feature-list/filtering';
import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';
import {FEATURE_LIST, FEATURE_SOURCES} from '@mapsight/ui/src/js/config/constants/controllers';
import FeatureListOne from '@mapsight/ui/src/js/components/feature-list';

import {modalitySelector} from '../../../modules/navigation/store/selectors';
import {NAVIGATION_URIS} from '../../../modules/navigation/store/constants';

import {withErrorBoundary} from '../../../modules/error-boundary';

import {useMagic, useNavPos} from '../../hooks';
import {
	createUrgentBlueSelector,
	createUrgentGreenSelector,
	createUrgentRedSelector,
	createUrgentSchoolSelector,
	detailsSelector,
	isPageContent,
	isPageHome,
	listTitleSelector,
	listVisibleSelector,
	preselectedFeatureInListOneSelector,
	specialPageSelector
} from '../../store/selectors';

import ListTwo from '../lists/list-two-box';
import ListOneBox from '../lists/list-one-box';
import StartPageItem from '../lists/start-page-item';

import PanelBox from './panel-box';
import {UrgentBlueBox, UrgentGreenBox, UrgentRedBox, UrgentSchoolBox} from './urgent-boxes';



// TODO replace hard-coded link for list one with some logic
const ListOneStartPageItem = memo(forwardRef(function ListOneStartPageItem(props, ref) {
	const {area} = useNavPos();
	return (<StartPageItem ref={ref} to={`/${area.uri}/verkehrslage`} {...props} />);
}));

const Boxes = memo(
	/**
	 * @param {{
	 * }} props
	 */
	function Boxes({children, boxesRef}) {
		// boxesRef ist statisch vom nutzenden Code her entweder immer gesetzt oder nie gesetzt
		// eslint-disable-next-line react-hooks/rules-of-hooks
		boxesRef = boxesRef || useRef();
		const listOneBodyRef = useRef();

		const magic = useMagic();
		const navPos = useNavPos();
		const details = useSelector(detailsSelector);
		const listVisible = useSelector(listVisibleSelector);
		const listTitle = useSelector(listTitleSelector);
		const listSource = useSelector(state => state[FEATURE_LIST].featureSource);
		const listSourceFeaturesSelector = useMemo(
			() => state => state[FEATURE_SOURCES][listSource]?.data?.features,
			[listSource]
		);
		const listSourceFeatures = useSelector(listSourceFeaturesSelector);
		const listFilterQuery = useSelector(listQuerySelector);
		const filteredFeatures = useMemo(
			() => filterFeatures(listFilterQuery, listSourceFeatures),
			[listFilterQuery, listSourceFeatures]
		);
		const preselectedFeature = useSelector(preselectedFeatureInListOneSelector);

		const specialPage = useSelector(specialPageSelector);
		const pageHome = isPageHome(specialPage);
		const pageContent = isPageContent(specialPage);
		// const pageSchool = isPageSchool(specialPage);
		// const pageCameras = isPageCameras(specialPage);
		// const isUrgentRedPage = (specialPage === SPECIAL_PAGE_URGENT_RED);

		const navigationModality = useSelector(modalitySelector);

		const urgentSchool = useSelector(createUrgentSchoolSelector(magic));
		const urgentRed = useSelector(createUrgentRedSelector(magic));
		const urgentGreen = useSelector(createUrgentGreenSelector(magic));
		const urgentBlue = useSelector(createUrgentBlueSelector(magic));

		const pageHomeOr404 = pageHome || navPos.error404;
		const outerIsNav = pageHomeOr404 && !details;

		const urgentSupressLowPrio = urgentRed.length >= 3 || urgentRed.length >= 2 && urgentBlue.length && urgentGreen.length;
		const urgentRedContent = urgentRed.length; // show all
		const urgentGreenContent = urgentRed.length || !urgentGreen.length ? 0 :
			(urgentBlue.length === 1 && urgentGreen.length === 1 ? 1 :
				(urgentBlue.length ? 0 : Math.min(3, urgentGreen.length)));
		// const urgentBlueContent = urgentRed.length ? 0 : Math.min(urgentGreen.length ? 1 : 3, urgentBlue.length);
		const urgentBlueContent = urgentRed.length || !urgentBlue.length ? 0 :
			(urgentGreen.length === 1 && urgentBlue.length === 1 ? 1 :
				(urgentGreen.length ? 0 : Math.min(3, urgentBlue.length)));

		const urgentSchoolContent = !urgentRedContent && !urgentBlueContent && !urgentGreenContent ? 0 : Math.min(3, urgentSchool.length);

		const urgentBoxCount =
			(urgentRedContent.length) +
			(urgentGreenContent ? 1 : 0) +
			(urgentBlueContent ? 1 : 0) +
			(urgentSchoolContent ? 1 : 0);

		// const urgentBoxCount =
		// 	(urgentSchool.length ? 1 : 0) +
		// 	(urgentRed.length ? 1 : 0) +
		// 	(urgentRed.length && urgentRed.length < 3 ? urgentRed.length - 1 : 0) + // wenn es nur 1 oder 2 Eilmeldungen sind, werden diese direkt angezeigt, zählen aber als box. In dem Fall wird über urgentSupressLowPrio dafür gesorgt, dass urgentBoxCount die 3 (excl. schulausfall) nicht übersteigt
		// 	(!urgentSupressLowPrio && urgentGreen.length ? 1 : 0) +
		// 	(!urgentSupressLowPrio && urgentBlue.length ? 1 : 0);
		// const maxUrgentMessages = Math.max(urgentSchool.length, urgentRed.length, urgentGreen.length, urgentBlue.length);
		// // DISABLED: if we have one box with one message: we show the content on the homePage → close other boxes as there where 3 boxes
		// // const extra = urgentBoxCount===1 && maxUrgentMessages===1 ? 3 :
		// // 	(urgentSchool.length ? urgentBoxCount - 1 : urgentBoxCount);
		// const extra = (urgentSchool.length ? urgentBoxCount - 1 : urgentBoxCount) +
		// 			  (urgentBoxCount === 1 ? maxUrgentMessages - 1 : Math.max(0, urgentRed.length -1));

		const extra = Math.min(3,
			urgentRedContent
			+ urgentGreenContent
			+ urgentBlueContent
			+ Math.max(0, urgentSchoolContent - 1));

		// console.log('urgentBoxes index', {
		// 	extra,
		// 	extraA: (urgentSchool.length ? urgentBoxCount - 1 : urgentBoxCount),
		// 	// extraB: (urgentBoxCount === 1 ? maxUrgentMessages - 1 : Math.max(0, urgentRed.length -1)),
		// 	urgentBoxCount,
		// 	// maxUrgentMessages,
		// 	urgentSchool: urgentSchool.length,
		// 	urgentRed: urgentRed.length,
		// 	urgentBlue: urgentBlue.length,
		// 	urgentGreen: urgentGreen.length,
		// 	urgentRedContent,
		// 	urgentGreenContent,
		// 	urgentBlueContent,
		// 	urgentSchoolContent,
		// });


		const boxes = [];

		if (pageHomeOr404 && !details) {
			if (urgentRed.length) {
				boxes.push(
					<UrgentRedBox
						key="urgent-box-red"
						boxCount={urgentBoxCount}
						forceContent={urgentRedContent}
					/>
				);
			}
			if (urgentSchool.length) {
				boxes.push(
					<UrgentSchoolBox
						key="urgent-box-school"
						boxCount={urgentBoxCount}
						forceContent={urgentSchoolContent}
					/>
				);
			}
			if (!urgentSupressLowPrio && urgentGreen.length) {
				boxes.push(
					<UrgentGreenBox
						key="urgent-box-green"
						boxCount={urgentBoxCount}
						forceContent={urgentGreenContent} />
				);
			}
			if (!urgentSupressLowPrio && urgentBlue.length) {
				boxes.push(<UrgentBlueBox
					key="urgent-box-blue"
					boxCount={urgentBoxCount}
					forceContent={urgentBlueContent} />
				);
			}

			boxes.push(
				<PanelBox
					key="prerouting"
					k="prerouting"
					href={`navigation/${NAVIGATION_URIS[navigationModality || 'car']}`}
					headline="Routenplaner"
				/>
			);
		}

		if (listVisible || (pageHomeOr404 && details)) {
			boxes.push(
				<ListOneBox
					bodyRef={listOneBodyRef}
					key="main-list"
					k="main-list"
					listTitle={listTitle}
					filteredFeatures={listVisible && !details && !pageHomeOr404 && !pageContent && filteredFeatures}
				>
					{children}
				</ListOneBox>
			);
		} else if (pageHomeOr404 /* && implicit: !details */) {
			boxes.push(
				<ListOneBox
					bodyRef={listOneBodyRef}
					key="main-list"
					k="main-list"
					listTitle={listTitle}
				>
					<FeatureListOne itemAs={ListOneStartPageItem} />
				</ListOneBox>
			);
		}

		if (pageHomeOr404 && !details) {
			boxes.push(<ListTwo key="list-two" />);

			if (!urgentSchool.length) {
				boxes.push(
					<PanelBox
						key="school-link"
						k="school-link"
						href="schulausfall"
						headline="Schulausfälle"
					/>
				);
			}

			boxes.push(
				<PanelBox
					key="cameras"
					k="cameras"
					href="region-hannover/verkehrskameras"
					headline="Verkehrskameras"
				/>
			);
		}


		useLayoutEffect(() => {
				if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
					console.log('list-one-box useLayoutEffect', {details, listSource, boxesRef, listOneBodyRef});
				}

				// eines der drei hat sich geändert -> also hoch scrollen, außer es gibt ein preselectedFeature zu dem die Liste gleich scrollt
				if ((details || !preselectedFeature)) {// Liste unsichtbar oder kein preselect-Ziel
					if (boxesRef.current) {
						boxesRef.current.scrollTop = 0;
					}
					if (listOneBodyRef.current) {
						listOneBodyRef.current.scrollTop = 0;
					}
				}
			},
			// nicht ausführen wenn sich nur die boxesRef geändert hat, deshalb wird die hier absichtlich nicht erwähnt
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[details, listSource, preselectedFeature]
		);

		const className = modClasses('vmznds-panel-boxes', [
			specialPage && `page-${specialPage}`,
			!details && pageHomeOr404 && 'with-extra',
			!details && pageHomeOr404 && `extra-${extra}`, // urgentSchool is always there, it just get's bigger, extra-0 is a marker for showUrgent
		]);

		if (boxes.length) {
			if (outerIsNav) {
				return (
					<nav
						ref={boxesRef}
						className={className}
						aria-labelledby="h1boxes"
						tabIndex="-1"
					>
						<h1 id="h1boxes" className="visuallyhidden">Kurznavigation</h1>
						{boxes}
					</nav>
				);
			} else {
				const Outer = !pageContent ? 'main' : 'div';
				return (
					<Outer
						ref={boxesRef}
						className={className}
						tabIndex={!pageContent ? -1 : undefined}
					>
						{boxes}
					</Outer>
				);
			}
		}
		return null;
	}
);

export default withErrorBoundary(Boxes);
