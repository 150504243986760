import React, {Fragment, memo, useContext} from 'react';
import {useSelector} from 'react-redux';

import {userPreferenceListVisibleSelector, viewSelector} from '@mapsight/ui/src/js/store/selectors';
import {VIEW_FULLSCREEN, VIEW_MOBILE} from '@mapsight/ui/src/js/config/constants/app';

import RouteToStore from '../routing/route-to-store';
import {RefsContext} from '../contexts';

import Boxes from './boxes';
import FooterNavigation from './footer-navigation';


/** @type {import("@mapsight/ui/src/js/components/contexts").AdditionalContainerContent} */
const AdditionalContainerContent = memo(
	function AdditionalContainerContent({children}) {
		const view = useSelector(viewSelector);
		const isMobile = view === VIEW_MOBILE;
		const isDesktop = view === VIEW_FULLSCREEN;
		const isNotFullscreen = useSelector(userPreferenceListVisibleSelector);

		const {setMapsightContentRef} = useContext(RefsContext);

		return (
			<Fragment>
				<RouteToStore />

				{isMobile && (
					<Boxes boxesRef={setMapsightContentRef}>
						{children}
					</Boxes>
				)}

				{(isMobile || (isDesktop && isNotFullscreen)) && <FooterNavigation />}
			</Fragment>
		);
	}
);

export default AdditionalContainerContent;
