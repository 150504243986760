import {BrowserRouter as BaseBrowserRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';

/**
 * @type {null|{
 *    goBack: Function,
 *    length: number,
 *    replace: Function,
 *    go: Function,
 *    action: string,
 *    location: object,
 *    goForward: Function,
 *    push: Function
 * }}
 */
export const history = typeof window !== 'undefined' ? createBrowserHistory() : null;

export class BrowserRouter extends BaseBrowserRouter {
	constructor(p) {
		super(p);
		this.history = history;
	}
}
