import React, {forwardRef, memo} from 'react';

import Link from '../link';


// tabIndex={-1} aria-hidden={true} nur auf der Startseite, falls dieses Element später auch für Deep-Links verwendet werden soll
export default memo(forwardRef(function StartPageItem(props, ref) {
	const {to, children, className, feature, ...other} = props;

	return (
		<li className={className} ref={ref}>
			<Link to={to} tabIndex={-1} aria-hidden={true} {...other}>
				{children}
			</Link>
		</li>
	);
}));
