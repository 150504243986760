import memoizee from 'memoizee';

import {metaData, metaDataWithLegend, osm, userGeolocation} from '@mapsight/ui/config/map-layers';
import {
	features as baseFeatures,
	interactiveFeatures as baseInteractiveFeatures, metaDataWithMiniLegend
} from '@mapsight/ui/src/js/config/map/layers';

import {calculateMaxAreas} from '../routing/helpers';
import {SPECIAL_PAGE_NAVIGATION} from '../store/controller';
import {isPageContent, isPagePrerouting} from '../store/selectors';

import legend, {ANOMALIES_LEGEND, BASE_LEGEND, LOS_LEGEND} from '../components/map-legends';
import {NAVIGATION_URIS, ROUTE_LAYERS} from '../../modules/navigation/store/constants';


const defaultFitFeaturesInViewOptions = {
	duration: 300, // default: 300
	padding: [20, 20, 20, 20], // default: [60, 100, 60, 100]
	keepZoom: false, // default: false
	//nearest: true, // default: false
	maxZoom: 12, // default: 17, smaller construction work on motorway lead to 13-14
	skipIfInView: true, // default: true
};

// TODO: Maybe make these defaults configurable in @mapsight/ui?
function interactiveFeatures(featureSourceId, visible, _metaData, style, sourceOptions = {}) {
	return baseInteractiveFeatures(featureSourceId, visible, _metaData, style, {
		...sourceOptions,
		//centerFeaturesInViewSelections: ['select'], // default: []
		//centerFeaturesInViewOptions: {
		//	duration: 300, // default: 300
		//	zoom: 14, // default: undefined
		//	...sourceOptions.centerFeaturesInViewOptions,
		//},
		//fitFeaturesInViewSelections: ['select'], // default: ['select']
		fitFeaturesInViewOptions: {
			...defaultFitFeaturesInViewOptions,
			...sourceOptions.fitFeaturesInViewOptions,
		},
	});
}

function nonInteractiveFeatures(featureSourceId, visible, _metaData, style, sourceOptions = {}) {
	return baseFeatures(featureSourceId, visible, false, _metaData, style, {
		...sourceOptions,
		//centerFeaturesInViewSelections: ['select'], // default: []
		//centerFeaturesInViewOptions: {
		//	duration: 300, // default: 300
		//	zoom: 14, // default: undefined
		//	...sourceOptions.centerFeaturesInViewOptions,
		//},
		//fitFeaturesInViewSelections: ['select'], // default: ['select']
		fitFeaturesInViewOptions: {
			...defaultFitFeaturesInViewOptions,
			...sourceOptions.fitFeaturesInViewOptions,
		},
	});
}

const osmAttribution =
	'Karte: © <a className="vmznds-list__more-link--external" href="http://www.openstreetmap.org/copyright" rel="external">OpenStreetMap-Mitwirkende<span class="visuallyhidden"> (externe Seite)</span></a>';

const graphmastersAttribution = 'Verkehrsfluss: &copy; Graphmasters';

export const AUTHORIZATION_SOURCE_ID = 'vmznds-internal-authorization';

const LAYER_SWITCHER_GROUP = 'Karten-Ebenen';

const topicFeature = (uri, topic, visible, visibleInLayerSwitcher, isBaseLayer) => {
	const metaDataValue = metaData(topic.name, '', visibleInLayerSwitcher, false, isBaseLayer, LAYER_SWITCHER_GROUP, isBaseLayer);

	return interactiveFeatures(
		uri,
		visible,
		topic.uri === 'anomalies' ?
			metaDataWithMiniLegend(metaDataValue, ANOMALIES_LEGEND) :
			topic.miniLegend ?
				metaDataWithMiniLegend(metaDataValue, topic.miniLegend) :
				metaDataValue,
		'features',
		isPageContent(topic.type) ? {/*empty turns off*/
			fitFeaturesInViewSelections: []
		} : undefined
	);
};

function setLayerZIndexes(layers) {
	let i = 0;
	Object.keys(layers).forEach(key => {
		layers[key].options = {
			zIndex: i++,
			...layers[key].options,
		};
	});
}

export default memoizee(function configMapLayers(magic, navPos) {
	const {maxAreas, areasList} = calculateMaxAreas(magic.areas);
	const {content, area, topic: currentTopic, reduced} = navPos || {};

	// even this may evaluate to true, the layer may not be added if reduced is true
	const losVisible = (area && currentTopic &&
			(
				currentTopic.uri === 'verkehrsmeldungen' ||
				isPagePrerouting(currentTopic.type)
			)
		) ||
		(!content && currentTopic && currentTopic.uri === ''); // TODO keep visible if set by user.

	/*
		Order of layers will set the zIndex in the map!

		I)   Base layer OSM
		II)  User geolocation
		III) Routing
		VI)  Quasi base layer LOS+Anomalies
		V)   Layer for current page (area + topic)
		VI)  Additional layers
		VII) Search result
	*/

	const result = {
		// I) Base layers OSM
		//osm: osm('/tiles/none/{z}/{x}/{y}.png', true, metaData('OpenStreetMap', osmAttribution, false, false, true)),
		vmz: osm(`/vmztileproxy/${reduced ? 'ggtc' : 'vmzt'}/{z}/{x}/{y}.png`, true,
			metaDataWithMiniLegend(
				metaDataWithLegend(
					metaData('Grundkarte', osmAttribution, true, false, true, LAYER_SWITCHER_GROUP, true),
					legend(magic)),
				BASE_LEGEND)
		)
	};

	// II) LOS(+Anomalies) act as a quasi base layer, so we add it before the other layers
	if (!reduced || currentTopic?.uri === 'verkehrsmeldungen') {
		result.los = {
			type: 'VectorTile',
			metaData: metaDataWithMiniLegend(
				metaData('Verkehrsfluss', graphmastersAttribution, true, false, false, LAYER_SWITCHER_GROUP, false),
				LOS_LEGEND
			),
			options: {
				source: {
					type: 'VectorTile',
					options: {
						doRefresh: true,
						timer: 5 * 60 * 1000, // anomalies wird im fünf Minuten-Takt berechnet
						// T2084 beim Graphmasters-Server trudeln die Kacheln über 2 Sekunden verteilt ein, was jedesmal die Karte neu berechnen lässt.
						// Daher nutzen wir entgegen dem Wunsch von Graphmasters unseren Proxy
						url: '/tiles-graphmasters-api/none/{z}/{x}/{y}.mvt', // mit unserem Proxy sieht es flüssiger aus, aber Graphmasters wünscht es so
						// url: 'https://traffic.graphmasters.net/tiles/v1/{z}/{x}/{y}.mvt?itemFeatures=currentSpeed,speedRatio',
						format: {
							type: 'MVT',
						},
					},
				},
				visible: losVisible,
				style: 'graphmastersTraffic',
				selections: {
					//mousedown: 'highlight',
					//touch: 'highlight',
					//mouseover: 'highlight',
				},
			},
		};
	}

	Object.assign(result, {
		// III) User geolocation
		userGeolocation: userGeolocation('userGeolocation', true, metaData('Benutzerstandort')),
	});

	// IV) anomalies still a sort of a base layer, but with interactive items
	if (!reduced && currentTopic?.uri !== 'verkehrsmeldungen') {
		Object.assign(result, {
			anomalies: interactiveFeatures( // hängt im LayerSwitcher am los, siehe embed und Kommentar dort
				'anomalies',
				losVisible,
				metaDataWithMiniLegend(
					metaData('Verzögerungen'),
					ANOMALIES_LEGEND
				),
				'features'
			),

		});
	}

	const isNavigation = currentTopic && currentTopic.type === SPECIAL_PAGE_NAVIGATION;
	Object.assign(result, {
		// V) Routing – if used this is probably the layer of the current page
		[ROUTE_LAYERS.car]: nonInteractiveFeatures(
			ROUTE_LAYERS.car,
			isNavigation && navPos.deepLink === NAVIGATION_URIS.car,
			metaData('Route'),
			'navigationRoute'),

		[ROUTE_LAYERS.bike]: nonInteractiveFeatures(
			ROUTE_LAYERS.bike,
			isNavigation && navPos.deepLink === NAVIGATION_URIS.bike,
			metaData('Route'),
			'navigationRoute'),

		[ROUTE_LAYERS.public]: nonInteractiveFeatures(
			ROUTE_LAYERS.public,
			isNavigation && navPos.deepLink === NAVIGATION_URIS.public,
			metaData('Route'),
			'navigationRoute'),
	});

	// VI) Layer for current page (area + topic)
	if (area) {
		// NOTE Herbert: eigentlich navPos.areaFound, aber dann müsste man ein if für die Seiten ohne area schreiben. navPos setzt area immer auf etwas sinnvolles, selbst wenn keine in der url ist.
		// core layers defining that topic
		// NOTE: PG currentTopic.uri ist auf der Startseite nämlich leer!
		if (currentTopic && currentTopic.uri) {
			// TODO deeplinks-aus-karte const changeUrl = currentTopic.deepLinks; // eventuell gleich Bausisurl auf area, noArea und topic berechnen
			if (currentTopic.noArea) {
				result[`${currentTopic.uri}`] = topicFeature(`${currentTopic.uri}`, currentTopic, true, currentTopic.type !== SPECIAL_PAGE_NAVIGATION, true);
			} else {
				result[`${area.uri}-${currentTopic.uri}`] = topicFeature(`${area.uri}-${currentTopic.uri}`, currentTopic, true, currentTopic.type !== SPECIAL_PAGE_NAVIGATION, true);
			}
		} else {
			// startpage TODO this is hardcoded but should use store or react router state
			result[`${area.uri}-verkehrsmeldungen`] =
				interactiveFeatures(`${area.uri}-verkehrsmeldungen`, true, metaData('Verkehrsmeldungen', '', true, false, false, LAYER_SWITCHER_GROUP, false));
			if (area.uri !== 'bundesweit') {
				result[`${area.uri}-baustellen`] = interactiveFeatures(`${area.uri}-baustellen`, true, metaData('Baustellen', '', true, false, false, LAYER_SWITCHER_GROUP, false));
				// result[`${area.uri}-baustellen-vorschau`] = interactiveFeatures(`${area.uri}-baustellen`, false, metaData('Baustellen Vorschau', '', true, false, true, LAYER_SWITCHER_GROUP, true));
			}
		}
	}

	// VI) Additional layers
	if (!reduced) {
		// always add all layers but those added already
		magic.topics.forEach(topic => {
			const topicAreas = topic.maxArea && maxAreas[topic.maxArea] ? maxAreas[topic.maxArea] : areasList;

			if (topic.type !== SPECIAL_PAGE_NAVIGATION) {
				if (topic.noArea) {
					if (!result[`${topic.uri}`]) {
						result[`${topic.uri}`] =
							topicFeature(`${topic.uri}`, topic, false, !isPageContent(topic.type), false);
					}
				} else {
					topicAreas.forEach(topicArea => {
						if (!result[`${topicArea}-${topic.uri}`]) {
							result[`${topicArea}-${topic.uri}`] =
								topicFeature(
									`${topicArea}-${topic.uri}`,
									topic,
									false,
									!isPageContent(topic.type) && area && topicArea === area.uri && topic.hidden !== true,
									false
								);
						}
					});
				}
			}
		});
	}

	// VII) Search result
	result.searchResult = interactiveFeatures('searchResult', false, metaData('Suchergebnis'));

	// NOTE: Giving the layers an explicit zIndex to enforce layer order even if layers are destroyed and re-added which can lead
	// to unwanted order when no zIndex is specified. Layers get destroyed and re-added if it was removed completely (instead of
	// just hiding it) and added again or when its type changes so @mapsight/core has to reconstruct it.
	setLayerZIndexes(result);

	if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
		console.warn('mapLayers', {area, currentTopic, result});
	}

	return result;
});
