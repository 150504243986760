import {createContext} from 'react';

/**@type {import("react").Context<import("./types").Magic>} */
export const MagicContext = createContext({});
MagicContext.displayName = 'MagicContext';

export const SSRContext = createContext({});
SSRContext.displayName = 'SSRContext';

// getrennte *pageContentRef für Mobil und Desktop, weil sonst beim Drehen des Tables zuerst der neue eingetragen wird und dann beim Löschen des alten wieder gelöscht wird
/**
 * @type {import("react").Context<{
 *    pageContentRef: import("react").RefObject,
 *    mobilePageContentRef: import("react").RefObject,
 *    mapsightContentRef: import("react").RefObject,
 *    setPageContentRef: Function,
 *    setMobilePageContentRef: Function,
 *    setMapsightContentRef: Function,
 * }>}
 */
export const RefsContext = createContext({});
RefsContext.displayName = 'RefsContext';
