import React, {memo, Fragment} from 'react';

import getFeatureProperty from '@mapsight/ui/src/js/helpers/get-feature-property';

import {useNavPos} from '../../hooks';

import Link from '../link';


export default memo(function ({children, feature, ...props}) {
	const navPos = useNavPos();
	let permanentLink = undefined;
	if(getFeatureProperty(feature, 'detailsUrl')) {
		permanentLink = '/' +
			`${navPos.topic.pageUri}/` +
			getFeatureProperty(feature, 'detailsUrl').replace(/.*\//, '').replace(/\.popup$/, '');
	}
	const listName = getFeatureProperty(feature, 'listName'); // TODO: document/collect magic property names
	const name = getFeatureProperty(feature, 'name'); // TODO: document/collect magic property names
	const headline = listName || name;
	const teaser = getFeatureProperty(feature, 'teaser'); // TODO: document/collect magic property names
	const images = getFeatureProperty(feature, 'images'); // TODO: document/collect magic property names

	const begin = getFeatureDatePropertyAsString(feature, 'begin');
	const end = getFeatureDatePropertyAsString(feature, 'end');
	const dateString = !begin ? '' : `${begin}${end && end !== begin ? ' – '+end : ''} - `;

	const mainContent = (
		<Fragment>
			<div className="ce-textpic ce-right ce-intext">
				<div className="ce-gallery">
					<div className="ce-row">
						<div className="ce-column">
							{images && images[0] && (
								<figure className="image">
									<img className="vmznds-content-list__img"
										 src={`/${images[0].url}`}
										 alt={images[0].alternative}
									/>
								</figure>
							)}
						</div>
					</div>
				</div>
				<div className="ce-bodytext">
					<h2>{headline}</h2>
					<p>
						{dateString && (
							<b>{dateString}</b>
						)}
						{teaser}
					</p>
					{permanentLink && (
						<span className="vmznds-list__more-link">mehr ...</span>
					)}
				</div>
			</div>
		</Fragment>
	);

	return (
		<li {...props}>
			{permanentLink ?
				<Link
					className="ms3-list__link"
					to={permanentLink}
				>
					{mainContent}
				</Link> :
				mainContent
			}
		</li>
	);
});


function getFeatureDatePropertyAsString(feature, property) {
	const date = getFeatureDateProperty(feature, property);
	if(date) {
		const [year, month, day] = date.toISOString().substr(0, 10).split('-');
		return `${day}.${month}.${year}`;
	}
	return null;
}

function getFeatureDateProperty(feature, property) {
	const date=new Date(getFeatureProperty(feature, property, 'fail'));
	return (!date || !date.getDay()) ? null : date;
}
