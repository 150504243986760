import React, {memo} from 'react';

import {PanelBoxBox} from './panel-box-box';

const PanelNote = memo(
	/**
	 * @param {{
	 *   children: React.ReactNode,
	 * }} props
	 */
	function PanelNote({children, html}) {
		return (
			<PanelBoxBox
				height="keep"
				withBorder={false}
			>
				<div className="vmznds-panel-note" dangerouslySetInnerHTML={html ? {__html: html} : undefined}>
					{children}
				</div>
			</PanelBoxBox>
		);
	}
);
export default PanelNote;
