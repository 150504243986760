import React, {Fragment, memo, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';

import {createListFeatureSelector, featureSourceIdSelector} from '@mapsight/core/lib/list/selectors';
import {getAllFeatures, getFeatureSourceStatus, STATUS_ERROR, STATUS_LOADING} from '@mapsight/core/lib/feature-sources/selectors';
import {async} from '@mapsight/core/lib/base/actions';
import {load} from '@mapsight/core/lib/feature-sources/actions';

import {FEATURE_LIST, FEATURE_SOURCES} from '@mapsight/ui/src/js/config/constants/controllers';
// import FeatureList from '@mapsight/ui/src/js/components/feature-list';
import getFeatureProperty from '@mapsight/ui/src/js/helpers/get-feature-property';
import globalEventHub, {EVENT_PARTIAL_CONTENT_CHANGED} from '@mapsight/ui/src/js/helpers/global-event-hub';
import {setAppTitle} from '@mapsight/ui/src/js/store/actions';
import {translate} from '@mapsight/ui/src/js/helpers/i18n';

import {getBaseUrl, newURL} from '../../routing/helpers';
import {useNavPos} from '../../hooks';
import {withErrorBoundary} from '../../../modules/error-boundary';

import {detailsSelector, isPageCameras, isPageContent, isPageSitemap, specialPageSelector} from '../../store/selectors';
import {SPECIAL_PAGE_CONTENT, SPECIAL_PAGE_CONTENTLIST} from '../../store/controller';

import NavQR, {NavAppLink, readNunavDataLink} from '../../../modules/navigation/components/qr-link';
import Link from '../link';

import ContentCameras from './cameras';
import ContentSitemap from './sitemap';
import ContentBox from './box';
import Details from './details';
import List from './list';
import DeepContentListItem from './deep-content-list-item';

/* eslint-disable smells/no-complex-switch-case, no-case-declarations  */

export function internalLink(featureProperties) {
	if (featureProperties?.linkInternal) {
		return featureProperties.linkInternal.trim();
	}

	const {link} = featureProperties || {};
	const url = newURL(link);
	const location = typeof window !== 'undefined' && window ? window.location : undefined; // SSR: als extern ausgeben
	if (url && location && url.hostname === location.hostname) {
		return url.pathname;
	}

	return null;
}

const Content = memo(
	function Content({forMobile = false}) {
		const dispatch = useDispatch();
		const navPos = useNavPos();
		const featureSourceId = useSelector(createSelector(state => state[FEATURE_LIST], featureSourceIdSelector));
		const featureSource = useSelector(createListFeatureSelector(FEATURE_LIST, FEATURE_SOURCES));
		const {features, status} = useMemo(
			() => ({
				features: getAllFeatures(featureSource),
				status: getFeatureSourceStatus(featureSource),
			}),
			[featureSource]
		);
		const details = useSelector(detailsSelector);
		const specialPage = useSelector(specialPageSelector);

		const appTitle = specialPage === SPECIAL_PAGE_CONTENT && getFeatureProperty(features[0], 'name');
		const descProp = features && getFeatureProperty(features[0], 'description') || null;
		const desc = typeof descProp === 'string' ? descProp : null;

		useEffect(
			() => {
				if (featureSourceId) {
					dispatch(async(load(FEATURE_SOURCES, featureSourceId)));
				}
			},
			[featureSourceId, dispatch],
		);

		useEffect(
			() => {
				if (appTitle) {
					dispatch(setAppTitle(appTitle));
				}
			},
			[appTitle, dispatch]
		);

		useEffect(
			() => {
				globalEventHub.emit(EVENT_PARTIAL_CONTENT_CHANGED);
			},
			[desc]
		);

		if (isPageCameras(specialPage)) {
			return (<ContentCameras features={features} status={status} />);
		}

		if (isPageSitemap(specialPage)) {
			return (<ContentSitemap />);
		}

		if (!isPageContent(specialPage)) {
			return null;
		}

		let content = null;
		let mods = null;

		// switch nach Status ablösen, weil inkompatibel mit SSR. bei SSR gibt es Features und STATUS_LOADING gleichzeitig.
		// eine ähnliche Situation herrscht beim Reload der Quelle, z.b. bei den Veranstaltungen
		// dh. if(features) ist wichtiger als (status===STATUS_LOADING)
		if (status === STATUS_ERROR || navPos.error404) {
			if (featureSource.error === 'Not Found' || navPos.error404) {
				content = (
					<div
						className="vmznds-content vmznds-content--error"
					>
						<h1>404 Fehler</h1>
						<p>&nbsp;</p>
						Die Seite konnte leider nicht gefunden werden.
						<p>&nbsp;</p>
						<Link className="vmznds-list__more-link"
							  to={`${getBaseUrl()}${navPos.area ? navPos.area.uri : ''}`}>Zur Startseite
							wechseln.</Link>
						<br />
					</div>
				);
			} else {
				content = (
					<div
						className="vmznds-content vmznds-content--error"
					>
						{translate('ui.feature-details.content-inner.error')}
						<br />
						<p>&nbsp;</p>
						<Link className="vmznds-list__more-link"
							  to={`${getBaseUrl()}${navPos.area ? navPos.area.uri : ''}`}>Zur Startseite
							wechseln.</Link>
						<br />
					</div>
				);
			}
		} else if (features) {
			switch (specialPage) {
				case SPECIAL_PAGE_CONTENT:
					if (desc) {
						const nunavLink = readNunavDataLink(desc || '');
						content = (
							<Fragment>
								<div
									className={`ms3-feature-details-content ms3-feature-details-content--description vmznds-content__details ${nunavLink ? 'vmznds-content__details--withNunav' : ''}`}>
									<div dangerouslySetInnerHTML={{__html: desc}} />
									{nunavLink && (
										<div className="vmznds__infobox vmznds__infobox--nunav-link">
											<hr />
											<NavAppLink name={getFeatureProperty(features[0], 'name')}
														nunavLink={nunavLink} withQr={true} />
										</div>
									)}
									{navPos.navService && (
										<div className="vmznds__infobox vmznds__infobox--footer">
											<NavQR />
										</div>
									)}
									{navPos.error404 && (
										<Link className="vmznds-list__more-link"
											  to={`${getBaseUrl()}${navPos.area ? navPos.area.uri : ''}`}>Zur Startseite
											wechseln.</Link>
									)}
								</div>
							</Fragment>
						);
					}
					break;

				case SPECIAL_PAGE_CONTENTLIST:
					mods = {list: true};
					if (details) {
						content = <Details />;
					} else {
						const itemAs = (navPos.topic && navPos.topic.deepContent) ? DeepContentListItem : undefined;
						content = features && features.length ? (
							<Fragment>
								<header><h1>{navPos.topic && navPos.topic.pageName}</h1></header>
								<List itemAs={itemAs} selectOnClick={itemAs ? true : undefined} />
							</Fragment>
						) : (
							<Fragment>
								<header><h1>{navPos.topic && navPos.topic.pageName}</h1></header>
								<p>Aktuell keine Einträge verfügbar</p>
							</Fragment>
						);
					}
					break;

				default:
					mods = {sum: true};
					const renderedFeatures = features.map(
						(feature, idx) => {
							const linkInternal = internalLink(feature.properties ? feature.properties : {});
							const {name, description, link} = feature.properties ? feature.properties : {};
							const hostname = link && (newURL(link)).hostname;
							const hr = (idx < features.length - 1) ? (<hr />) : null;
							return (
								<Fragment key={`content-feature-item-${idx}`}>
									<h2 dangerouslySetInnerHTML={{__html: name}} />
									<div
										dangerouslySetInnerHTML={{__html: description}}
									/>

									{(linkInternal || link) && <br />}
									{linkInternal &&
										<Link className="vmznds-list__more-link" to={linkInternal}>mehr ...</Link>}
									{!linkInternal && link && (
										<a className="vmznds-list__more-link--external" href={link} target="_blank">
											{hostname ? `weiter zu ${hostname} ...` : link}
										</a>
									)}
									{hr}
								</Fragment>
							);
						}
					);
					content = (
						<div
							className="ms3-feature-details-content ms3-feature-details-content--description"
						>
							<header>
								<h1>{navPos.content ? navPos.content.pageName : navPos.topic && navPos.topic.pageName}</h1>
							</header>
							{features && features.length ? renderedFeatures : <p>Aktuell keine Einträge verfügbar</p>}
						</div>
					);
			}
		} else if (status === STATUS_LOADING) {
			content = (
				<div
					className="vmznds-content vmznds-content--loading"
				>
					{translate('ui.feature-details.content-inner.loading')}
				</div>
			);
		}

		return content && (
			<ContentBox k="content" mods={mods} forMobile={forMobile}>
				{content}
			</ContentBox>
		);
	}
);
export default withErrorBoundary(Content);
