import React, {memo} from 'react';
import {useDispatch} from 'react-redux';
import {batchActions} from 'redux-batched-actions';

import Link from '../../../mapsight-vmznds/components/link';
import {getBaseUrl} from '../../../mapsight-vmznds/routing/helpers';

import {clearSuggestions, setInput} from '../store/actions';
import {NAVIGATION, NAVIGATION_URIS} from '../store/constants';

const navigationUrl = getBaseUrl() + 'navigation';

export default memo(
	/**
	 * Usage example:
	 *	<InternalRoutingLink query="Braunschweig" location={{lat: 52.2646577, lon: 10.5236066}}>
	 *		Routing nach Braunschweig
	 *	</InternalRoutingLink>
	 *
	 * @param {{
	 *   name: string,
	 *   location: import("../store/types").Location,
	 *   modality: null | import("../store/types").Location,
	 * }} props
	 */
	function NavigationLink({name, location, modality = 'car'}) {
		const dispatch = useDispatch();
		// kein useCallback auf alle Parameter, weil die Komponente als ganzes eh nur dann neu gerendert wird, wenn auch das Callback neu muss ...
		function handleClick() {
			dispatch(batchActions([
				clearSuggestions(NAVIGATION, 'dest'),
				setInput(NAVIGATION, 'dest', false, location, name, 'link', Date.now()),
			]));
		}

		return (
			<Link className="vmznds-list__more-link" to={`${navigationUrl}/${NAVIGATION_URIS[modality]}`} onClick={handleClick}>
				Route berechnen ...
			</Link>
		);
	}
);
