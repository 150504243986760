import React, {Fragment, memo, useMemo} from 'react';
import HighchartsReact from 'highcharts-react-official';
import merge from 'lodash/merge';

import {DAY_IN_MS, DEFAULT_STATION_OPTIONS, getHighcharts} from '../../../modules/highcharts';
import {localTimeTimestampFromDateTimeString} from '../../../modules/radzaehler/helpers';

import Link from '../link';

/**
 * @param {Array<{datetime: string, value: string | null}>} data
 * @returns {Array<[number, number]>}
 */
function normalizeData(data) {
	return data.map((entry) => [
		localTimeTimestampFromDateTimeString(entry.datetime),
		parseFloat(entry.value || 0),
	]);
}

/**
 * @type {import("@mapsight/ui/src/js/components/contexts.js").FeatureDetailsContent}
 */
const FeatureDetailsContentWheelCounter = memo(
	function FeatureDetailsContentAnomaly({feature}) {
		const Highcharts = getHighcharts();
		/** @type {Record<string, any>} */
		const props = (() => {
			if (
				typeof feature === 'object'
				&& feature !== null
				&& typeof feature.properties === 'object'
				&& feature.properties !== null
			) {
				return feature.properties;
			} else {
				return {};
			}
		})();

		const daily = useMemo(
			() => {
				if (
					!Array.isArray(props.wheelCountingDataDaily)
					|| props.wheelCountingDataDaily.length === 0
				) {
					return null;
				}

				const data = normalizeData(props.wheelCountingDataDaily);
				const last = data[data.length - 1];

				/** @type {import("highcharts").Options} */
				const day7 = {
					xAxis: {
						type: 'datetime',
						tickInterval: 2 * DAY_IN_MS,
						min: last[0] - (7 * DAY_IN_MS),
						max: last[0],
						dateTimeLabelFormats: {
							day: '%a',
						},
					},
					series: [
						{
							type: 'column',
							name: 'Fahrradfahrende',
							data,
						},
					],
				};

				/** @type {import("highcharts").Options} */
				const day30 = {
					xAxis: {
						type: 'datetime',
						tickInterval: 30 * DAY_IN_MS,
						min: last[0] - (30 * DAY_IN_MS),
						max: last[0],
						dateTimeLabelFormats: {
							day: '%d.%m.',
							month: '%d.%m.',
							week: '%d.%m.',
							// day: '%a',
							// month: '%a',
							// week: '%a',
							// week: 'KW%V',
						},
						// single letter weekday formatter, left for reference but even one letter is bigger than one column
						// labels: {
						// 	formatter: function () {
						// 		const SINGLE_LETTER_WEEKDAYS = ['S', 'M', 'D', 'M', 'D', 'F', 'S'];
						// 		const d=new Date(this.value);
						// 		return SINGLE_LETTER_WEEKDAYS[d.getDay()];
						// 	}
						// },
					},
					series: [
						{
							type: 'column',
							name: 'Fahrradfahrende',
							data,
						},
					],
				};

				return {
					day7: merge({}, DEFAULT_STATION_OPTIONS, day7),
					day30: merge({}, DEFAULT_STATION_OPTIONS, day30),
					last,
				};
			},
			[props.wheelCountingDataDaily]
		);

		const month24 = useMemo(
			() => {
				if (
					!Array.isArray(props.wheelCountingDataMonthly)
					|| props.wheelCountingDataMonthly.length === 0
				) {
					return null;
				}

				const data = normalizeData(props.wheelCountingDataMonthly);
				const last = data[data.length - 1];

				/** @type {import("highcharts").Options} */
				const month24options = {
					// plotOptions: {
					// 	column: {
					// 		pointPadding: 0,
					// 		groupPadding: 0,
					// 	},
					// },
					xAxis: {
						min: last[0] - (24 * 31 * DAY_IN_MS),
						max: last[0],
						// labels: {enabled: false},
					},
					tooltip: {xDateFormat: '%B %Y'},
					series: [
						{
							type: 'column',
							name: 'Fahrradfahrende',
							data,
						},
					],
				};

				return merge({}, DEFAULT_STATION_OPTIONS, month24options);
			},
			[props.wheelCountingDataMonthly]
		);

		return (
			<div className="ms3-feature-details-content ms3-feature-details-content--description">
				<span className="ms3-list__icon">
					<img
						src="/assets/img/mapsight-icons-svg/bike-default.svg"
						alt=""
					/>
				</span>

				<div className="vmznds-feature-details">
					<header>
						<h1>{props.name}</h1>
					</header>

					{daily && (() => {
						const date = new Date(daily.last[0]);
						return (
							<p>
								<br />
								{'Am '}
								{date.getDate().toString()}
								{'.'}
								{(1 + date.getMonth()).toString()}
								{'.'}
								{' gemessen: '}
								{/* TODO: format number */}
								{daily.last[1]}
								{'.'}
								<br />
								Bitte berücksichtigen Sie, dass die Daten des heutigen Tages nur mit Verzögerung vorliegen.
								<br />
								<br />
							</p>
						);
					})()}

					{daily && (
						<Fragment>
							<figure>
								<figcaption>7 Tage</figcaption>

								<HighchartsReact
									highcharts={Highcharts}
									options={daily.day7}
								/>
							</figure>

							<figure>
								<figcaption>30 Tage</figcaption>

								<HighchartsReact
									highcharts={Highcharts}
									options={daily.day30}
								/>
							</figure>
						</Fragment>
					)}

					{month24 && (
						<figure>
							<figcaption>24 Monate</figcaption>

							<HighchartsReact
								highcharts={Highcharts}
								options={month24}
							/>
						</figure>
					)}

					<p>
						<Link className="vmznds-list__more-link" to="/radverkehr/zaehler-auswertungen/">
							Weitere Datenauswertungen finden Sie hier
						</Link>
					</p>
				</div>
			</div>
		);
	}
);

export default FeatureDetailsContentWheelCounter;
