import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import useCookie from '@neonaut/use-cookie';
import {useDispatch, useSelector} from 'react-redux';
import {batchActions} from 'redux-batched-actions';

import {FEATURE_SELECTION_SELECT} from '@mapsight/ui/src/js/config/feature/selections';
import {FEATURE_SELECTIONS} from '@mapsight/ui/src/js/config/constants/controllers';
import {VIEW_FULLSCREEN, VIEW_MAP_ONLY, VIEW_MOBILE} from '@mapsight/ui/src/js/config/constants/app';

import {userPreferenceListVisibleSelector, viewSelector} from '@mapsight/ui/src/js/store/selectors';
import {setView, toggleUserPreferenceListVisible} from '@mapsight/ui/src/js/store/actions';

import {MagicContext, SSRContext} from './contexts';
import {getBaseUrl, navPosition, QS_REDUCED} from './routing/helpers';
import {deselectAllViaHistory} from './store/actions';


// TODO magic automatisches erneuern alle t
// 1. feature-source anlegen mit reload alle t
// 2. embed() legt die erhaltene magic dort ab, wo die daten beim load der feature-source lägen
// 3. embed() setzt einen timer auf t und initiiert den ersten load
// 4. sicherstellen, dass die data der feature source erhalten bleibt, wenn der load fehlschlägt
// 5. statt useMagic wird eine selector genutzt, um die daten aus dem store zu holen
/**
 * @returns {import("./types").Magic}
 */
export function useMagic() {
	return useContext(MagicContext);
}


export function useSSR() {
	return useContext(SSRContext);
}

// ist eine Abkürzung, die wie wie ein Hook verwendet wird
// eslint-disable-next-line react-hooks/rules-of-hooks
export function useIsSSR() {
	return useSSR()?.isSSR;
}

/**
 * @returns {any}
 */
export function useNavPos() {
	const magic = useMagic();
	const location = useLocation();
	return useMemo(
		() => navPosition(magic, location.pathname + location.search, location.state),
		[magic, location.pathname, location.state, location.search],
	);
}

export function useLoginState() {
	const [nnFeLogin] = useCookie('nnFeLogin', '');
	// console.log('useLoginState', {fe_typo_user: have_fe_user, cookie: getCookie('have_fe_user')});
	return nnFeLogin !== ''; // TODO check, weil Typo3 liefert Wert "false"
}

/**
 * @param {null | number} [interval] Updates returned date every `interval` millisecs. Change in
 * `interval` restarts the update interval.
 * @returns {null | Date}
 */
export function useDate(interval = null) {
	const [date, setDate] = useState(
		/** @returns {null | Date} */
		() => null,
	);

	// run only on first render
	useEffect(
		() => setDate(new Date()),
		[],
	);

	useEffect(
		() => {
			if (interval === null) {
				return undefined;
			}

			const handle = setInterval(
				() => setDate(new Date()),
				interval,
			);

			return () => {
				clearInterval(handle);
			};
		},
		[interval]
	);

	return date;
}

/**
 * calculate day and hours for current date
 *
 * @param {Date} date
 * @return {{hours: number, day: number}}
 */
function calcDayHour(date) {
	return {
		day: date.getDay(),
		hours: date.getHours(),
	};
}

/**
 * current day and hours, updates each hour
 *
 * @return {{hours: number, day: number}}
 */
export function useDayHours() {
	const [value, setValue] = useState(calcDayHour(new Date()));

	useEffect(
		() => {
			const date = new Date();
			const handle = setInterval(
				() => setValue(calcDayHour(new Date())),
				((60 - date.getMinutes()) * 60 * 1000) +
				1000 - date.getMilliseconds()
			);

			return () => {
				// this will be called most time after the intervall has ended except that important call when the component gets unmounted
				clearInterval(handle);
			};
		},
		[value.day, value.hours]
	);

	return value;
}

/**
 * @param {null | number} [interval] Updates returned timestamp every `interval` millisecs. The
 *                returned timestamp is aligned to the interval. Change in `interval` restarts the update interval.
 * @returns {null | number} timestamp in milliseconds
 */
export function useTimestamp(interval = null) {
	const date = useDate(interval);

	if (date === null) {
		return null;
	}

	if (interval === null) {
		return date.getTime();
	} else {
		return Math.round(date.getTime() / interval) * interval;
	}
}

/**
 * @param {number} interval
 * @param {null | undefined | string} url
 * @returns {null | string}
 */
export function useCacheBustingUrl(interval, url) {
	const ts = useTimestamp(interval);

	if (url === null || url === undefined) {
		return null;
	}

	if (ts === null) {
		return url;
	}

	return `${url.replace(/\/$/, '')}/${ts}/`;
}

/**
 * clears featureSelection but keeps preselection
 *   changes mode. this is for the link in feature-details-summary
 *
 * @returns {function(): void}
 */
export function useOnLinkToDetailsSource() {
	const dispatch = useDispatch();
	const view = useSelector(viewSelector);
	const sidePanelVisible = useSelector(userPreferenceListVisibleSelector);

	return useCallback(
		() => {
			if (view === VIEW_MAP_ONLY) {
				dispatch(batchActions([
					setView(VIEW_MOBILE),
					deselectAllViaHistory(
						FEATURE_SELECTIONS,
						FEATURE_SELECTION_SELECT,
					),
				]));
			} else if (view === VIEW_FULLSCREEN && !sidePanelVisible) { // = Fullscreen
				dispatch(batchActions([
					toggleUserPreferenceListVisible(),
					deselectAllViaHistory(
						FEATURE_SELECTIONS,
						FEATURE_SELECTION_SELECT,
					),
				]));
			} else {
				dispatch(
					deselectAllViaHistory(
						FEATURE_SELECTIONS,
						FEATURE_SELECTION_SELECT,
					),
				);
			}
		},
		[dispatch, view, sidePanelVisible]
	);
}


/**
 * this is for the close button of details only
 *
 * @returns {function(): void}
 */
export function useOnCloseDetails() {
	const dispatch = useDispatch();
	const history = useHistory();
	const navPos = useNavPos();
	return useCallback(
		() => {
			dispatch(deselectAllViaHistory(
				FEATURE_SELECTIONS,
				FEATURE_SELECTION_SELECT
			));
			if (navPos.topic.deepLinks) {
				history.push(
					`${getBaseUrl()}${navPos.area && !navPos.topic.noArea ? navPos.area.uri + '/' : ''}` +
					`${navPos.topic.pageUri}/` +
					`${navPos.reduced ? '?' + QS_REDUCED : ''}`,
				);
			}
		},
		[dispatch, history, navPos]
	);
	//// old code left for reference
	// const dispatch = useDispatch();
	// const featureSourceInfo = useSelector(featureSourceInfoSelector);
	// const view = useSelector(viewSelector);
	// const sidePanelVisible = useSelector(userPreferenceListVisibleSelector);
	//
	// return useCallback(
	// 	() => {
	// 		if (view === VIEW_MAP_ONLY) {
	// 			dispatch(batchActions([
	// 				deselect(
	// 					FEATURE_SELECTIONS,
	// 					FEATURE_SELECTION_SELECT,
	// 					featureSourceInfo.featureId
	// 				),
	// 			]));
	// 		} else if (view === VIEW_FULLSCREEN && !sidePanelVisible) { // = Fullscreen
	// 			dispatch(batchActions([
	// 				deselect(
	// 					FEATURE_SELECTIONS,
	// 					FEATURE_SELECTION_SELECT,
	// 					featureSourceInfo.featureId
	// 				),
	// 				// toggleUserPreferenceListVisible(),
	// 			]));
	// 		} else {
	// 			dispatch(
	// 				deselect(
	// 					FEATURE_SELECTIONS,
	// 					FEATURE_SELECTION_SELECT,
	// 					featureSourceInfo.featureId
	// 				),
	// 			);
	// 		}
	// 	},
	// 	[dispatch, featureSourceInfo, view, sidePanelVisible]
	// );
}

/**
 * @param {undefined | null | string} v
 * @returns {null | Date}
 */
export function useParseDate(v) {
	return useMemo(
		() => {
			if (v === null || v === undefined) {
				return null;
			}

			const timeStamp = Date.parse(v);
			if (Number.isNaN(timeStamp)) {
				return null;
			} else {
				return new Date(timeStamp);
			}
		},
		[v],
	);
}
