// Diese Datei gibt es nur, weil das Verwenden von FeatureList innnerhalb von Content dazu führt, dass die featureSource ständig geladen würde. wahrscheinlich wechseln sich die FeatureList aus Content und die reguläre, wenn auch unsichtbare FeatureListe immer gegenseitig ab mit dem dispatch(async(load))) und jedes load verersacht ein rerender der liste und damit ein neues load
// daher: diese list.jsx, die auf das load verzichtet (es wird ja durch die unsichtbare Liste ausgeführt)

// ach ja, es ist ein Hack. trotzdem: kein clean-up der Kommentare vor dem Launch der Site!

import {connect} from 'react-redux';
import {createSelector, createStructuredSelector} from 'reselect';

import {createListFeatureSelector, featureSourceIdSelector} from '@mapsight/core/lib/list/selectors';
import {
	getAllFeatures,
	getFeatureSourceError,
	getFeatureSourceStatus
} from '@mapsight/core/lib/feature-sources/selectors';

import {FEATURE_LIST, FEATURE_SOURCES} from '@mapsight/ui/src/js/config/constants/controllers';
import {
	isViewMobile,
	layerSwitcherShowExternalSelector,
	listUiOptionsSelector,
	tagSwitcherShowSelector,
	viewSelector,
} from '@mapsight/ui/src/js/store/selectors';

import FeatureListGroupedContent from '@mapsight/ui/src/js/components/feature-list/grouped-content';


function determineItemType(containerType) {
	if (containerType === 'table') {
		return 'tr';
	}

	if (containerType === 'ul' || containerType === 'ol') {
		return 'li';
	}

	return 'p';
}


const selector = createStructuredSelector({
	listUiOptions: listUiOptionsSelector,
	tagSwitcherShow: tagSwitcherShowSelector,
	layerSwitcherShowExternal: layerSwitcherShowExternalSelector,
	view: viewSelector,

	// scrollPosition: lastListScrollPositionSelector,
	featureSourceId: createSelector(state => state[FEATURE_LIST], featureSourceIdSelector),
	featureSource: createListFeatureSelector(FEATURE_LIST, FEATURE_SOURCES),
	// filterOptions: createStructuredSelector({
	// 	query: listQuerySelector,
	// 	sorting: listSortingSelector,
	// 	places: createSelector(
	// 		[
	// 			state => state[USER_GEOLOCATION],
	// 			placesSelector,
	// 		],
	// 		(userGeolocation, places) => ({
	// 			...places,
	// 			geolocation: {
	// 				x: userGeolocation.latitude,
	// 				y: userGeolocation.longitude,
	// 			},
	// 		})
	// 	),
	// }),
});


let lastOwnProps = null;
let lastDispatchProps = null;
let lastStatePropsP = null;


const mergeProps = (statePropsP, dispatchProps, ownProps) => {
	const {featureSource, filterOptions, listUiOptions, view, ...stateProps} = statePropsP;
	const {itemAs} = ownProps;
	const selectOnClick = ownProps.selectOnClick || ownProps.selectOnClick === false ?
		ownProps.selectOnClick : listUiOptions.selectOnClick;
	const features = getAllFeatures(featureSource);
	// const filteredFeatures = features; // filterFeatures(filterOptions.query, features);
	const sortedFeatures = features; //sortFeaturesByDistance(filteredFeatures, filterOptions.places, filterOptions.sorting);
	const {
		detailsInList,
		// showVaryingListInfoOnly,
		renderGroupAs,
		renderAs = 'table',
	} = listUiOptions;
	const {renderItemAs = determineItemType(renderAs)} = listUiOptions;


	if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
		console.log('list.jsx mergeProps', {
			dispatchProps,
			ownProps,
			stateProps,
			resultProps: {
				...dispatchProps,
				...ownProps,
				...stateProps,
				status: getFeatureSourceStatus(featureSource),
				error: getFeatureSourceError(featureSource),
				features: features,
				filteredFeatures: sortedFeatures,
			}
		}, lastStatePropsP !== statePropsP, lastDispatchProps !== dispatchProps, lastOwnProps !== ownProps);
	}

	lastStatePropsP = statePropsP;
	lastDispatchProps = dispatchProps;
	lastOwnProps = ownProps;

	return ({
		...dispatchProps,
		...ownProps,
		...stateProps,
		status: getFeatureSourceStatus(featureSource),
		error: getFeatureSourceError(featureSource),
		features: features,
		filteredFeatures: sortedFeatures,
		itemProps: {
			as: itemAs || renderItemAs,
			// showFeatureListInfo: showFeatureListInfo,
			isMobile: isViewMobile(view),
			forceShowDetails: detailsInList,
			selectedOnly: listUiOptions.showSelectedOnly,
			selectOnClick: selectOnClick,
			deselectOnClick: listUiOptions.deselectOnClick,
			highlightOnMouse: listUiOptions.highlightOnMouse,
			// topOffset: topOffset,
		},
		groupAs: renderGroupAs,
		as: renderAs,
		itemAs: itemAs || renderItemAs,
		// showFeatureListInfo={showFeatureListInfo}
	});
};

export default connect(selector, null, mergeProps)(FeatureListGroupedContent);
