import React, {forwardRef, memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import getPath from '@neonaut/lib-js/es/object/getPath';

import usePrevious from '@mapsight/ui/src/js/hooks/usePrevious';
import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';

import {setNavigationOpen} from '../store/actions';
import {featureSelectionSelector, navOpenSelector} from '../store/selectors';


export default memo(forwardRef(
	function MenuButton({}, ref) {
		const dispatch = useDispatch();
		const open = useSelector(navOpenSelector);

		// close menu on (map)-selection. Nachteil dieser Lösung ist, dass beim Reselect das Menü offen bleibt.
		const featureSelections = useSelector(featureSelectionSelector);
		const oldFeatureSelections = usePrevious(featureSelections);
		useEffect(function () {
				if (open && featureSelections !== oldFeatureSelections && getPath(featureSelections, ['features', 'length'])) {
					dispatch(setNavigationOpen(false));
				}
			},
			[open, featureSelections, oldFeatureSelections, dispatch]
		);

		const toggleOpen = useCallback(
			function toggleOpen() {
				dispatch(setNavigationOpen(!open));
			},
			[open, dispatch],
		);

		return (
			<button
				onClick={toggleOpen}
				className="vmznds-menu-button"
				title="Hauptmenü"
				aria-label="Hauptmenü"
				aria-controls="vmznds-menu-nav"
				aria-expanded={open}
				ref={ref}
			>
				<div className="vmznds-menu-button__img-wrapper">
					<div
						className={modClasses('vmznds-menu-button__img', {
							menu: true,
							visible: !open,
						})}
					/>
					<div
						className={modClasses('vmznds-menu-button__img', {
							'menu-close': true,
							visible: open,
						})}
					/>
				</div>

				<span className="visuallyhidden">
					{open ? 'Menü schließen' : 'Menü öffnen und zum ersten Eintrag springen'}
				</span>
				<span className="vmznds-menu-button__text" aria-hidden="true">
					Menü
				</span>
			</button>
		);
	}
));
