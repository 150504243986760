/**
 * @typedef {"u" | "s" | "bus" | "rb" | "re" | "db" | "ast"} Mode
 */

/**
 * @typedef {"outward" | "inward"} Direction
 */

/**
 * @typedef {{
 *   dt: Date,
 *   type: "estimated" | "planned",
 * }} StopDeparture
 */

/**
 * @typedef {{
 *   description: null | string,
 *   name: string,
 *   mode: Mode,
 *   modeName: string,
 *   lineName: string,
 *   direction: Direction,
 *   destinationName: string,
 *   departures: Array<StopDeparture>,
 * }} Stop
 */

import {isDefined, isRecord, tryEnsureNonEmptyString} from '../../mapsight-vmznds/helpers';

/**
 * @param {any} val
 * @returns {null | Mode}
 */
function tryToMode(val) {
	if (typeof val !== 'string') {
		return null;
	}

	if (
		val === 'u'
		|| val === 's'
		|| val === 'bus'
		|| val === 'rb'
		|| val === 're'
		|| val === 'db'
		|| val === 'ast'
	) {
		return val;
	} else {
		return null;
	}
}

/**
 * @param {any} val
 * @returns {null | Direction}
 */
function tryToDirection(val) {
	if (typeof val !== 'string') {
		return null;
	}

	if (val === 'outward' || val === 'inward') {
		return val;
	} else {
		return null;
	}
}

/**
 * @param {Record<string, any>} props
 * @return {Array<Stop>}
 */
export default function getStops({stopEvents}) {
	if (!Array.isArray(stopEvents)) {
		return [];
	}

	const stops = stopEvents
		.filter(isRecord)
		.map((stop) => {
			const mode = tryToMode(stop.mode);
			const direction = tryToDirection(stop.direction);

			if (
				typeof stop.name !== 'string'
				|| mode === null
				|| typeof stop.modeName !== 'string'
				|| typeof stop.lineName !== 'string'
				|| direction === null
				|| typeof stop.destinationName !== 'string'
				|| !Array.isArray(stop.departures)
			) {
				return null;
			}

			const departures = stop.departures
				.filter(isRecord)
				.map(({dt, type}) => {
					if (
						typeof dt !== 'string'
						|| typeof type !== 'string'
						|| (type !== 'estimated' && type !== 'planned')
					) {
						return null;
					}

					try {
						/** @type {StopDeparture} */
						const dep = {
							dt: new Date(dt),
							type,
						};
						return dep;
					} catch (err) {
						return null;
					}
				})
				.filter(isDefined);

			// FIX für: Hauptlinien ohne Timetable werden aktuell komplett verschwiegen. Sie müssen trotzdem angeführt werden!
			// FEHLER: das führt dazu, dass solche Stops überhaupt nicht mehr erwähnt werden.
			// Der Fall sollte bei korrekter Konfiguration nie auftreten, aber kann etwa bei Fehlern von EFA eintreten
			// if (departures.length === 0) {
			// 	// ignore stops with 0 events
			// 	return null;
			// }

			// noinspection UnnecessaryLocalVariableJS
			/** @type {Stop} */
			const res = {
				name: stop.name,
				description: tryEnsureNonEmptyString(stop.description),
				mode,
				modeName: stop.modeName,
				lineName: stop.lineName,
				direction,
				destinationName: stop.destinationName,
				departures,
			};
			return res;
		})
		.filter(isDefined);

	// @ts-ignore
	return stops;
}
