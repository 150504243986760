import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';

import {createListFeatureSelector} from '@mapsight/core/lib/list/selectors';
import getPath from '@neonaut/lib-js/es/object/getPath';

import {FEATURE_LIST_TWO} from '../../store/controller';

import ListSummaryInner from './list-summary-inner';

const totalCountSelector = createSelector(
	createListFeatureSelector(FEATURE_LIST_TWO, 'featureSources'),
	(source) => getPath(
		source,
		['data', 'features', 'length']
	)
);

export default memo(
	function ListTwoSummary({}) {
		const totalCount = useSelector(totalCountSelector);

		// TODO entsprechend source der zweiten Liste, derzeit harcoded, prüfen
		const topic = {name: 'Baustellen', pageUri: 'baustellen-aktuell', maxArea: 'niedersachsen'};

		return (
			<ListSummaryInner
				totalCount={totalCount}
				topic={topic}
			/>
		);
	}
);
