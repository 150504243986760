import React, {Fragment, memo} from 'react';
import {useSelector} from 'react-redux';

import {isViewMapOnlySelector, userPreferenceListVisibleSelector} from '@mapsight/ui/src/js/store/selectors';

import {isDesktopSelector, pageCamerasSelector, pageContentSelector} from '../store/selectors';
import {withErrorBoundary} from '../../modules/error-boundary';
import {memoForever} from '../helpers';

import AreaSwitcher from './area-switcher';
import Logo from './logo';
import Content from './content';


/** @type {import("@mapsight/ui/src/js/components/contexts").MapOverlayStart} */
const MapOverlayStart = memo(function MapOverlayStart({}) {
		const isMapOnly = useSelector(isViewMapOnlySelector);
		const isDesktopOrFullscreen = useSelector(isDesktopSelector);
		const isVMZDesktop = useSelector(userPreferenceListVisibleSelector);
		const pageContent = useSelector(pageContentSelector);
		const isPageCamera = useSelector(pageCamerasSelector);

		return (
			<Fragment>
				{((isDesktopOrFullscreen && !isVMZDesktop) || isMapOnly) && <Logo small={true} />}
				{isDesktopOrFullscreen && isVMZDesktop && <AreaSwitcher />}

				{(pageContent || isPageCamera) && <Content />}
			</Fragment>
		);
	},
	memoForever // mapsight sets parameters for this component, memoForever changes to get them ignored
);

export default withErrorBoundary(MapOverlayStart);
