import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {listTwoTitleSelector, listTwoVisibleSelector} from '../../store/selectors';
import {useNavPos} from '../../hooks';

import PanelBox from '../boxes/panel-box';

import ListTwo from './list-two';
import ListTwoSummary from './list-two-summary';

export default memo(
	/**
	 * @param {{}} props
	 * @returns {null | React.ReactElement}
	 */
	function ListTwoWrapper({}) {
		// TODO Icon for headline. use unified code for headline
		const navPos = useNavPos();

		const visible = useSelector(listTwoVisibleSelector);
		const title = useSelector(listTwoTitleSelector);

		if (!visible && !navPos.error404) {
			return null;
		}

		// TODO entsprechend source der zweiten Liste, derzeit harcoded
		const href = `${navPos.areaFound ? navPos.area.uri : 'niedersachsen'}/baustellen-aktuell`;

		return (
			<PanelBox
				k="list-two"
				headline={title}
				href={href}
				footer={!navPos.error404 && <ListTwoSummary />}
				autoClose={true}
				mods={['body-no-scroll']}
			>
				{!navPos.error404 && <ListTwo />}
			</PanelBox>
		);
	}
);
