import React, {memo} from 'react';

import {calculateMaxAreas} from '../../routing/helpers';
import {useMagic} from '../../hooks';
import {memoForever} from '../../helpers';

import Link from '../link';

import ContentBox from './box';


class Section {
	constructor(name) {
		this.name = name;
		this.additionalContents = [];
		this.topics = [];
	}
}

const ContentSitemap = memo(
	function ContentSitemap() {
		const magic = useMagic();
		const {maxAreas, areasByUri, areasList} = calculateMaxAreas(magic.areas);

		// kein useMemo, weil die ganz Komponente ja eh nur von useMagic abhängt

		const sections = {};

		magic.topics.forEach(t => {
			if(t.name !== 'config' && t.name !== 'Nachricht') {
				const section = sections[t.menu] || new Section(t.menu);
				sections[t.menu] = section;
				section.topics.push(t);
			}
		});

		// hidden werden unten direkt ausgegeben
		magic.additionalContent.forEach(c => {
			if(!c.authorization && !c.hidden) {
				const section = sections[c.menu] || new Section(c.menu);
				sections[c.menu] = section;
				section.additionalContents.push(c);
			}
		});

		const sectionedContent = Object.keys(sections).map(s => {
			const section = sections[s];
			const entries = [
					...section.additionalContents.map(c => (<li key={c.url}><Link to={'/'+c.url}>{c.pageName}</Link></li>)),
					...section.topics.map(t => {
						if(t.noArea) {
							return (<li key={t.pageUri}><Link to={'/'+t.pageUri}>{t.pageName}</Link></li>);
						}

						const areas = t.maxArea ? maxAreas[t.maxArea] : areasList;
						const areaEntries = areas.map(area => {
							const a=areasByUri[area];
							return (<li key={`${a.uri}/${t.pageUri}`}>
								<Link to={`/${a.uri}/${t.pageUri}`}>
									{t.pageName} {a.name}
								</Link>
							</li>);
						});

						return (
							<li key={t.uri}>
								<span>{t.pageName}</span>
								<ul>
									{areaEntries}
								</ul>
							</li>
						);
					})
				];

			return (
				<li key={section.name}>
					<h3>{section.name}</h3>
					<ul>
						{entries}
					</ul>
				</li>
			);
		});

		// TODO Urgent-Seiten immer oder nur wenn dort was steht?

		return (
			<ContentBox>
				<div
					className="ms3-feature-details-content ms3-feature-details-content--description vmznds-content__details"
				>
					<nav aria-labelledby="h1sitemap">
						<header>
							<h1 id="h1sitemap">Inhaltsübersicht</h1>
						</header>
						<h2>Verkehrsmanagementzentrale Niedersachsen / Region Hannover</h2>
						<ul>
							{magic.areas.map(area => (<li key={area.uri}><Link to={'/'+area.uri}>Startseite {area.name}</Link></li>))}
							{magic.additionalContent.filter(c => c.hidden && !c.authorization && !Object.keys(sections).includes(c.pageName)).map(c => (<li key={c.url}><Link to={'/'+c.url}>{c.pageName}</Link></li>) )}
							{sectionedContent}
						</ul>
					</nav>
				</div>
			</ContentBox>
		);
	},
	memoForever
);

export default ContentSitemap;
