import {German as flatpickrDe} from 'flatpickr/dist/l10n/de';
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import {useQuery} from '@tanstack/react-query';

import range from 'lodash/range';
import React, {useCallback, useMemo, useState} from 'react';

import Flatpickr from 'react-flatpickr';

import config from '../config';
import {dateToYmd} from '../helpers';
import objectToQueryString from '../helpers/object-to-query-string';
import loadJson from '../helpers/xhr-json';

import Chart from './Chart';

async function getData({limit, resolution, startDateYmd, stationIds}) {
	const params = new URLSearchParams();
	if (limit) params.set('limit', limit);
	if (startDateYmd) params.set('startDate', startDateYmd);
	if (stationIds) params.set('stationIds', stationIds);

	return new Promise((resolve, reject) => {
		loadJson(`${config.API_URL}last-values/${resolution}?${params}`, (data) => {
			resolve(data);
		}, (err) => {
			reject(err);
		});
	});
}

function useData({limit, resolution, startDateYmd, stationIds}) {
	return useQuery({
		queryKey: ['chart data', limit, resolution, startDateYmd, stationIds],
		queryFn: () => getData({limit, resolution, startDateYmd, stationIds}),
		enabled: stationIds.length > 0,
		refetchOnWindowFocus: false,
		keepPreviousData: true,
	}).data;
}

async function getProblems({limit, resolution, startDateYmd, stationIds}) {
	const params = new URLSearchParams();
	if (limit) params.set('limit', limit);
	if (startDateYmd) params.set('startDate', startDateYmd);
	if (stationIds) params.set('stationIds', stationIds);

	return new Promise((resolve, reject) => {
		loadJson(`${config.API_URL}last-problems/${resolution}?${params}`, (data) => {
			resolve(data);
		}, (err) => {
			reject(err);
		});
	});
}

function useProblems({limit, resolution, startDateYmd, stationIds}) {
	return useQuery({
		queryKey: ['chart problems', limit, resolution, startDateYmd, stationIds],
		queryFn: () => getProblems({limit, resolution, startDateYmd, stationIds}),
		enabled: stationIds.length > 0,
		refetchOnWindowFocus: false,
		keepPreviousData: true,
	}).data;
}

const ChartWrapper = function ChartWrapper({
	showStationsList,
	stations,
	resolution,
	limit,
	type,
}) {
	const [startDateYmd, setStartDateYmd] = useState(null);
	const [flatpickrValue, setFlatpickrValue] = useState(null);

	const stationIds = useMemo(function () {
		return stations.map(({id}) => id).join(',');
	}, [stations]);

	const handleYearSelect = useCallback(function (e) {
		const year = parseInt(e.target.value, 10);
		if (year) {
			setStartDateYmd(year + '-01-01');
		}
	}, []);

	const data = useData({limit, resolution, startDateYmd, stationIds});
	const problems = useProblems({limit, resolution, startDateYmd, stationIds});

	const submit = useCallback(e => {
		e.preventDefault();
	}, []);

	const handleDateChange = useCallback(function (selectedDates) {
		setFlatpickrValue(selectedDates);
		setStartDateYmd(dateToYmd(selectedDates[0]));
	}, [setFlatpickrValue, setStartDateYmd]);

	const reset = useCallback(function (e) {
		e.preventDefault();
		setStartDateYmd(null);
		setFlatpickrValue(null);
	}, [setStartDateYmd, setFlatpickrValue]);

	const downloadHref = useMemo(function () {
		return `${config.API_URL}last-values/${resolution}?${objectToQueryString({
			format: 'csv',
			startDate: startDateYmd,
			limit: limit,
			stationIds: stationIds,
		})}`;
	}, [startDateYmd, resolution, limit, stationIds]);

	const flatpickrOptions = useMemo(function () {
		return {
			disableMobile: true,
			dateFormat: 'd.m.Y',
			locale: flatpickrDe,
			minDate: new Date(config.EARLIEST_YEAR + '-01-01T00:00:00'),
			maxDate: resolution === 'daily' ? new Date().fp_incr(-(limit - 1)) : null,
			plugins: resolution === 'monthly' ? [new MonthSelectPlugin({
				shorthand: true,
				dateFormat: 'm.Y',
			})] : [],
			onChange: handleDateChange,
			onValueUpdate: (selectedDates) => {
				if (selectedDates.length) {
					handleDateChange(selectedDates);
				}
			},
		};
	}, [resolution, limit, handleDateChange]);

	let showDateSelect = true;
	let years = null;
	if (resolution === 'yearly') {
		years = range(config.EARLIEST_YEAR, 1 + (new Date()).getFullYear() - limit);
		showDateSelect = years.length > 1;
	}

	return (
		<div className="vmznds-chart-wrapper">
			<div className="vmznds-chart-buttons">
				{showDateSelect ? (
					<form className="vmznds-chart-form" onSubmit={submit}>
						{years ? (
							<select
								className="vmznds-chart-date-selector"
								onBlur={handleYearSelect}
							>
								<option key="empty" value="">Zeitraum auswählen …</option>
								{years.map(year => (
									<option key={year} value={year}>{year} bis {year + limit - 1}</option>
								))}
							</select>
						) : (
							<Flatpickr
								className="vmznds-chart-date-selector"
								placeholder="Zeitraum auswählen …"
								options={flatpickrOptions}
								value={flatpickrValue}
							/>
						)}

						{/* <button className="vmznds-chart-button vmznds-chart-button--submit" type="submit" onClick={submit}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452 452" width="12" height="12">
								<path d="M345.4 248.3L151.2 442.6a31.6 31.6 0 11-44.8-44.8l172-171.9L106.3 54a31.6 31.6 0 0144.8-44.7l194.2 194.3a31.5 31.5 0 010 44.7z" />
							</svg>

							<span className="visuallyhidden">Auswahl ändern</span>
						</button>*/}

						{startDateYmd ? (
							<button className="vmznds-chart-button vmznds-chart-button--reset" type="reset"
									onClick={reset}
									title="Auswahl zurücksetzen">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461 461" width="12" height="12">
									<path fill="#444"
										  d="M285 230L456 59c6-6 6-16 0-22L424 5a16 16 0 00-22 0L230 176 59 5a16 16 0 00-22 0L5 37c-7 6-7 16 0 22l171 171L5 402c-6 6-6 15 0 21l32 33a16 16 0 0022 0l171-171 172 171a16 16 0 0021 0l33-33c6-6 6-15 0-21L285 230z" />
								</svg>
								<span className="visuallyhidden">Auswahl zurücksetzen</span>
							</button>
						) : null}
					</form>
				) : (
					<span />
				)}

				<div>
					<a className="vmznds-chart-button vmznds-chart-button--download"
					   href={downloadHref} rel="download" target="_blank" title="Daten als CSV-Datei herunterladen …">
						CSV herunterladen …
					</a>
				</div>
			</div>

			<Chart
				type={type}
				stations={stations}
				showStationList={showStationsList}
				data={data}
				problems={problems}
				resolution={resolution}
			/>
		</div>
	);
};

export default ChartWrapper;
