import React, {forwardRef, Fragment, memo} from 'react';

import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';
import {withKeyboard} from '@mapsight/ui/src/js/components/feature-list/with-keyboard';

import {getBaseUrl} from '../../routing/helpers';
import {history} from '../../renderer/browser-router';

import CloseButton from '../close-button';
import Link from '../link';

import {PanelBoxBox} from './panel-box-box';


const DivWithKeyboard = memo(withKeyboard(
	(
		{children, filteredFeatures, divRef, ...props}
	) => (
		<div ref={divRef} {...props}>
			{children}
		</div>
	)
));



const PanelBox = memo(forwardRef(
	/**
	 * @param {{
	 *   k?: string,
	 *   headline?: React.ReactNode,
	 *   headlineTag?: string
	 *   header?: React.ReactNode,
	 *   children?: React.ReactNode,
	 *   footer?: React.ReactNode,
	 *   href?: string,
	 *   onClose?: () => void,
	 *   autoClose?: boolean,
	 *   urgent?: "school" | "alert" | "green" | "info",
	 *   height?: "keep",
	 *   mods?: Array<"body-no-scroll" | "main" | null | undefined>,
	 *   filteredFeatures?: Array<object>,
	 *   tabIndex?: number
	 * }} props
	 * @param {React.RefObject} ref
	 * @returns {null | React.ReactElement}
	 */
	function PanelBox({
		bodyRef,
		k = '',
		headline,
		headlineTag: HeadlineTag = 'h2',
		header,
		children,
		footer,
		href,
		onClose,
		autoClose = false,
		urgent,
		height,
		isNav = false,
		mods = [],
		filteredFeatures,
		tabIndex,
	}, ref) {
		const baseUrl = getBaseUrl();
		const headlineId = 'h-' + k;

		if (!headline && !children) {
			return null;
		}

		let wrappedHeadline = href ?
			(<Link
				tabIndex={-1}
				id={headlineId}
				className="vmznds-panel-box__headline-inner"
				to={`${baseUrl}${href}`}
			>{headline}</Link>) :
			(<span
				id={headlineId}
				className="vmznds-panel-box__headline-inner"
			>{headline}</span>);

		if (href || headline || onClose) {
			const onKeyDown = !href ? undefined :
				(ev) => {
					if(ev.key === 'Enter') {
						history.push('/'+href);
					}
				};
			wrappedHeadline = (
				<div className="vmznds-panel-box__headline-wrapper" tabIndex={href ? 0 : undefined} onKeyDown={onKeyDown} >
					<HeadlineTag className="vmznds-panel-box__headline">
						{wrappedHeadline}
					</HeadlineTag>

					{onClose && (
						<div className="vmznds-panel-box__headline-close-button">
							<CloseButton
								onClick={onClose}
								shortDescription="Informationsdialog schließen"
							/>
						</div>
					)}
				</div>
			);
		}

		let content = (
			<Fragment>
				{wrappedHeadline}

				{header && (
					<header className="vmznds-panel-box__header">
						{header}
					</header>
				)}

				{children && (
					<DivWithKeyboard divRef={bodyRef} className="vmznds-panel-box__body" filteredFeatures={filteredFeatures}>
						{filteredFeatures &&
							<span className="visuallyhidden">Die Liste kann man mit Cursor auf und ab durchblättern</span>
						}
						{children}
					</DivWithKeyboard>
				)}

				{footer && (
					<footer className="vmznds-panel-box__footer" aria-labelledby={headlineId} >
						{footer}
					</footer>
				)}
			</Fragment>
		);
		if (bodyRef && !children) {
			bodyRef.current = null;
		}

		const classNameInner = modClasses('vmznds-panel-box', [
			`key-${k}`,
			urgent && `urgent-${urgent}`,
			autoClose && 'auto-close',
			...mods,
		]);

		if(isNav) {
			content = (<nav
				aria-labelledby={headlineId}
				className={classNameInner}
				tabIndex="-1"
			>{content}</nav>);
		} else {
			content = (<div className={classNameInner}>{content}</div>);
		}

		return (
			<PanelBoxBox
				ref={ref}
				k={k}
				tabIndex={tabIndex}
				height={
					height ? height :
						(autoClose || !children) ? 'limit' :
							'expand'
					}
			>
				{content}
			</PanelBoxBox>
		);
	}
));

export default PanelBox;
