import React, {Fragment, memo, useMemo} from 'react';

import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';

import {isRecord, tryEnsureNonEmptyString} from '../../../helpers';
import {getOccupationIcon, getOccupationText} from '../../../../modules/p+r-utilization/occupation';

import NavigationLink from '../../../../modules/navigation/components/navigation-link';


function calcAddressAndAddressText(props) {
	const address = tryEnsureNonEmptyString(props.address);
	const zip = tryEnsureNonEmptyString(props.zip);
	const city = tryEnsureNonEmptyString(props.city);

	if (address === null && zip === null && city === null) {
		return null;
	}

	return {
		address: (
			<Fragment>
				{address}
				{(address && (zip || city)) && ', '}
				{(address && (zip || city)) && <br />}
				{zip}
				{(zip && city) && ' '}
				{city}
			</Fragment>
		),
		addressText: `${address ? address : ''}${(address !== null && (zip !== null || city !== null)) ? ', ' : ''}` +
			`${zip ? zip : ''}${(zip !== null && city !== null) ? ' ' : ''}${city ? city : ''}`,
	};
}

function renderImage(props) {
	if (
		!Array.isArray(props.images)
		|| props.images.length < 1
		|| !isRecord(props.images[0])
	) {
		return null;
	}

	const img = props.images[0];
	const url = tryEnsureNonEmptyString(img.url);
	if (url === null) {
		return null;
	}

	return (
		<img
			className="vmznds-feature-details-pundr__image"
			src={url}
			alt={tryEnsureNonEmptyString(img.alternative) || undefined}
		/>
	);
}


const Heading = memo(
	function Heading({feature, occupation, isBike}) {
		const {
			occupationIcon,
			occupationText,
			image,
			address,
			addressText,
			props,
		} = useMemo(
			() => ({
				...calcAddressAndAddressText(feature.properties),
				occupationIcon: getOccupationIcon(occupation),
				occupationText: getOccupationText(occupation),
				image: renderImage(feature.properties),
				props: feature.properties,
			}),
			[feature, occupation]
		);

		// coordinates muss 2 und darf 3 Werte haben, der dritter ist die Höhe
		const coords = feature.geometry && feature.geometry.coordinates && feature.geometry.coordinates.length >= 2 ?
			feature.geometry.coordinates : null;

		return (
			<Fragment>
				<header className="vmznds-feature-details-pundr__header">
					<div className="vmznds-feature-details-pundr__headline-icons">
						<h1 className="vmznds-feature-details-pundr__headline">
							{props.name}
						</h1>

						<div className="vmznds-feature-details-pundr__icons">
							<img
								className={modClasses(
									'vmznds-feature-details-pundr__icon',
									'pundr',
								)}
								src={`/assets/img/mapsight-icons-svg/${props.mapsightIconId ? props.mapsightIconId : 'pundr'}-default.svg`}
								alt=""
							/>

							{occupationIcon !== null && (
								<img
									className={modClasses(
										'vmznds-feature-details-pundr__icon',
										'occupation',
									)}
									src={`/assets/img/parking-dynamic/${occupationIcon}.png`}
									title={occupationText || undefined}
									alt=""
								/>
							)}
						</div>
					</div>

					<p className="vmznds-feature-details-pundr__total-capacity">
						{occupation.totalCapacity} Stellplätze
					</p>

					{(address || coords) && (
						<p className="vmznds-feature-details-pundr__address">
							{address}
							{address && coords && <br />}
							{coords && (
								<NavigationLink
									name={addressText || props.name || (isBike ? 'B+R' : 'P+R')}
									location={{lat: coords[1], lon: coords[0]}}
									modality={isBike ? 'bike' : 'car'}
								/>
							)}
						</p>
					)}

					{image}

					{occupationText !== null && (
						<p>
							{occupationText}
						</p>
					)}
				</header>

				<section className="vmznds-feature-details-pundr__description">
					{typeof props.description === 'string' && (
						<div dangerouslySetInnerHTML={{__html: props.description}} />
					)}
				</section>
			</Fragment>
		);
	}
);

export default Heading;
