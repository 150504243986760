import React, {memo} from 'react';

import UtilizationEntry from './entry';

const ParkingArea = memo(
	/**
	 * @param {{area: import('../../../../modules/p+r-utilization/pundr').Area}} props
	 */
	function ParkingArea({area, isBike}) {
		return (
			<div className="vmznds-feature-details-pundr__area-content">
				Plätze für:

				<dl className="vmznds-feature-details-pundr__area-slots">
					{area.motorVehicle && (
						<UtilizationEntry name={isBike ? 'Fahrräder' : 'PKW'} {...area.motorVehicle} />
					)}

					{area.disabled && (
						<UtilizationEntry name="Mobilitätseingeschränkte" {...area.disabled} />
					)}

					{area.woman && (
						<UtilizationEntry name="Frauenparkplätze" {...area.woman} />
					)}

					{area.charging && (
						<UtilizationEntry name="E-Ladesäulen" {...area.charging} />
					)}
				</dl>
			</div>
		);
	}
);

export default ParkingArea;
