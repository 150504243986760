import React, {memo} from 'react';

import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';


/**
 * @type {import("@mapsight/ui/src/js/components/contexts.js").FeatureDetailsContent}
 */
const FeatureDetailsContentSchul = memo(
	function FeatureDetailsContentSchul({feature}) {
		if (typeof feature !== 'object' || feature === null) {
			feature = {};
		}
		let props = feature.properties;
		if (typeof props !== 'object' || props === null) {
			props = {};
		}

		return (
			<article className={modClasses('vmznds-feature-details-schul', 'details')}>
				<header>
					{typeof props.place === 'string' && (
						<h2 className="vmznds-feature-details-schul__place">
							{props.place}
						</h2>
					)}

					{typeof props.schulName === 'string' && (
						<h1 className="vmznds-feature-details-schul__schul">
							{props.schulName}
						</h1>
					)}
				</header>

				{typeof props.body === 'string' && (
					<div
						className="vmznds-feature-details-schul__body"
						dangerouslySetInnerHTML={{__html: props.body}}
					/>
				)}

				{typeof props.pubDateFmt === 'string' && (
					<div className="vmznds-feature-details-schul__datetime">
						<time
							dateTime={typeof props.pubDate === 'string' ? props.pubDate : undefined}
						>
							{props.pubDateFmt}
						</time>
					</div>
				)}
			</article>
		);
	}
);

export default FeatureDetailsContentSchul;
