import {useDispatch, useSelector} from 'react-redux';
import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';

import Flatpickr from 'react-flatpickr';
import {German as flatpickrDe} from 'flatpickr/dist/l10n/de';
import FlatpickrConfirm from 'flatpickr/dist/plugins/confirmDate/confirmDate';

import {async} from '@mapsight/core/lib/base/actions';

import {memoForever} from '../../../mapsight-vmznds/helpers';

import {datetimeSelector} from '../store/selectors';
import {setDatetime} from '../store/actions';

import InputContainer from './input-container';


// auf 15 Minuten genau, aber 10 Minuten in der Zukunft
const getCurrentDate = () => new Date(Math.round((Date.now() + 600000) / 900000) * 900000);

export default memo(function DatePicker() {
		const dispatch = useDispatch();

		const [currentDatetime, setCurrentDatetime] = useState(getCurrentDate());
		const datetime = useSelector(datetimeSelector);
		const haveDateTime = !!datetime; // Schmitt-Trigger

		useEffect(
			() => {
				if (!haveDateTime) {
					dispatch(async(setDatetime(currentDatetime))); // async verschiebt die Verarbeitung nach hinten
				}
			},
			[haveDateTime, currentDatetime, dispatch]
		);

		console.log('date-picker', {datetime, currentDatetime, haveDateTime});

		const resetDatetime = useCallback(() => {
			const date = getCurrentDate();
			setCurrentDatetime(date);
			dispatch(setDatetime(date));
		}, [dispatch]);


		const updateDatetime = useCallback(dates => {
			const newDatetime = Array.isArray(dates) && dates[0] ? dates[0] : null;
			dispatch(setDatetime(newDatetime));
		}, [dispatch]);

		const flatpickrPlugins = useMemo(() => [FlatpickrConfirm({
			showAlways: true,
		})], []);

		return (
			<InputContainer
				for="navigation-datetime"
				reset={
					datetime && datetime.getTime() !== currentDatetime.getTime() &&
					resetDatetime
				}
				label="Datum und Zeit:"
			>
				<Flatpickr
					value={datetime || currentDatetime}
					id="navigation-datetime"
					className="ms3-input-container__element"
					placeholder="Datum und Zeit …"
					options={{
						clickOpens: true,
						dateFormat: 'j.n.Y H:i',
						enableTime: true,
						minuteIncrement: 15,
						locale: flatpickrDe,
						time_24hr: true,
						plugins: flatpickrPlugins,
						defaultDate: currentDatetime,
					}}
					onChange={updateDatetime}
				/>
			</InputContainer>
		);
	}, memoForever
);
