import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {isViewMobileOrMapOnlySelector} from '@mapsight/ui/src/js/store/selectors';
import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';

import {getBaseUrl} from '../routing/helpers';
import {useDate} from '../hooks';

import Link from './link';


const FooterNavigation = memo(
	function FooterNavigation({}) {
		const baseUrl = getBaseUrl();
		const isMobile = useSelector(isViewMobileOrMapOnlySelector);
		const date = useDate();

		return (
			<footer className={modClasses('vmznds-footer-navigation', {fixed: !isMobile})}>
				<ul className="vmznds-footer-navigation__list">
					<li className="vmznds-footer-navigation__list-entry">
						<Link
							to={`${baseUrl}wissenswertes/bitv`}
							className="vmznds-footer-navigation__link"
						>
							Barrierefreiheit
						</Link>
					</li>
					<li className="vmznds-footer-navigation__list-entry">
						<Link
							to={`${baseUrl}site-map`}
							className="vmznds-footer-navigation__link"
						>
							Inhaltsübersicht
						</Link>
					</li>
					<li className="vmznds-footer-navigation__list-entry">
						<Link
							to={`${baseUrl}impressum`}
							className="vmznds-footer-navigation__link"
						>
							Impressum
						</Link>
					</li>

					<li className="vmznds-footer-navigation__list-entry">
						<Link
							to={`${baseUrl}datenschutz`}
							className="vmznds-footer-navigation__link"
						>
							Datenschutz
						</Link>
					</li>

					<li className="vmznds-footer-navigation__list-entry">
							{'© VMZ Niedersachsen '}
							{date !== null && `${date.getFullYear()}`}
					</li>
				</ul>
			</footer>
		);
	}
);
export default FooterNavigation;
