import React, {useCallback, useMemo, useState} from 'react';

import {getFirstDayOfLastYear, getLastDayOfLastYear} from '../../helpers';
import loadJson from '../../helpers/xhr-json';
import config from '../../config';
import objectToQueryString from '../../helpers/object-to-query-string';

import {SelectionStep} from './SelectionStep';
import {ResultStep} from './ResultStep';

const DEFAULT_RESOLUTION = 'daily';

const Wizard = function Wizard({stations}) {
	const [step, setStep] = useState(0);
	const [data, setData] = useState(null);
	const [problems, setProblems] = useState(null);
	const [startDateYmd, setStartDateYmd] = useState(getFirstDayOfLastYear);
	const [endDateYmd, setEndDateYmd] = useState(getLastDayOfLastYear);
	const [resolution, setResolution] = useState(DEFAULT_RESOLUTION);
	const [selectedStationsIds, setSelectedStations] = useState([]);

	const stationIds = useMemo(function () {
		return stations.map(({id}) => id).join(',');
	}, [stations]);
	const submit = useCallback((e) => {
		e.preventDefault();
		setStep(1);

		loadJson(`${config.API_URL}values/${startDateYmd}/${endDateYmd}/${resolution}?${objectToQueryString({stationIds})}`,
			response => {
				setData(response);
			}
		);

		loadJson(`${config.API_URL}problems/${startDateYmd}/${endDateYmd}?${objectToQueryString({stationIds})}`,
			response => {
				setProblems(response);
			}
		);
	}, [setStep, resolution, endDateYmd, startDateYmd, stationIds]);
	const edit = useCallback(() => setStep(0), [setStep]);

	return (
		<form onSubmit={submit} className="vmznds-rz-wizard">
			<ol className="vmznds-rz-wizard-steps">
				<li className={`vmznds-rz-wizard-step vmznds-rz-wizard-step--${step === 0 ? 'active' : 'inactive'}${step > 0 ? ' vmznds-rz-wizard-step--clickable' : ''}`}
					onClick={edit}
					tabIndex={step > 0 ? 0 : null}>Auswahl
				</li>
				<li className={`vmznds-rz-wizard-step vmznds-rz-wizard-step--${step === 1 ? 'active' : 'inactive'}`}>Diagramm
					&
					Download
				</li>
			</ol>

			<div className="vmznds-rz-wizard__content-wrapper">
				<SelectionStep
					active={step === 0}
					stations={stations}
					setSelectedStations={setSelectedStations}
					resolution={resolution}
					setResolution={setResolution}
					startDateYmd={startDateYmd}
					setStartDateYmd={setStartDateYmd}
					endDateYmd={endDateYmd}
					setEndDateYmd={setEndDateYmd} />
				<ResultStep
					active={step === 1}
					stations={stations}
					edit={edit}
					stationIds={selectedStationsIds}
					data={data}
					problems={problems}
					resolution={resolution}
					startDateYmd={startDateYmd}
					endDateYmd={endDateYmd} />
			</div>
		</form>
	);
};


export default Wizard;
