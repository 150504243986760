import React, {memo, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';

import {createPanelOptionSelector} from '../../store/selectors';
import {setPanelOption} from '../../store/actions';

export default memo(
	/**
	 * @param {{
	 *   k: string,
	 *   title: React.ReactNode,
	 *   children: React.ReactNode,
	 *   defaultActive?: boolean,
	 * }} props
	 */
	function PanelOption({k, title, children, defaultActive = false}) {
		const dispatch = useDispatch();
		const panelOptionSelector = useMemo(
			() => createPanelOptionSelector(k),
			[k]
		);
		const activeState = useSelector(panelOptionSelector);

		const active = activeState === undefined ? defaultActive : activeState;

		const onClick = useCallback(
			() => {
				dispatch(setPanelOption(k, !active));
			},
			[dispatch, k, active]
		);

		return (
			<article className={modClasses('vmznds-panel-option', {[k]: true, active})}>
				<header
					className="vmznds-panel-option__header"
					onClick={onClick}
				>
					<div className="vmznds-panel-option__headline">
						{title}
					</div>

					<button className="vmznds-panel-option__button">
					<span className="visuallyhidden">
						Optionen für {title} {active ? 'öffnen' : 'schließen'}
					</span>
					</button>
				</header>

				{active && (
					<div className="vmznds-panel-option__body">
						{children}
					</div>
				)}
			</article>
		);
	}
);
