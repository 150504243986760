import React, {Fragment, memo} from 'react';

import MiniLegend from '@mapsight/ui/src/js/components/map-overlay/mini-legend';
import InfoOverlayRight from '@mapsight/ui/src/js/components/map-overlay/info-overlay-right';

import {withErrorBoundary} from '../../modules/error-boundary';
import {memoForever} from '../helpers';
import {useNavPos} from '../hooks';


/** @type {import("@mapsight/ui/src/js/components/contexts").MapOverlayStart} */
const MapOverlayEnd = memo(function MapOverlayEnd({}) {
	const {reduced} = useNavPos();
		return (
			<Fragment>
				<div className="vmznds-map-overlay-bottom">
					<div className={`vmznds-mini-legend ${reduced?'vmznds-mini-legend--reduced':''}`}>
						<MiniLegend />
					</div>
					<InfoOverlayRight />
				</div>
			</Fragment>
		);
	},
	memoForever // mapsight sets parameters for this component, memoForever changes to get them ignored
);

export default withErrorBoundary(MapOverlayEnd);
