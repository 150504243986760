import React from 'react';
import {withErrorBoundary as reactWithErrorBoundary} from 'react-error-boundary';

// Kein Link oder andere aktive Elemente in das fallback.
// Wenn es z.B. im Kartenoverlay gerendert wird, ist es zwar sichtbar, aber die Mausevents kommen nicht an!
const fallback = (
	<div className="vmznds-error-boundary__error" >
		<h1>Ein unerwarteter Fehler ist aufgetreten.</h1>
		<p>
			Bitte versuchen Sie es erneut!
			<br />
		</p>
	</div>);

const onError = (error, info) => {
	// this error message has to survice all tempering with console.log by gulp
	const c=typeof window !== 'undefined' ?
			window.console :
			global.console;
	c.error('Error Boundary catched an awful error.', {error, info});
	// TODO push {error, info} as json to some neonaut server
};

/**
 * @template P
 * @param {React.ComponentType<P>} Component
 * @return {React.ComponentType<P>}
 */
export function withErrorBoundary(Component) {
	return reactWithErrorBoundary(Component, {fallback, onError});
}

// TODO? export default ErrorBoundary
