import React, {useCallback} from 'react';

import config from '../../config';

import StationSelection from './StationSelection';
import Step from './Step';
import TimeRangeSelection from './TimeRangeSelection';

export function SelectionStep({
	active,
	stations,
	setSelectedStations,
	resolution,
	setStartDateYmd,
	setEndDateYmd,
	startDateYmd,
	endDateYmd,
	setResolution,
}) {
	const handleResolutionChange = useCallback(function (e) {
		setResolution(e.target.value);
	}, [setResolution]);

	return (
		<Step active={active} modifier="selection">
			<div className="vmznds-rz-wizard-section">
				<h4 className="vmznds-rz-wizard-section-headline">Radzählstationen</h4>
				<div>
					<StationSelection stations={stations} setSelectedStations={setSelectedStations} />
				</div>
			</div>

			<div className="vmznds-rz-wizard-section">
				<h4 className="vmznds-rz-wizard-section-headline">Auflösung</h4>
				<fieldset>
					<legend className="visuallyhidden">Auflösung</legend>
					<span>
							{Object.values(config.resolutions).map(({value, label}) => (
								<label key={value} className="vmznds-rz-wizard-radio"
									htmlFor={`vmznds-rz-wizard-resolution-${value}`}>
									<input className="vmznds-rz-wizard-radio__input" type="radio" name="resolution"
										value={value}
										checked={resolution && resolution === value} onChange={handleResolutionChange}
										id={`vmznds-rz-wizard-resolution-${value}`} />
									<span className="vmznds-rz-wizard-radio__label">{label}</span>
								</label>
							))}
						</span>
				</fieldset>
			</div>

			<div className="vmznds-rz-wizard-section">
				<h4 className="vmznds-rz-wizard-section-headline">Zeitraum</h4>
				<fieldset>
					<legend className="visuallyhidden">Zeitraum</legend>
					<TimeRangeSelection
						startDateYmd={startDateYmd} setStartDateYmd={setStartDateYmd}
						endDateYmd={endDateYmd} setEndDateYmd={setEndDateYmd}
					/>
				</fieldset>
			</div>

			<div className="vmznds-rz-wizard-section">
				<p style={{display: 'flex', justifyContent: 'flex-end'}}>
					<button className="vmznds-rz-wizard-button vmznds-rz-wizard-button--primary" type="submit">Weiter
					</button>
				</p>
			</div>
		</Step>
	);
}
