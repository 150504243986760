import React, {memo, useCallback} from 'react';
import {createSelector} from 'reselect';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';

import {selectExclusively} from '@mapsight/core/lib/feature-selections/actions';

import getFeatureProperty from '@mapsight/ui/src/js/helpers/get-feature-property';
import {isViewMobileOrMapOnlySelector} from '@mapsight/ui/src/js/store/selectors';
import {translate} from '@mapsight/ui/src/js/helpers/i18n';

import {FEATURE_SELECTIONS} from '@mapsight/ui/src/js/config/constants/controllers';
import {FEATURE_SELECTION_SELECT} from '@mapsight/ui/src/js/config/feature/selections';
import {STATUS_ERROR, STATUS_LOADING} from '@mapsight/core/lib/feature-sources/selectors';

import {camerasOverviewSelector, detailsFeatureSelector} from '../../store/selectors';

import {setCamerasOverview} from '../../store/actions';
import CloseButton from '../close-button';
import {useNavPos} from '../../hooks';
import {getBaseUrl} from '../../routing/helpers';

import ContentBox from './box';
import useCameraUrl from './camera-url';


const Entry = memo(
	/**
	 * @param {{feature: Record<string, any>}} props
	 */
	function Entry({feature}) {
		const dispatch = useDispatch();
		const history = useHistory();
		const navPos = useNavPos();
		const onClick = useCallback(
			() => {
				dispatch(selectExclusively(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT, feature.id));
				const url = `${getBaseUrl()}${navPos.area && !navPos.topic.noArea ? navPos.area.uri + '/' : ''}${navPos.topic.pageUri}/${feature.id}`;
				history.replace(url);
			},
			[dispatch, history, feature.id, navPos.topic, navPos.area]
		);

		const imgHref = useCameraUrl(feature);

		return (
			<article onClick={onClick} className="vmznds-content-cameras__entry">
				<h1 className="vmznds-content-cameras__entry-headline">
					{getFeatureProperty(feature, 'title')}
				</h1>

				<div className="vmznds-content-cameras__entry-img-wrapper">
					{imgHref && (
						<img
							className="vmznds-content-cameras__entry-img"
							src={imgHref}
							alt=""
						/>
					)}
				</div>
			</article>
		);
	}
);

const overviewEnabledSelector = createSelector(
	isViewMobileOrMapOnlySelector,
	camerasOverviewSelector,
	(isMob, owEnabled) => !isMob && owEnabled,
);

const ContentCameras = memo(
	/**
	 * @param {{
	 *   features: Array<Record<string, any>>,
	 *   status: string,
	 * }} props
	 */
	function ContentCameras({features, status}) {
		const dispatch = useDispatch();
		const selectedFeature = useSelector(detailsFeatureSelector);
		const overviewEnabled = useSelector(overviewEnabledSelector);

		const disableOverview = useCallback(
			() => {
				dispatch(setCamerasOverview(false));
			},
			[dispatch]
		);

		if (selectedFeature || !overviewEnabled) {
			return null;
		}

		const statusMessage = status === STATUS_ERROR ? (
			<div
				className="vmznds-content vmznds-content--error"
			>
				{translate('ui.feature-details.content-inner.error')}
				<br />
			</div>
		) : (status === STATUS_LOADING ? (
				<div
					className="vmznds-content vmznds-content--loading"
				>
					{translate('ui.feature-details.content-inner.loading')}
				</div>
			) : null
		);

		return (
			<ContentBox alignWithPanelContent={true} fullWidth={true} onClose={disableOverview}>
				<div className="vmznds-content-cameras">
					<div className="vmznds-content-cameras__headline-wrapper">
						<h1 className="vmznds-content-cameras__headline">
							Verkehrskameras im Überblick
						</h1>
						<div className="vmznds-content-cameras__close-button">
							<CloseButton onClick={disableOverview} />
						</div>
					</div>

					<div className="vmznds-content-cameras__entries">
						{statusMessage}
						{(features || []).map((f) => (
							<Entry key={f.id} feature={f} />
						))}
					</div>
				</div>
			</ContentBox>
		);
	}
);
export default ContentCameras;
