import React, {Fragment, memo, useMemo} from 'react';

import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';

import getStops from '../get-stops';

import StopComp from './stop';
import PtType from './type';
import PtLine from './line';

export default memo(function PtInfo({featureProps, baseClassName, linkClassName}) {
	const {
		groupedSecondaryLines,
		stops,
	} = useMemo(
		() => {
			const nextGroupedSecondaryLines = (Array.isArray(featureProps.secondaryLines) ? featureProps.secondaryLines : []).reduce(
				(g, line) => {
					const type = ['rb', 're'].includes(line.type) ? 'rb' : line.type;
					if (!g[type]) g[type] = [];
					g[type].push(line);
					return g;
				},
				{}
			);
			if (featureProps.publicTransportLongDistanceTraffic === '1') {
				nextGroupedSecondaryLines.rb = nextGroupedSecondaryLines.rb || [];
				nextGroupedSecondaryLines.rb.push({type: 'db'});
			}
			if (featureProps.publicTransportCollectiveCab === '1') {
				nextGroupedSecondaryLines.ast = nextGroupedSecondaryLines.ast || [];
				nextGroupedSecondaryLines.ast.push({type: 'ast'});
			}
			return {
				groupedSecondaryLines: nextGroupedSecondaryLines,
				stops: getStops(featureProps),
			};
		},
		[featureProps]
	);

	return (stops.length !== 0 || groupedSecondaryLines !== null) && (
		<section className={`${baseClassName}__stops`}>
			{typeof featureProps.publicTransportStop === 'string' && (
				<h2 className={`${baseClassName}__stops-headline`}>
					ÖPNV-Anbindung
				</h2>
			)}

			<div className={`${baseClassName}__stops-content`}>
				<p className={`${baseClassName}__haltestelle`}>
					Haltestelle<br />
					{featureProps.publicTransportStop}
				</p>
				{stops.length !== 0 && (
					<Fragment>
						<h3>Nächste Abfahrten:</h3>

						{stops.map((stop, stopIndex) => (
							<StopComp key={stopIndex} stop={stop} />
						))}
					</Fragment>
				)}

				{groupedSecondaryLines && (
					<Fragment>
						<h3>Weitere Linien:</h3>

						{Object.entries(groupedSecondaryLines).map(([mode, lines]) => (
							<section
								key={mode}
								className={modClasses(`${baseClassName}__stop`, 'more')}
							>
								{mode !== 'ast' &&
								<div
									className={`${baseClassName}__stop-symbol`}
								>
									<PtType mode={mode} />
								</div>
								}
								<div className={`${baseClassName}__stop-body`}>
									<ul className={`${baseClassName}__stop-more-lines`}>
										{lines.map((line, lineI) =>
											(<li
												key={lineI}
												className={`${baseClassName}__stop-more-line`}
											>
												{['db', 'ast'].includes(line.type) ?
													<PtType mode={line.type} /> :
													<PtLine
														mode={line.type}
														name={line.number || ''}
													/>
												}
											</li>)
										)}
									</ul>
								</div>
							</section>
						))}
					</Fragment>
				)}
				{featureProps.publicTransportStopTimetable && (new URL(featureProps.publicTransportStopTimetable)).hostname && (
					<Fragment>
						<p><a className={linkClassName} target="_blank"
							  href={featureProps.publicTransportStopTimetable}>
							{`Aushangfahrplan (${(new URL(featureProps.publicTransportStopTimetable)).hostname.replace(/^www./, '')})`}
						</a></p>
					</Fragment>
				)}
			</div>
		</section>
	);
});
