import React, {forwardRef, memo, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {createSelector, createStructuredSelector} from 'reselect';

import {createListFeatureSelector, featureSourceIdSelector} from '@mapsight/core/lib/list/selectors';
import {getAllFeatures, getFeatureSourceStatus} from '@mapsight/core/lib/feature-sources/selectors';

import {FEATURE_SOURCES} from '@mapsight/ui/src/js/config/constants/controllers';
// import sortFeaturesByDistance from '@mapsight/ui/src/js/helpers/sort-features-by-distance';
import {
	layerSwitcherShowExternalSelector,
	listUiOptionsSelector,
	tagSwitcherShowSelector,
} from '@mapsight/ui/src/js/store/selectors';

import FeatureList from '@mapsight/ui/src/js/components/feature-list/feature-list';

import {FEATURE_LIST_TWO} from '../../store/controller';
import {useNavPos} from '../../hooks';

import StartPageItem from './start-page-item';
// import {filterFeatures} from '@mapsight/ui/src/js/components/feature-list/filtering';

// TODO UGLY HACK replace hard-coded link for list-two with some logic
const ListTwoItem = memo(forwardRef(function ListTwoItem(props, ref) {
	const {area} = useNavPos();
	return (<StartPageItem ref={ref} to={`/${area.uri}/baustellen-aktuell`} {...props} />);
}));

const selector = createStructuredSelector({
	listUiOptions: listUiOptionsSelector, // kann bleiben, da es die zweite Liste nur auf der Startseite gibt und dort werden ohnehin identisch für beide Listen die Filterungs- und Sortierungsbuttons abgeschaltet
	tagSwitcherShow: tagSwitcherShowSelector, // analog zu listUiOptions
	layerSwitcherShowExternal: layerSwitcherShowExternalSelector, // analog zu listUiOptions
	// scrollPosition: lastListScrollPositionSelector,
	featureSourceId: createSelector(state => state[FEATURE_LIST_TWO], featureSourceIdSelector),
	featureSource: createListFeatureSelector(FEATURE_LIST_TWO, FEATURE_SOURCES),
	// filterOptions: createStructuredSelector({
	// 	query: listQuerySelector,
	// 	sorting: listSortingSelector,
	// 	places: createSelector(
	// 		[
	// 			state => state[USER_GEOLOCATION],
	// 			placesSelector,
	// 		],
	// 		(userGeolocation, places) => ({
	// 			...places,
	// 			geolocation: {
	// 				x: userGeolocation.latitude,
	// 				y: userGeolocation.longitude,
	// 			},
	// 		})
	// 	),
	// }),
});

export default memo(
	function ListTwo() {
		const {
			listUiOptions,
			tagSwitcherShow,
			layerSwitcherShowExternal,
			featureSource,
			featureSourceId,
		} = useSelector(selector);

		const {features, status} = useMemo(
			() => ({
				features: getAllFeatures(featureSource),
				status: getFeatureSourceStatus(featureSource),
			}),
			[featureSource]
		);

		return (
			<FeatureList
				itemAs={ListTwoItem}
				listUiOptions={listUiOptions}
				tagSwitcherShow={tagSwitcherShow}
				layerSwitcherShowExternal={layerSwitcherShowExternal}
				features={features}
				filteredFeatures={features}
				status={status}
				featureSourceId={featureSourceId}
				additionalClasses="vmznds-list-two__feature-list"
			/>
		);
	}
);
