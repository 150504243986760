import {memo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {batchActions} from 'redux-batched-actions';
import memoizee from 'memoizee';

import easing from 'ol/easing';

import loadJS from '@neonaut/lib-js/es/dom/network/load-js';
import getPath from '@neonaut/lib-js/es/object/getPath';
import {trackPageView} from '@neonaut/lib-js/es/misc/piwik';

import {async} from '@mapsight/core/lib/base/actions';
import {load, pauseRefreshUntilNextLoad} from '@mapsight/core/lib/feature-sources/actions';
import {animate as animateMap, setStyleEnv} from '@mapsight/core/lib/map/actions';

import {titleSelector, viewSelector} from '@mapsight/ui/src/js/store/selectors';
import {FEATURE_SELECTIONS, FEATURE_SOURCES, MAP} from '@mapsight/ui/src/js/config/constants/controllers';
import {VIEW_MOBILE} from '@mapsight/ui/src/js/config/constants/app';
import {
	setAppTitle,
	setListFilterControl as uiSetListFilterControl,
	setListHighlightOnMouse,
	setListScrollOnPreselection,
	setListSelectionBehavior,
	setListSelectionBehaviorSelection,
	setListSelectOnClick,
	setListSortControl as uiSetListSortControl,
	setMapVisible,
	setMiniLegendLayer,
	setOverlayModalVisible,
	setPageTitleShow,
} from '@mapsight/ui/src/js/store/actions';
import {FEATURE_SELECTION_PRESELECT, FEATURE_SELECTION_SELECT} from '@mapsight/ui/src/js/config/feature/selections';

import {disableNavigation, enableNavigation, setModality} from '../../modules/navigation/store/actions';

import {
	deselectAllFromRoute,
	replaceFeatureSources,
	replaceMapLayers,
	selectExclusivelyFromRoute,
	setCamerasOverview,
	setListFeatureSource,
	setListFilterControl,
	setListPanelIsVisible,
	setListSortControl,
	setListTitle,
	setListTwoFeatureSource,
	setListTwoVisible,
	setListVisible,
	setNavigationOpen,
	setSpecialPage,
	setTagSwitcherControl
} from '../store/actions';

import configFeatureSources from '../config/feature-sources';
import configMapLayers, {AUTHORIZATION_SOURCE_ID} from '../config/map-layers';

import {useLoginState, useMagic} from '../hooks';
import {isPageContent} from '../store/selectors';
import {
	SPECIAL_PAGE_CONTENT,
	SPECIAL_PAGE_HOME,
	SPECIAL_PAGE_NAVIGATION,
	SPECIAL_PAGE_SITEMAP,
	URGENT_PAGES
} from '../store/controller';
import {NAVIGATION_URI_MODALITIES, ROUTE_STOPS_LAYER} from '../../modules/navigation/store/constants';

import {calculateMaxAreas, contentUrlToId, navPosition, reduceToLayerIdsSetVisible} from './helpers';


/* eslint-disable smells/no-complex-switch-case */
export default memo(
	/**
	 * @param {{}} props
	 * @returns {null}
	 */
	function RouteToStore({}) {
		const dispatch = useDispatch();
		const location = useLocation();
		const history = useHistory();
		const magic = useMagic();
		const view = useSelector(viewSelector);
		//const isMobile = useSelector(createSelector(viewSelector, view => view === VIEW_MOBILE));
		const loggedIn = useLoginState();

		const appTitle = useSelector(titleSelector);

		// this is necesarry as <ReactLink to="?x"> will place the search in location.pathname whereas it will bei in location.search if it is set by the use in the adress bar
		const locPathName = location.pathname + location.search;

		useEffect(
			() => {
				if (typeof window !== 'undefined') {
					const document = window.document;
					document.title = appTitle + ' | VMZNDS';
				}
			},
			[appTitle]
		);

		useEffect(
			() => {
				onInit(dispatch);
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[] // leerer Array bedeutet: nur einmal ausführen, kein Array: immer
		);

		// adapt feature sources to change to magic or changed location (this is needed to support deep content pages)
		useEffect(
			() => {
				onMagicLocationChange(magic, locPathName, location.state, dispatch);
			},
			[magic, locPathName, location.state, dispatch]
		);

		// Spezialbehandlung content-pages auf mobil
		useEffect(
			() => {
				onMagicLocationViewChange(magic, locPathName, location.state, view, dispatch);
			},
			[dispatch, magic, view, locPathName, location.state]
		);

		useEffect(
			() => {
				onMagicLocationLoggedInChange(magic, locPathName, location.state, loggedIn, history, dispatch);
			},
			[dispatch, history, magic, locPathName, location.state, loggedIn],
		);

		return null;
	}
);

/**
 * @param {import('redux').Dispatch} dispatch
 */
export function onInit(dispatch) {
	const actions = [];

	// reset if set by localStorage of user's browser
	actions.push(setPageTitleShow(false));
	actions.push(uiSetListFilterControl(false));
	actions.push(uiSetListSortControl(false));
	actions.push(setCamerasOverview(true));

	if (typeof window !== 'undefined') {
		const siteId = window.location.hostname.includes('staging') || window.location.hostname.includes('2020') ? 15 : 13;
		// TODO need to set correct values for piwikDomains?
		initTracking('https://stats.vmz-niedersachsen.de/piwik/', siteId, [window.location.hostname], 'https://stats.vmz-niedersachsen.de/piwik/matomo.php');
	}
	dispatch(batchActions(actions));
}

/**
 * @param {import('../types').Magic} magic
 * @param {string} locPathName
 * @param {*} locState
 * @param {import('redux').Dispatch} dispatch
 * @param {function(string)=} loadIssue
 */
export function onMagicLocationChange(magic, locPathName, locState, dispatch, loadIssue) {
	loadIssue = loadIssue || (id => dispatch(async(load(FEATURE_SOURCES, id))));

	// HOTFIX for data loss at replaceFeatureSource. TODO actually we only want to replace the source 'preview' so that should be calculated seperatly leaving the other feature sources alone
	const navPos = locPathName ? navPosition(magic, locPathName, locState) : null;
	const previewUrl = getPath(navPos, ['content', 'preview']);
	if (previewUrl) {
		dispatch(replaceFeatureSources(configFeatureSources(magic, locPathName, locState)));
	}

	// TODO Herbert: hier Funktion auftrennen und urgentTopics loading nach onInit schieben (oder in ein eigenes onMagicChange)
	// initiate loading and refreshing of urgent feature sources, Schulausfälle, Eilmeldungen et. al
	/** @var {Array.<string>} urgentSources */
	const urgentSources = calculateUrgentSources(magic.topics); // eslint-disable-line no-use-before-define
	urgentSources.forEach(source => {
		if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
			console.log(`route-to-store initialCall urgentTopics: dispatching loading of ${source}`);
		}
		loadIssue(source);
	});
}

/**
 * @param {import('../types').Magic} magic
 * @param {string} locPathName
 * @param {*} locState
 * @param {import('@mapsight/ui/src/js/config/constants/app').View} view
 * @param {import('redux').Dispatch} dispatch
 */
export function onMagicLocationViewChange(magic, locPathName, locState, view, dispatch) {
	const isMobile = view === VIEW_MOBILE;

	const navPos = navPosition(magic, locPathName, locState);
	const actions = [
		setListScrollOnPreselection(!isMobile),
	];

	actions.push(setMapVisible(
		!(
			isMobile && (
				navPos.content || navPos.sitemap || isPageContent(getPath(navPos, ['topic', 'type']))
			)
		)
	));
	dispatch(batchActions(actions));
}


let lastTrackingUrl = null;
let lastTrackingTitle = null;
let lastPageWasContent = false;
let lastPageWasNavigation = false;
let lastMapLayers = null;
let lastSourceOne = null;
let lastSourceTwo = null;

const lastMapLayerInput = {navPos: null, magic: null};

let lastAnimatedArea = null;

/**
 * @param {import('../types').Magic} magic
 * @param {string} locPathName
 * @param {*} locState
 * @param {boolean} loggedIn
 * @param {object} history
 * @param {import('redux').Dispatch} dispatch
 */
export function onMagicLocationLoggedInChange(magic, locPathName, locState, loggedIn, history, dispatch) {
	const actions = [];

	const navPos = navPosition(magic, locPathName, locState);

	const startPage = !(navPos.topic && navPos.topic.uri) && !navPos.content && !navPos.sitemap;
	const listTwoVisible = (startPage && !(navPos.area && navPos.area.uri === 'bundesweit'));

	const {maxAreas} = calculateMaxAreas(magic.areas);
	const hasWrongArea = !startPage && !navPos.content &&
		navPos.topic && !navPos.topic.noArea && navPos.topic.maxArea && !maxAreas[navPos.topic.maxArea].includes(navPos.area.uri);

	const specialPage = startPage ? SPECIAL_PAGE_HOME :
		navPos.content ? SPECIAL_PAGE_CONTENT :
			navPos.sitemap ? SPECIAL_PAGE_SITEMAP :
				navPos.topic && !hasWrongArea && navPos.topic.type;

	if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
		console.log('RouteToStoreInner (onMagicLocationLoggedInChange)',
			{
				navPos,
				startPage,
				listTwoVisible,
				specialPage,
				tagFilter: (navPos.topic && navPos.topic.tagFilter),
				'show TagFilter': (!startPage && navPos.topic && navPos.topic.tagFilter === true)
			},
			magic,
		);
	}

	if (lastAnimatedArea !== navPos.area) {
		lastAnimatedArea = navPos.area;

		actions.push(animateMap('map', {
			// there's no `nearest` option
			// @ts-ignore
			nearest: true,
			duration: 1000,
			easing: easing.easeOut,
			bounds: navPos.area.bounds,
		}));
	}

	// instead of memoizing the result, we simple keep the mapLayers if magic and navPos haven't changed
	// that way we keep user selections as long she stays on the same page
	const updateMapLayers = (
		lastMapLayerInput.magic !== magic ||
		lastMapLayerInput.navPos === null ||
		lastMapLayerInput.navPos.error404 !== navPos.error404 ||
		lastMapLayerInput.navPos.content?.url !== navPos.content?.url ||
		lastMapLayerInput.navPos.topic?.uri !== navPos.topic?.uri ||
		lastMapLayerInput.navPos.area?.uri !== navPos.area?.uri ||
		lastMapLayerInput.navPos.reduced !== navPos.reduced
		// ignore sitemap as it does not involve any feature data.
		// alternativ configMapLayers so konfigurieren dass im Ergebnis der Reload für alle featureSourcen gestoppt wird, siehe hier, weiter unten. das ist aber nicht so wichtig.
	);

	let mapLayers = lastMapLayers;
	if (updateMapLayers) {
		mapLayers = configMapLayers(magic, navPos); // TODO supply current user selections as parameter or change LayerSwitcher to keep state of user selection seperated from layers. (that seperated state would need to update visibility by layerswitcher when layers change=
		actions.push(replaceMapLayers(mapLayers));
		if(lastMapLayerInput.navPos?.reduced !== navPos.reduced) {
			actions.push(setStyleEnv(MAP, {
				colorScheme: navPos.reduced ? 'high-contrast' : 'default',
			}));
		}
		lastMapLayerInput.navPos = navPos;
		lastMapLayerInput.magic = magic;
	}
	if(updateMapLayers || specialPage) {
		// un highlight features because there is a case in Firefox where a feature of the last list may be "mouse entered" and
		// therefore highlighted and the layer might be removed (which is what would allow the map to unhighlight the feature),
		// so when the user "mouse enters" the map a old tooltip will be shown - at least until another feature is highlighted
		actions.push(deselectAllFromRoute('featureSelections', 'highlight'));
	}

	actions.push(setListSelectionBehaviorSelection(startPage ? FEATURE_SELECTION_PRESELECT : FEATURE_SELECTION_SELECT));
	actions.push(setListSelectionBehavior(
		isPageContent(specialPage) ? {mobile: 'nop', fullscreen: 'nop'} :
			{
				mobile: startPage ? 'scrollToMap' : 'showInMapOnlyView',
				fullscreen: 'scrollToMap'
			}
	));

	actions.push(setListTwoVisible(listTwoVisible));
	const listTwoFeatureSource = (listTwoVisible ? navPos.area.uri + '-baustellen' : null);
	actions.push(setListTwoFeatureSource(listTwoFeatureSource));

	actions.push(setListVisible(!isPageContent(specialPage) && !startPage));
	actions.push(setListTitle(
		navPos.topic && navPos.topic.uri ? navPos.topic.pageName || navPos.topic.name
			: (navPos.content ? navPos.content.pageName || navPos.content.name
				: (startPage ? 'Verkehrslage' : '404') // TODO programmatisch Titel der ersten Liste auf der Startseite feststellen
			)
	));
	actions.push(setListSelectOnClick(specialPage !== SPECIAL_PAGE_NAVIGATION));
	actions.push(setListHighlightOnMouse(specialPage !== SPECIAL_PAGE_NAVIGATION));
	actions.push(setNavigationOpen(false));
	actions.push(setOverlayModalVisible(false));

	actions.push(setSpecialPage(specialPage));

	// deselect on all page changes but keep PRESELECTION which is used when switching away from startpage
	// and support deeplinks and select via link state
	if (navPos?.topic?.deepLinks && navPos.deepLink) {
		actions.push(selectExclusivelyFromRoute(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT, navPos.deepLink));
	} else if (!isPageContent(specialPage) && navPos.select) {
		actions.push(selectExclusivelyFromRoute(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT, navPos.select));
	} else {
		actions.push(deselectAllFromRoute(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT));
	} // TODO? is there any link change to keep selection "select"?

	if (startPage || isPageContent(specialPage)) {
		actions.push(deselectAllFromRoute(FEATURE_SELECTIONS, FEATURE_SELECTION_PRESELECT));
	}

	if (isPageContent(specialPage)) {
		actions.push(setListPanelIsVisible(true)); // stop fullscreen if it is on
	}
	let listOneFeatureSoure = null;
	switch (specialPage) {
		case SPECIAL_PAGE_CONTENT:
			if (specialPage === SPECIAL_PAGE_CONTENT) {
				if (navPos.content.authorization === true && magic.authorization && magic.authorization.url && !loggedIn) {
					// authorization feature source nicht merken, weil sie nicht abgestellt werden soll
					actions.push(setListFeatureSource(AUTHORIZATION_SOURCE_ID));
				} else {
					listOneFeatureSoure = contentUrlToId(navPos.content.url);
					actions.push(setListFeatureSource(listOneFeatureSoure));
				}
			}
			break;

		case SPECIAL_PAGE_NAVIGATION:
			actions.push(setListSortControl(false));
			actions.push(setListFilterControl(false));
			actions.push(setTagSwitcherControl(false));
			actions.push(setListPanelIsVisible(true)); // stop fullscreen if it is on
			break;

		case SPECIAL_PAGE_SITEMAP:
			// NOP
			break;

		default:
			listOneFeatureSoure = (navPos.topic && navPos.topic.noArea ? '' : navPos.area.uri + '-') + // navPos.topic.noArea impliziert !startpage
				(startPage ? 'verkehrsmeldungen' : navPos.topic.uri);
			actions.push(setListFeatureSource(listOneFeatureSoure));
			actions.push(setListSortControl(!startPage && navPos.topic && navPos.topic.distanceSort === true));
			actions.push(setListFilterControl(!startPage && navPos.topic && navPos.topic.distanceSort === true));
			actions.push(setTagSwitcherControl(
				!startPage && navPos.topic && navPos.topic.tagFilter === true,
				FEATURE_SOURCES,
				listOneFeatureSoure,
				{
					toggleableGroups: true,
					sortTags: true
				})
			);
			break;
	}

	const isNavigation = specialPage === SPECIAL_PAGE_NAVIGATION;
	const modality = isNavigation && navPos.deepLink ? NAVIGATION_URI_MODALITIES[navPos.deepLink] : 'car';
	actions.push(
		specialPage === SPECIAL_PAGE_NAVIGATION ?
			(
				lastPageWasNavigation ?
					setModality(modality) :
					enableNavigation(modality)
			) :
			disableNavigation()
	);
	lastPageWasNavigation = isNavigation;

	const title = (
		(navPos.content ?
				navPos.content.pageName || navPos.content.name :
				startPage ? 'Startseite' + (navPos.areaFound ? ` ${navPos.area.name}` : '') :
					navPos.sitemap ? 'Sitemap' :
					(navPos.topic.noArea ? '' : `${navPos.area.name} `) + `${navPos.topic.pageName || navPos.topic.name}`
		)
	);
	actions.push(setAppTitle(title));

	if (typeof window !== 'undefined') {
		// This is a hot fix that only works with content pages
		if (lastPageWasContent || isPageContent(specialPage)) {
			window.scrollTo({top: 0});
		}
		lastPageWasContent = isPageContent(specialPage);

		if ((locPathName && locPathName !== lastTrackingUrl) || title && title !== lastTrackingTitle) {
			lastTrackingTitle = title;
			lastTrackingUrl = locPathName;
			trackPageView(locPathName, title);
		}
	}

	// actions based on delta to last run of route-to-store
	if(
		!lastMapLayers || !mapLayers ||
		reduceToLayerIdsSetVisible(lastMapLayers).sort().join(',') !== reduceToLayerIdsSetVisible(mapLayers).sort().join(',')
	) {
		actions.push(setMiniLegendLayer(specialPage === SPECIAL_PAGE_NAVIGATION ?
			ROUTE_STOPS_LAYER :
			navPos.topic && navPos.topic.pageUri === 'verkehrslage' ? 'los' : listOneFeatureSoure
		));
	}
	const pauseActions = pauseOldSources(magic, {
		mapLayers: lastMapLayers,
		listOneFeatureSoure: lastSourceOne,
		listTwoFeatureSource: lastSourceTwo
	}, {mapLayers, listOneFeatureSoure, listTwoFeatureSource});
	lastMapLayers = mapLayers;
	lastSourceOne = listOneFeatureSoure;
	lastSourceTwo = listTwoFeatureSource;


	dispatch(batchActions([
		...actions,
		...pauseActions
	]));
}

const calculateInLayerSwitcher = memoizee((layers) =>
	layers && Object.keys(layers).filter(
	k =>
		// getPath(layers[k], ['metaData', 'visibleInExternalLayerSwitcher']) ||
		getPath(layers[k], ['metaData', 'visibleInLayerSwitcher'])
	) || []
);


const calculateActiveLayers = memoizee((layers) =>
	layers && Object.keys(layers).filter(
	k =>
		// getPath(layers[k], ['metaData', 'isBaseLayer']) ||
		getPath(layers[k], ['options', 'visible'])
	) || []
);

const calculateUrgentSources = memoizee((topics) => {
	const urgentTopics = (topics || [])
		.filter((topic) => topic.type && URGENT_PAGES.includes(topic.type));

	return urgentTopics.map(urgent => {
		const areaUri = urgent.maxArea ? urgent.maxArea : 'bundesweit';
		return (urgent.noArea ? urgent.uri : `${areaUri}-${urgent.uri}`);
	});
});

/**
 * @param {object} magic
 * @param {object} oldSources
 * @param {object} newSources
 * @returns {Array.<import("redux").AnyAction>}
 */
function pauseOldSources(magic, oldSources, newSources) {
	const keep = [
		newSources.listOneFeatureSoure,
		newSources.listTwoFeatureSource,
		...calculateUrgentSources(magic.topics),
		...calculateActiveLayers(newSources.mapLayers),
	];

	const stop = new Set(
		[
			oldSources.listOneFeatureSoure,
			oldSources.listTwoFeatureSource,
			...calculateActiveLayers(oldSources.mapLayers),
			// remove layers potentially turned on by user but not turned on by newSources.mapLayers
			...calculateInLayerSwitcher(oldSources.mapLayers),
			'anomalies', // can be turned on by user via 'los' but has no visibleInLayerSwitcher itself
		].filter(source => source !== null && !keep.includes(source))
	);

	// noinspection UnnecessaryLocalVariableJS
	const actions = Array.from(stop).map(source => pauseRefreshUntilNextLoad(FEATURE_SOURCES, source));

	// console.warn('pauseOldSources', {
	// 	keep,
	// 	stop: Array.from(stop),
	// 	oldSources,
	// 	newSources,
	// 	urgent: calculateUrgentSources(magic.topics),
	// 	activeOldLayers: calculateActiveLayers(oldSources.mapLayers),
	// 	activeNewLayers: calculateActiveLayers(newSources.mapLayers),
	// 	actions,
	// });

	return actions;
}

// copied from @neonaut/lib-js only to omit initial 'trackPageView'
// piwik.js is still there, but let's change to matomo.js as we've copied this over already
/**
 * @param {string} piwikURL piwik url
 * @param {number} piwikSiteId piwik site id
 * @param {Array.<string>} piwikDomains domains to track
 * @param {string} [piwikTrackerUrl] piwik tracker url. default: piwikURL + 'piwik.php'
 */
export function initTracking(piwikURL, piwikSiteId, piwikDomains, piwikTrackerUrl) {
	piwikTrackerUrl = piwikTrackerUrl || piwikURL + 'matomo.php';

	if (typeof window === 'undefined') {
		console.log('No Piwik on SSR!');
	}

	window._paq.push(['setDomains', piwikDomains]);
	window._paq.push(['enableLinkTracking']);
	window._paq.push(['enableHeartBeatTimer']);
	window._paq.push(['setTrackerUrl', piwikTrackerUrl]);
	window._paq.push(['setSiteId', piwikSiteId]);
	// window._paq.push(['trackPageView']);

	loadJS(piwikURL + 'matomo.js', () => {
		if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
			if (window.console) {
				console.log('loaded piwik/matomo ', piwikURL, piwikSiteId, piwikDomains, piwikTrackerUrl, window._paq);
			}
		}
	});
}
