import {createSelector} from '@reduxjs/toolkit';
import {createStructuredSelector} from 'reselect';

import {MAP, USER_GEOLOCATION} from '@mapsight/ui/src/js/config/constants/controllers';
import {createUserGeolocationSelector} from '@mapsight/core/lib/user-geolocation/selectors';

import {NAVIGATION, ROUTE_STOPS_LAYER} from './constants';


export const enabledSelector = _ => _[MAP].layers[ROUTE_STOPS_LAYER]?.options?.visible || false;

export const navigationSelector = _ => _[NAVIGATION];
export const modalitySelector = _ => _[NAVIGATION].modality;
export const originSelector =  _ => _[NAVIGATION].origin;
export const destSelector = _ => _[NAVIGATION].dest;

export const originInputSelector = createSelector(
	originSelector,
	_ => _.input
);
export const destInputSelector = createSelector(
	destSelector,
	_ => _.input
);

export const originSuggestionsSelector = createSelector(
	originSelector,
	_ => _.suggestions
);
export const destSuggestionsSelector = createSelector(
	destSelector,
	_ => _.suggestions
);

export const originSuggestionsEntriesSelector = createSelector(
	originSelector,
	_ => _.suggestions.entries
);
export const destSuggestionsEntriesSelector = createSelector(
	destSelector,
	_ => _.suggestions.entries
);

export const inputSelectors = {
	origin: originInputSelector,
	dest: destInputSelector
};

export const suggestionsSelectors = {
	origin: originSuggestionsSelector,
	dest: destSuggestionsSelector,
};

export const suggestionsEntriesSelectors = {
	origin: originSuggestionsEntriesSelector,
	dest: destSuggestionsEntriesSelector,
};

export const datetimeSelector = createSelector(
	_ => _[NAVIGATION].datetime,
	dtStr => (!dtStr ? null : new Date(dtStr))
);

export const userGeolocationSelector = createUserGeolocationSelector(USER_GEOLOCATION);

// der macht eigentlich nichts außer Änderungen an den sugesstions und das Eintragen der Trip-Ergebnisse auszufiltern
export const tripParameterSelector = createSelector(
	navigationSelector,
	createStructuredSelector({
		enabled: _ => _.enabled,
		modality: _ => _.modality,
		datetime: _ => _.datetime,
		origin: _ => _.origin.input,
		dest: _ => _.dest.input,
		car: createStructuredSelector({datetime: _ => _.car?.datetime, requestIds: _ => _.car?.requestIds}),
		bike: createStructuredSelector({datetime: _ => _.bike?.datetime, requestIds: _ => _.bike?.requestIds}),
		public: createStructuredSelector({datetime: _ => _.public?.datetime, requestIds: _ => _.public?.requestIds}),
	})
);

export const tripsSelector = createSelector(
	navigationSelector,
	_ => _.trips
);

export const tripValid = (tripParameters, trips, modality) => (
	!!modality
	&& tripParameters.origin.requestId && tripParameters.dest.requestId
	&& tripParameters.origin.location && tripParameters.dest.location
	&& tripParameters.origin.requestId === trips[modality].requestIds?.origin
	&& tripParameters.dest.requestId === trips[modality].requestIds?.dest
);

export const tripResultValidSelector = createSelector(
	[
		tripParameterSelector,
		tripsSelector,
		modalitySelector
	],
	(tripParameter, trips, modality) =>
		tripValid(tripParameter, trips, modality)
		&& !trips[modality]?.error
		&& !!trips[modality]?.steps
);
