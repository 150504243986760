import React from 'react';

export default function Step({children, modifier, active, ...attrs}) {
	return (
		<div className={`vmznds-rz-wizard-step-content vmznds-rz-wizard-step-content--${modifier}`}
			style={{display: active ? 'block' : 'none'}} {...attrs}>
			{children}
		</div>
	);
}
