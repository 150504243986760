import React, {memo} from 'react';

import {translate} from '@mapsight/ui/src/js/helpers/i18n';

import {useNavPos} from '../hooks';
import {memoForever} from '../helpers';

import Link from './link';

// TODO Text im Visually Hidden je nach Zustand ändern, so dass der zukünftige Zustand drinnen steht
// FIXME Icon für farbenblindmodus statt style-Attribut. das ist jetzt nur zum schnellen Faken eines Screenshots
function ReducedButton() {
	const {reduced} = useNavPos();
	return (
		<Link reduced={!reduced}
			  role="switch"
			  aria-checked={reduced}
			  className={`ms3-button vmznds-button-reduced${reduced?' vmznds-button-reduced--active':''}`}
			  rel="nofollow"
		><span className="visuallyhidden">{translate(reduced ? 'vmznds.map-overlay.reduce.turnoff' : 'vmznds.map-overlay.reduce.turnon')}</span></Link>
	);
}

// style={{
// 				  fontSize: '36px',
// 				  lineHeight: '42px',
// 				  backgroundColor: 'transparent',
// 				  border: 'none',
// 				  textDecoration: 'none'
// 			  }}
export default memo(ReducedButton, memoForever);
