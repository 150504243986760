import React, {memo} from 'react';
import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';


/**
 * Public Transport Line Symbol
 */
const PtLine = memo(
	/**
	 * @param {{
	 *   name: string,
	 *   mode: import("../get-stops").Mode,
	 * }} props
	 */
	function PtLine({name, mode}) {
		return (
			<span className={modClasses('vmznds-pt-line', [`mode-${mode}`, `line-${mode}-${name}`])}>
				{name}
			</span>
		);
	}
);

export default PtLine;
