import React from 'react';
import ReactDOM from 'react-dom';

import {MagicContext} from '../contexts';
import RoutedMapsightApp from '../routing';

import {BrowserRouter} from './browser-router';
import {RefsContextProvider} from './provider';


/**
 * @param {import("../types").Magic} magic
 */
export default function createMapsightUiBrowserDomRenderer(magic) {
	/**
	 * Browser renderer (DOM) with re-hydration
	 *
	 * @type {import("@mapsight/ui/src/js/index").MapsightUiRenderer}
	 * @inheritDoc {import("@mapsight/ui/src/js/index").MapsightUiRenderer}
	 */
	return function mapsightUiBrowserDomRenderer(container, props, hydrate = false) {
		const app = (
			<BrowserRouter>
				<MagicContext.Provider value={magic || {}}>
					<RefsContextProvider>
						<RoutedMapsightApp {...props} />
					</RefsContextProvider>
				</MagicContext.Provider>
			</BrowserRouter>
		);

		return ReactDOM[hydrate ? 'hydrate' : 'render'](app, container);
	};
}
