import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {featureDetailsHasErrorSelector, featureDetailsHtmlSelector} from '@mapsight/ui/src/js/store/selectors';
import FeatureDetailsContentInner from '@mapsight/ui/src/js/components/feature-details-content/feature-details-content-inner';
import getFeatureProperty from '@mapsight/ui/src/js/helpers/get-feature-property';

import {detailsFeatureSelector} from '../../store/selectors';


export default connect(
	createStructuredSelector({
		feature: detailsFeatureSelector,
		hasError: featureDetailsHasErrorSelector,
		html: featureDetailsHtmlSelector,
	}),
	undefined,
	(state, ownProps) => {
		if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
			console.log('Details.jsx',
				{state, ownProps, url: getFeatureProperty(state.feature, 'detailsUrl')},
				state.feature && state.feature.properties,
				state.feature && state.feature.properties && state.feature.properties.detailsUrl
			);
		}

		return {
			...state,
			...ownProps,
			url: getFeatureProperty(state.feature, 'detailsUrl'),
		};
	}
)(FeatureDetailsContentInner);
