import React, {Fragment, memo, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {isViewMobileOrMapOnlySelector} from '@mapsight/ui/src/js/store/selectors';
import TagSwitcher from '@mapsight/ui/src/js/components/tag-switcher';
import FeatureFilter from '@mapsight/ui/src/js/components/feature-list/filter';
import FeatureListOne from '@mapsight/ui/src/js/components/feature-list/index'; // here we include index, in list-two we include contained component feature-list/feature-list

import {tripResultValidSelector} from '../../../modules/navigation/store/selectors';
import NavigationBox from '../../../modules/navigation/components/navigation-box';
import NavigationNote from '../../../modules/navigation/components/navigation-note';

import {
	camerasOverviewSelector,
	detailsSelector,
	featureSourceInfoSelector,
	listFilterControlSelector,
	listSortControlSelector,
	listTagSwitcherControlSelector,
	pageCamerasSelector,
	pageHomeSelector,
	preroutingEnabledSelector,
} from '../../store/selectors';
import {URGENT_PAGES} from '../../store/controller';
import {setCamerasOverview} from '../../store/actions';
import {useIsSSR, useMagic, useNavPos, useOnCloseDetails} from '../../hooks';

import {calculateMaxAreas, findAreaAndTopicFromSourceId, getBaseUrl, recommendedArea} from '../../routing/helpers';
import FeatureDetailsSummary from '../feature-details-content/feature-details-summary';
import PanelBox from '../boxes/panel-box';
import PanelOption from '../boxes/panel-option';
import PanelNote from '../boxes/panel-note';

import Link from '../link';

import FeatureSorter from './feature-sorter'; // Original in @mapsight/ui has an integrated show button with internal state defaulting to invisible. so it had to be copied over
import ListOneSummary from './list-one-summary';
import ListOneCamerasListItem from './list-one-cameras-item';
import LinkItem from './link-item';


export default memo(
	/**
	 * @param {{
	 *   k: string,
	 *   listTitle: React.ReactNode,
	 *   children: React.ReactNode,
	 * }} props
	 */
	function ListOneBox({k, listTitle, bodyRef, children, filteredFeatures}) {
		const dispatch = useDispatch();
		const magic = useMagic();
		const navPos = useNavPos();
		const isSSR = useIsSSR();

		const tagFilter = useSelector(listTagSwitcherControlSelector);
		const isMobile = useSelector(isViewMobileOrMapOnlySelector);
		const search = useSelector(listFilterControlSelector);
		const distanceSort = useSelector(listSortControlSelector);
		const details = useSelector(detailsSelector);

		const isNavigation = useSelector(preroutingEnabledSelector);
		const isNavigationValid = useSelector(tripResultValidSelector);

		const camerasPage = useSelector(pageCamerasSelector);
		const camerasOverviewEnabled = useSelector(camerasOverviewSelector);

		// Achtung || navPos muss hinter useSelector sein, damit useSelector ohne Bedingung immer an derselben Stelle ausgeführt wird
		const startPage = useSelector(pageHomeSelector) || navPos.error404;

		const featureSourceInfo = useSelector(featureSourceInfoSelector);
		const {topic: detailsTopic, totalCount} = featureSourceInfo.source ? findAreaAndTopicFromSourceId(magic, featureSourceInfo.source) : {topic: null};

		const {maxAreas} = calculateMaxAreas(magic.areas);
		const hasWrongArea = !details && !startPage && !navPos.content &&
			navPos.topic && !navPos.topic.noArea && navPos.topic.maxArea && !maxAreas[navPos.topic.maxArea].includes(navPos.area.uri);

		const haveCameraOverview = !hasWrongArea && camerasPage && !details && !isMobile && camerasOverviewEnabled;

		const mods = useMemo(
			/** @returns {Array<"body-no-scroll" | "main" | null>} */
			() => [
				startPage || navPos.error404 ?
					'body-no-scroll' : null,

				(details && detailsTopic && URGENT_PAGES.includes(detailsTopic.type)) ||
				(!details && navPos && navPos.topic && URGENT_PAGES.includes(navPos.topic.type)) ||
				(!startPage && !details) ||
				(!startPage && details && detailsTopic && navPos && navPos.topic && detailsTopic.uri === navPos.topic.uri)	?
					'main' : null,
			],
			[startPage, navPos, details, detailsTopic],
		);

		const onCloseDetails = useOnCloseDetails();
		const toggleCameraOverview = useCallback(
			() => {
				dispatch(setCamerasOverview(!camerasOverviewEnabled));
			},
			[dispatch, camerasOverviewEnabled]
		);

		const header = useMemo(
			() => {
				const headerItems = [];
				if (!details && !startPage && !hasWrongArea) {
					if (!isMobile && camerasPage) {
						headerItems.push(
							<div
								key="camera-layout-toggle"
								className="vmznds-list-one-cameras__overview-toggle-button"
								onClick={toggleCameraOverview}
								role="button"
							>
								{camerasOverviewEnabled ? 'Kameras als Liste' : 'Kameras im Raster'}
							</div>
						);
					}
					if (tagFilter) {
						headerItems.push(
							<PanelOption
								key="tag-switcher"
								k="tag-switcher"
								title="Filtern"
								defaultActive={true}
							>
								<TagSwitcher />
							</PanelOption>
						);
					}
					if (search || distanceSort) {
						headerItems.push(
							<PanelOption
								key="suchen-sortieren"
								k="suchen-sortieren"
								title="Suchen & Sortieren"
							>
								{search && (
									<FeatureFilter />
								)}

								{distanceSort && (
									<FeatureSorter />
								)}
							</PanelOption>
						);
					}
				}

				return headerItems.length ? (
					<Fragment>
						{headerItems}
					</Fragment>
				) : null;
			},
			[details, startPage, hasWrongArea, isMobile, camerasPage, toggleCameraOverview, camerasOverviewEnabled, tagFilter, search, distanceSort]
		);

		let footer = null;
		if (details) {
			footer = (<FeatureDetailsSummary />);
		} else if (startPage) {
			footer = (<ListOneSummary />);
		}

		let note = null;
		if (details) {
			if (detailsTopic && detailsTopic.detailsMarginal) {
				note = (
					<PanelNote html={`${detailsTopic.detailsMarginal}`} />
				);
			}
		} else if(!details && isNavigation) {
			note = (
				<NavigationNote />
			);
		} else {
			note = (
				navPos.content && navPos.content.marginal ? (
					<PanelNote html={`${navPos.content.marginal}`} />
				) : (
					!hasWrongArea && navPos.topic && navPos.topic.marginal ? (
							<PanelNote html={`${navPos.topic.marginal}`} />
						) :
						null
				)
			);
		}

		const content = [];

		if (!details && isNavigation) {
			content.push(<NavigationBox key="navigation" />);
		}

		if (details) {
			content.push(
				<Fragment key="details">
					{children}
				</Fragment>
			);
		} else if (!hasWrongArea && !camerasPage && (totalCount || !isNavigation || (isNavigation && isNavigationValid))) {
			// TODO (totalCount || trip) als zusätzliches inneres if und mit topic-spezifischen Hinweis.
			//  aktuell wird nur bei !preroutingEnabled der Hinweis ausgeblendet

			if(isSSR &&  navPos.topic && navPos.topic.deepLinks) {
				content.push(
					// INFO if you want to try out @mapsight/ui:feature/fast-list use <FeatureListOne simpleList={false}/>
					<Fragment key="list">
						<FeatureListOne itemAs={LinkItem} />
					</Fragment>
				);
			} else {
				content.push(
					// INFO if you want to try out @mapsight/ui:feature/fast-list use <FeatureListOne simpleList={false}/>
					<Fragment key="list">
						{children}
					</Fragment>
				);
			}
		}

		if (!details && !hasWrongArea && camerasPage && !haveCameraOverview) {
			content.push(<FeatureListOne key="cameras" itemAs={ListOneCamerasListItem} />);
		}

		if (hasWrongArea) {
			content.push(
				<p key="wrong-area-info" className="vmznds-panel-box__info">
					Das ausgewählte Thema ist für das aktuelle Gebiet nicht verfügbar.<br /><br />
					<Link className="vmznds-list__more-link"
						  to={`${getBaseUrl()}${recommendedArea(maxAreas, navPos.topic.maxArea, navPos.area.uri)}/${navPos.topic.pageUri}`}>Zum
						passenden Gebiet wechseln.</Link>
				</p>
			);
		}

		// hardcoded für verkehrslage auf der Startseite TODO berechnen wie in ListOneSummaryInner
		const href = startPage && !details ? `${navPos.areaFound ? navPos.area.uri : 'niedersachsen'}/verkehrslage` : null;


		// TODO urgent should be set to detailsTopic.type (i.e. if a Schulausfall gets clicked on other page)
		return (
			<Fragment>
				<PanelBox
					filteredFeatures={filteredFeatures}
					bodyRef={bodyRef}
					k={k}
					mods={mods}
					href={href}
					onClose={!isMobile && details ? onCloseDetails : undefined}
					headline={
						navPos.error404 ? 'Verkehrslage' : (
							!detailsTopic && featureSourceInfo.foreign && featureSourceInfo.name ? featureSourceInfo.name :
								(detailsTopic && detailsTopic !== navPos.topic ? detailsTopic.pageName : listTitle)
						)
					}
					header={header}
					footer={!navPos.error404 && footer}
					urgent={
						(!details && navPos.topic && URGENT_PAGES.includes(navPos.topic.type)) ? navPos.topic.type :
							(details && detailsTopic && URGENT_PAGES.includes(detailsTopic.type)) ? detailsTopic.type :
								undefined
					}

				>
					{!navPos.error404 && !!content.length && (
						<div className="vmznds-main-panel-container__main-list-wrapper">
							{content}
						</div>
					)}
				</PanelBox>
				{!hasWrongArea && (note)}
			</Fragment>
		);
	});
