import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {selectExclusively} from '@mapsight/core/lib/feature-selections/actions';
import {FEATURE_SELECTIONS} from '@mapsight/ui/src/js/config/constants/controllers';
import {FEATURE_SELECTION_SELECT} from '@mapsight/ui/src/js/config/feature/selections';

import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';

import {isRecord, isString} from '../helpers'; // TODO: move useful helpers to NNSIMPLEJS or mapsight specific ones to @mapsight/ui

/** @type {import("@mapsight/ui/src/js/components/contexts").TooltipContent} */
function TooltipContent({children, feature}) {
	const dispatch = useDispatch();

	const [hidden, setHidden] = useState(true);
	useEffect(
		() => setHidden(!feature),
		[feature]
	);
	const onKeyDown = useCallback(
		(ev) => {
			if (ev.key === 'Escape') {
				setHidden(true);
				ev.preventDefault();
			} else if (feature?.id && ev.key === 'Enter') {
				dispatch(selectExclusively(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT, feature.id));
				ev.preventDefault();
			}
		},
		[dispatch, setHidden, feature]
	);
	useEffect(
		() => {
			if (typeof window !== 'undefined' && !hidden) {
				// console.log('tooltip useEffect addEventListener', {cond: (typeof window !== 'undefined' && !hidden), onKeyDown, activeElement: document.activeElement});
				window.addEventListener('keydown', onKeyDown, true);

				return () => {
					// console.log('tooltip useEffect cleanup = removeEventListener');
					window.removeEventListener('keydown', onKeyDown, true);
				};
			}
			return undefined;
		},
		[hidden, onKeyDown]
	);

	if (hidden) {
		return null;
	}

	if (
		!isRecord(feature)
		|| !isRecord(feature.properties)
		|| !isString(feature.properties.type)
	) {
		return <Fragment>{children}</Fragment>;
	}

	if (feature.properties.type !== 'pundr') {
		return <Fragment>{children}</Fragment>;
	}

	const props = feature.properties;
	/** @type {import('../../modules/p+r-utilization/occupation').Occupation} occupation */
	const occupation = props.parking;

	return (
		<div className="ms3-tooltip__inner">
			<article className={modClasses('vmznds-feature-details-tooltip', 'pundr')}>
				<h1>{props.name}</h1>

				{occupation.totalFree !== undefined && occupation.totalFree !== null && (
					<div className="vmznds-feature-details-tooltip__occupation">
						<div className="vmznds-feature-details-tooltip__occupation-text">
							{occupation.totalFree} Plätze frei
						</div>
					</div>
				)}
			</article>
		</div>
	);
}

export default TooltipContent;
