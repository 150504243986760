import React, {memo} from 'react';

import {withErrorBoundary} from '../../modules/error-boundary';
import {memoForever} from '../helpers';
import {useNavPos} from '../hooks';


/** @type {import("@mapsight/ui/src/js/components/contexts").MapOverlayModal} */
const MapOverlayModal = memo(function MapOverlayModal({children}) {
		const {reduced} = useNavPos();
		return (
			<div className={`vmznds-map-overlay-modal  ${reduced ? 'vmznds-map-overlay-modal--reduced' : ''}`}>
				{children}
			</div>
		);
	},
	memoForever // mapsight sets parameters for this component, memoForever changes to get them ignored
);

export default withErrorBoundary(MapOverlayModal);
