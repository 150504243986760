import React, {memo} from 'react';

import modClasses from '@mapsight/ui/src/js/helpers/mod-classes';

// TODO übernehmen nach @mapsight/ui und dort bei der Filtereingabe und beim Eingabefeld für die Kartensuche verwenden
// ms3-input-container__reset-button ist bereits dort definitiert, aber eigentlich müsste man da das reset- rausnehmen und es auf ...button ändern und dann das CSS ein bissl aufräumen und dann die css-Klassen des Location-Buttons
export default memo(function InputContainer(props) {
	const {
		for: htmlFor, children, label, reset, setUserLocation, setEvents, isEvents, haveEvent
	} = props;

	return (
		<fieldset className="ms3-pre-routing-box__input-container">
			{/* eslint-disable-next-line jsx-a11y/label-has-for */}
			<label htmlFor={htmlFor}>
				<span className="ms3-pre-routing-box__input-label">{label}</span><br />
			</label>
			<div className={modClasses('ms3-input-container', {short: setEvents && !isEvents})} >
				{children}

				{reset && (
					<button
						className="ms3-filter-button ms3-filter-button--icon-reset ms3-input-container__reset-button"
						type="button"
						onClick={reset}
						aria-expanded={isEvents ? true : undefined}
					>
						<i>zurücksetzen{isEvents ? ' und Event-Suche schließen' : ''}</i>
					</button>
				)}
				{!reset && !isEvents && setUserLocation && (
					<button
						className="ms3-filter-button ms3-filter-button--icon-reset ms3-input-container__reset-button ms3-input-container--button__icon-geo-location"
						type="button"
						onClick={setUserLocation}
					>
						<i>Eingabe auf Ihren Standort setzen</i>
					</button>
				)}
				{setEvents && !isEvents && (
					<button
						className={modClasses('ms3-input-container__events', {black: haveEvent})}
						type="button"
						onClick={setEvents}
						aria-expanded={isEvents}
					>
						<span className="visuallyhidden">Event-Suche öffnen</span>
					</button>
				)}
			</div>
		</fieldset>
	);
});
