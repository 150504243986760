import Highcharts from 'highcharts';

let needInit = true;

export const DAY_IN_MS = 24 * 60 * 60 * 1000;

/** @type {Highcharts.Options} */
export const DEFAULT_STATION_OPTIONS = {
	// colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
	colors: [
		'#0066b4', '#BE1E1E7F', '#008F48', '#C67B00',  // logoColor, vmzRed mit alpha, vmzGreen, vmzOrange
		'#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'  // Farben von Thomas/unbekannte Herkunft, wahrscheinlich nirgends benutzt
	],
	credits: {
		enabled: false,
		href: 'http://neonaut.de/',
		text: 'Neonaut GmbH',
	},

	tooltip: {
		borderWidth: 1,
		borderRadius: 3,
		backgroundColor: 'rgba(250, 250, 250, 0.9)',
		xDateFormat: '%A, %d.%m.%Y',
	},

	title: {
		text: undefined,
	},
	subtitle: {
		text: undefined,
	},

	xAxis: {
		type: 'datetime',
		dateTimeLabelFormats: {
			day: '%e. %b',
			month: '%b \'%y',
		},
	},

	yAxis: {
		type: 'linear',
		min: 0,
		title: {
			text: undefined,
		},
	},

	chart: {
		style: {
			fontFamily: 'PTSansRegular, Helvetica, Arial, sans-serif',
			fontSize: '13px'
		},
		type: 'column',
		height: 180,
		backgroundColor: 'transparent',
		spacing: [15, 20, 15, 0], // top, right, bottom and left
	},

	plotOptions: {
		column: {
			pointPadding: 0, //0.1,
			groupPadding: 0, //0.1,
			// borderColor: '#6F8698',  // sieht hässlich aus, weil die Abstände manchmal dicker sind
		},
		area: {
			stacking: 'normal',
		},
	},

	legend: {
		enabled: false,
	},
};

export const DEFAULT_HOUR24_OPTIONS = Object.assign({},
	DEFAULT_STATION_OPTIONS,
	/** @type {Highcharts.Options} */
	{
		tooltip: Object.assign({}, DEFAULT_STATION_OPTIONS.tooltip, {
			formatter: function () {
				return `${this.x} bis ${this.x + 1} Uhr:<br> ø zu ${Math.round(this.y)}% belegt`;
			},
			xDateFormat: null,
		}),

		plotOptions:  {
			column: {
				pointPadding: 0,
				groupPadding: 0,
				grouping: false,
			},
			area: {
				stacking: 'normal',
			},
		},

		xAxis: {
			type: 'linear',
			min: 0,
			max: 23,
			tickInterval: 3,
			title: {
				text: undefined, // Uhr
			},
		},

		// yAxis: {visible: false},

		legend: {
			enabled: true,
			align: 'left',
			symbolRadius: 0,
			verticalAlign: 'top',
			padding: 0,
			width: 220,
		},
	});

export function getHighcharts() {
	if(needInit && typeof Highcharts === 'object') {
		needInit = false;
		Highcharts.setOptions({
			lang: {
				months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
				shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
				weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
				shortWeekdays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
				numericSymbols: null, //[' tsd.', 'M', 'G', 'T', 'P', 'E'],
				printButtonTitle: 'Diagramm ausdrucken',
				resetZoomTitle: 'Zoom zurücksetzen',
				resetZoom: 'Zoom zurücksetzen',
				loading: 'Lädt...',
				exportButtonTitle: 'Diagramm sichern',
				downloadSVG: 'Als SVG sichern',
				downloadPNG: 'Als PNG sichern',
				downloadPDF: 'Als PDF sichern',
				downloadJPEG: 'Als JPEG sichern',
				decimalPoint: ',',
				thousandsSep: '.',
			},
		});

		// // not used but left for reference
		// // additional dateFormats
		// Highcharts.dateFormats = {
		// 	// https://stackoverflow.com/questions/17850809/highstock-display-number-of-week
		// 	// using code from http://techblog.procurios.nl/k/news/view/33796/14863/calculate-iso-8601-week-and-year-in-javascript.html
		// 	// implementing %V and %G from strftime http://php.net/manual/en/function.strftime.php
		// 	V: function (timestamp) {
		// 		var target  = new Date(timestamp);
		// 		var dayNr   = (target.getDay() + 6) % 7;
		// 		target.setDate(target.getDate() - dayNr + 3);
		// 		var firstThursday = target.valueOf();
		// 		target.setMonth(0, 1);
		// 		if (target.getDay() != 4) {
		// 			target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
		// 		}
		// 		return 1 + Math.ceil((firstThursday - target) / 604800000); // 604800000 = 7 * 24 * 3600 * 1000
		// 	}  ,
		// 	G: function(timestamp) {
		// 		var target  = new Date(timestamp);
		// 		target.setDate(target.getDate() - ((target.getDay() + 6) % 7) + 3);
		// 		return target.getFullYear();
		// 	}
		// };
	}

	return Highcharts;
}
