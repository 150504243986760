import React, {memo, useMemo, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';

import Link from '../../../mapsight-vmznds/components/link';
import {useDayHours} from '../../../mapsight-vmznds/hooks';

import {withErrorBoundary} from '../../error-boundary';
import {DEFAULT_HOUR24_OPTIONS, getHighcharts} from '../../highcharts';

const ParkingStatistics = memo(
	function ParkingStatistics({featureProps}) {
		const Highcharts = getHighcharts();

		const dayHours = useDayHours();
		const [selectedDay, setSelectedDay] = useState(
			/** @return {number} */
			() => (dayHours.day + 7 - 1) % 7 // we have monday on zero
		);
		const current = useMemo(
			() => {
				const today = (dayHours.day + 7 - 1) % 7;
				const isToday = selectedDay === today;
				const percentOccupied = featureProps?.parking?.percentOccupied;
				const currentSeries = {
					type: 'column',
					name: isToday ?
						(typeof percentOccupied === 'number' ? `Aktuell ${Math.round(percentOccupied)}%` : 'Aktuell kein Wert verfügbar') :
						`Aktuell ist ${Highcharts.getOptions().lang.weekdays[dayHours.day]}`,
					data: [
						0, 0, 0, 0, 0, 0,
						0, 0, 0, 0, 0, 0,
						0, 0, 0, 0, 0, 0,
						0, 0, 0, 0, 0, 0,
					],
				};
				currentSeries.data[dayHours.hours] = isToday && percentOccupied ? percentOccupied : 0;

				return currentSeries;
			},
			[Highcharts, featureProps, dayHours.day, dayHours.hours, selectedDay]
		);

		const day = useMemo(() => ({
			type: 'column',
			name: 'Auslastung je Stunde (%)',
			data:
				featureProps.hourlyRelativeOccupancyAveragePerWeekday &&
				featureProps.hourlyRelativeOccupancyAveragePerWeekday[selectedDay] &&
				featureProps.hourlyRelativeOccupancyAveragePerWeekday[selectedDay][0] !== null ?
					//hs: keine Ahnung worum, aber ohne slice() wird der Array überschrieben
					featureProps.hourlyRelativeOccupancyAveragePerWeekday[selectedDay].slice() :
					[
						0, 0, 0, 0, 0, 0,
						0, 0, 0, 0, 0, 0,
						0, 0, 0, 0, 0, 0,
						0, 0, 0, 0, 0, 0
					],
		}), [featureProps, selectedDay]);

		const weekdaySelector = (
			<p>
				<strong>
					{'Stoßzeiten für'}
					<select
						value={selectedDay}
						onChange={(e) => setSelectedDay(parseInt(e.target.value, 10))}
					>
						<option value="0">Montag</option>
						<option value="1">Dienstag</option>
						<option value="2">Mittwoch</option>
						<option value="3">Donnerstag</option>
						<option value="4">Freitag</option>
						<option value="5">Samstag</option>
						<option value="6">Sonntag</option>
					</select>
				</strong>
			</p>
		);

		const chart = Object.assign({}, DEFAULT_HOUR24_OPTIONS, {
			series: [
				day,
				current,
			],
			yAxis: Object.assign({}, DEFAULT_HOUR24_OPTIONS.yAxis, {
				visible: false,
				max: 100, // Angaben in %
			})
		});

		return featureProps.hourlyRelativeOccupancyAveragePerWeekday && (
			<section className="vmznds-feature-details-pundr__statistics">
				<h2 className="vmznds-feature-details-pundr__statistics-headline">Auslastungsverlauf</h2>

				<div className="vmznds-feature-details-pundr__statistics-content">
					{weekdaySelector}
					<figure className="vmznds-feature-details-pundr__statistics-chart">
						<figcaption className="visuallyhidden">Durschnittliche Auslastung je Stunde des gewählten
							Tages
						</figcaption>
						<HighchartsReact
							highcharts={Highcharts}
							options={chart}
						/>
					</figure>
					<p>
						Bitte beachten Sie, dass die<br />
						P+R Anlage bei
						<Link className="vmznds-list__more-link" to="/veranstaltungen">Veranstaltungen</Link>
						stärker frequentiert ist<br />
						als gewöhnlich.
					</p>
				</div>
			</section>
		) || null;
	}
);

export default withErrorBoundary(ParkingStatistics);

