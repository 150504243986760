import proj4 from 'proj4';

/**
 * @param {Array<[number, number]>} points
 * @param {[number, number]} first
 * @returns {[number, number, number, number]}
 */
function reducePointsToBbox(points, first) {
	return points.reduce(
		([minX, minY, maxX, maxY], [x, y]) => [
			Math.min(minX, x), Math.min(minY, y),
			Math.max(maxX, x), Math.max(maxY, y),
		],
		[...first, ...first]
	);
}

/**
 * @param {Array<[number, number]>} points points
 * @returns {[number, number, number, number] | null} bounding box or null
 */
export function pointsToBoundingBox(points) {
	const first = points.pop();
	if (first) {
		const [minX, minY, maxX, maxY] = reducePointsToBbox(points, first);
		return [
			...proj4('EPSG:3857', [minY, minX]),
			...proj4('EPSG:3857', [maxY, maxX]),
		];
	}

	return null;
}
