import React, {Fragment, memo, useMemo} from 'react';

import {useLoginState, useMagic, useNavPos} from '../hooks';
import {calculateMaxAreas} from '../routing/helpers';

import PanelBox from './boxes/panel-box';
import {calculateMenus, Entry} from './menu';
import PanelNote from './boxes/panel-note';


const Section = memo(
	/**
	 * @param {{
	 * section: string
	 * }} props
	 */
	function Sections({section}) {
		const magic = useMagic();
		const {area} = useNavPos();
		const {maxAreas} = useMemo(
			() => calculateMaxAreas(magic.areas),
			[magic.areas]
		);
		const loggedIn = useLoginState();

		const menuData = calculateMenus(magic, maxAreas, area, loggedIn);

		const entries = menuData[section] && menuData[section].entries || [];
		return (
			<li
				key={section}
				className="vmznds-menu__section"
			>
				<span className="vmznds-menu__section-name">
					{section}
				</span>

				<ul className="vmznds-menu__section-list">
					{entries.map(({href, name}) => (
						<Entry
							key={href}
							href={href}
							name={name}
						/>
					))}
				</ul>
			</li>
		);
	}
);


export default memo(
	function ({}) {
		const navPos = useNavPos();
		const headline = navPos.error404 ? 'Wissenswertes' :
			navPos.sitemap ? 'Service' :
			(navPos.content ? navPos.content.menu : navPos.topic.menu);
		const noteMessage = navPos.content ? navPos.content.marginal : navPos.topic?.marginal;

		return (
			<Fragment>
				<PanelBox
					k="side-menu"
					headline={headline}
					headlineTag="h1"
					isNav={true}
				>
					<ul className="vmznds-menu__list">
						<Section section={headline} />
					</ul>
				</PanelBox>
				{/*navPos.error404 && (
					<PanelNote>
						<p className="vmznds-panel-box__info">
							<Link to={`${getBaseUrl()}${navPos.area ? navPos.area.uri : ''}`}>Zur Startseite wechseln.</Link>
						</p>
					</PanelNote>
				)*/}
				{!!noteMessage &&(
					<PanelNote html={noteMessage} />
				)}
			</Fragment>
		);
	}
);
