import React from 'react';

import ParkingUtilization from '../../../../modules/p+r-utilization/components/utilization';
import ParkingStatistics from '../../../../modules/p+r-utilization/components/statistics';

import PtInfo from '../../../../modules/public-transport/components/info';

import Heading from './heading';

/**
 * @type {import("@mapsight/ui/src/js/components/contexts.js").FeatureDetailsContent}
 */
function FeatureDetailsContentPundr({feature}) {
	const props = feature.properties;
	/** @type {import('../../../../modules/p+r-utilization/occupation').Occupation} occupation */
	const occupation = props.parking;
	const isBike = props.mapsightIconId === 'bike';

	return (
		<article className="vmznds-feature-details-pundr">
			<Heading {...{isBike, occupation, feature}} />

			<ParkingUtilization occupation={occupation} isBike={isBike} />

			<PtInfo
				featureProps={props}
				baseClassName="vmznds-feature-details-pundr"
				linkClassName="vmznds-list__more-link--external"
			/>

			<ParkingStatistics featureProps={props} />

			<p className="vmznds-feature-details-pundr__disclaimer">Angaben ohne Gewähr.</p>
		</article>
	);
}

export default FeatureDetailsContentPundr;
