import ReactModal from 'react-modal/lib/components/Modal';
import browserEmbed from '@mapsight/ui/src/js/embed/browser';
import msUiEventHub, {
	EVENT_PARTIAL_CONTENT_CHANGED as MS_UI_EVENT_CONTENT_CHANGE
} from '@mapsight/ui/src/js/helpers/global-event-hub';
import {FEATURE_SOURCES} from '@mapsight/ui/src/js/config/constants/controllers';

import renderRefreshingImage from '../modules/refreshing-image';
import {render as renderRadzaehlerOverview} from '../modules/radzaehler/overview';
import {render as renderRadzaehlerWizard} from '../modules/radzaehler/wizard';

import createBrowserDomRenderer from './renderer/browser-dom';
import {history} from './renderer/browser-router';

import embed, {processMagic} from './embed';


window.mapsightUi = window.mapsightUi || {};

/**
 * @param {Element} elem
 * @param {{magic: import("./types").Magic, uri: string}} options
 */
function embedWrapper(elem, options) {
	msUiEventHub.on(MS_UI_EVENT_CONTENT_CHANGE, function renderPartialChange() {
		elem.querySelectorAll('img[data-refresh-interval]').forEach(renderRefreshingImage);
		renderRadzaehlerOverview();
		renderRadzaehlerWizard();
	});

	const magic = processMagic(options.magic);
	const preset = embed({...options, magic});
	preset.embedOptions.renderer = createBrowserDomRenderer(magic);
	// console.warn('window.mapsightUi.preset', presetTypo3(options), preset);
	preset.baseMapsightCoreConfig = {
		...preset.baseMapsightCoreConfig,
		[FEATURE_SOURCES]: {
			...preset.baseMapsightCoreConfig[FEATURE_SOURCES],
			...(options.reHydratedState && options.reHydratedState[FEATURE_SOURCES])
		}
	};

	ReactModal.setAppElement('.vmznds-mapsight-embed');
	browserEmbed(elem, preset);
}

window.mapsightUi.embed = embedWrapper;

// NOTE: We use global functions here on purpose so we can call them "from html"
/* eslint-disable piggyback/no-restricted-global-extend */
window.vmznds = window.vmznds || {};

window.vmznds.goPundR = function () {
	history.push('/region-hannover/park-und-ride' + window.location.search);
};

window.vmznds.goVeranstaltungen = function () {
	history.push('/veranstaltung' + window.location.search);
};

/**
 * click handler for links in text, to be used by typo3 text generator
 * usage:  onclick="window.vmznds.goInternal(event, this)"
 *
 * @param {Event} event object "event" of inline handler
 * @param {string | HTMLAnchorElement} target  url as string or anchor element
 */
window.vmznds.goInternal = function (event, target) {
	const href = typeof target === 'string' ? target : target.getAttribute('href');
	history.push(href + window.location.search);
	event.preventDefault();
};
